import { Link, useNavigate, useParams } from "react-router-dom";
import { DateTimePicker } from "./dateTimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useEffect, useState } from "react";
import formatISO from "date-fns/formatISO";
import getDay from "date-fns/getDay";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { api } from "../../helper/api";
import { Breadcrumbs, Grid } from "@mui/material";
import CheckBox from "../forms/inputs/checkBox";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import styles from "./appointmentRequestDateTime.module.css";
import GenericButton from "../forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

export function AppointmentRequestDateTime() {
  const [startTime, setStarttime] = useState<any>(
    dayjs("08:00:00", "HH:mm:ss")
  );
  const [endTime, setEndtime] = useState<any>(dayjs("16:00:00", "HH:mm:ss"));
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [appointmentRequestData, setAppointmentRequestData] = useState<any>();
  const [skipValidation, setSkipValidation] = useState<boolean>(false);
  const [appointmentRequestStatus, setAppointmentRequestStatus] =
    useState<string>("");
  const [error, setError] = useState<any>();

  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };
  const { showConfirmationModalAsync } = useConfirmationModal();

  const user = useSelector((state: RootState) => state.login);

  /*eslint-disable */
  const { t, i18n } = useTranslation();
  /*eslint-enable */

  useEffect(() => {
    if (appointmentrequestId) {
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "get",

        successHandler: (res: any) => {
          setAppointmentRequestData(res.data);
          setAppointmentRequestStatus(res.data.status);
          const daySet = res.data.day_set;
          if (daySet.length !== 0) {
            const length = daySet.length;
            const start = daySet[0].start;
            const end = daySet[length - 1].end;
            setStarttime(dayjs(start));
            setEndtime(dayjs(end));
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: appointmentrequestId,
      });
    }
  }, [appointmentrequestId]);

  const navigate = useNavigate();

  const handleDateRange = (range: any) => {
    setDateRange(range);
  };
  const editAppointmentRequest = async () => {
    if (appointmentrequestId && appointmentRequestStatus === "open") {
      navigate("/appointmentrequest");
    } else {
      const today = dayjs().format("YYYY-MM-DD");
      const startDay = dayjs(dateRange[0]).format("YYYY-MM-DD");

      const startHours = startTime.$H < 10 ? "0" + startTime.$H : startTime.$H;
      const startMinutes =
        startTime.$m < 10 ? "0" + startTime.$m : startTime.$m;
      const endHours = endTime.$H < 10 ? "0" + endTime.$H : endTime.$H;
      const endMinutes = endTime.$m < 10 ? "0" + endTime.$m : endTime.$m;
      const daySet = [];

      let range = [];
      if (dateRange instanceof Date) {
        range.push(dateRange);
      } else {
        range = dateRange;
      }
      for (const key in range) {
        const date = range[key];
        const dateToIso = formatISO(date, { representation: "date" }) + "T";

        daySet.push({
          start: dateToIso + startHours + ":" + startMinutes,
          end: dateToIso + endHours + ":" + endMinutes,
        });
      }

      if (user.companyName === "kaer" && startDay === today) {
        const isConfirmed = await showConfirmationModalAsync(
          t("confirm_create_appointmentrequest_for_today")
        );
        if (!isConfirmed) return;
      }
      const result = {
        day_set: daySet,
        skip_validation: skipValidation,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        // eslint-disable-next-line
        const response = await new Promise((resolve, reject) => {
          api.genericApiRequest({
            entity: "appointmentRequest",
            method: "put",
            successHandler: (res: any) => {
              resolve(res);
              navigate("/appointmentrequest");
            },
            failHandler: (error: any) => {
              reject(error);
            },
            entityId: appointmentrequestId,
            submitData: result,
            config: config,
          });
        });
      } catch (error) {
        setError(error);
      }
    }
  };
  const handleCheckoxChange = (checked: any) => {
    setSkipValidation(checked);
  };
  let hoursOfDay: any;
  if (startTime && endTime) {
    hoursOfDay = endTime.$H - startTime.$H;
  }
  let calculateDays = 0;

  if (appointmentRequestData && hoursOfDay) {
    calculateDays =
      (appointmentRequestData.subject_count *
        appointmentRequestData.slot_duration) /
      60;
    if (appointmentRequestData.parallel_slots > 1) {
      calculateDays = calculateDays / appointmentRequestData.parallel_slots;
    }
    calculateDays = Math.ceil(calculateDays / hoursOfDay);
  }

  const isWeekday = (date: any) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  let selectedDays: any;
  if (!dateRange.length) {
    selectedDays = 0;
  } else {
    selectedDays = dateRange.length;
  }
  // const days = dateRange.length > 1 ? t("days") : t("day");

  const disableButtonCheck =
    (Object.keys(dateRange).length === 0 || dateRange.length > 1) &&
    selectedDays < calculateDays &&
    !skipValidation;

  const checkAndSetEnttime = (time: any) => {
    if (startTime && time >= startTime) {
      setEndtime(time);
    } else {
      alert(
        t("end_time") +
          " darf nicht vor der" +
          " " +
          t("start_time") +
          " liegen!"
      );
      return;
    }
  };

  return (
    <>
      {appointmentRequestData && (
        <div className={styles.mobileTimePickerWrapper}>
          {appointmentrequestId && appointmentRequestStatus !== "draft" && (
            <div role="presentation">
              <Breadcrumbs separator="|">
                <Link
                  className={styles.linkNoUnderline}
                  to={`/editappointmentrequest/${appointmentrequestId}/settings`}
                >
                  {t("basic_information")}
                </Link>
                <Link
                  className={styles.linkNoUnderline}
                  to={`/editappointmentrequest/${appointmentrequestId}/building`}
                >
                  {t("building_location")}
                </Link>
                <Link
                  className={styles.linkUnderlined}
                  to={`/editappointmentrequest/${appointmentrequestId}/calendar`}
                >
                  {t("period")}
                </Link>
              </Breadcrumbs>
            </div>
          )}
          <h1>
            {t("select_date")} - {appointmentRequestData?.company?.name}
          </h1>
          <MobileTimePicker
            label={t("start_time")}
            value={startTime}
            onChange={(value) => setStarttime(value)}
            disabled={appointmentRequestStatus !== "draft" ? true : false}
          />
          <MobileTimePicker
            label={t("end_time")}
            value={endTime}
            onChange={(value) => checkAndSetEnttime(value)}
            disabled={appointmentRequestStatus !== "draft" ? true : false}
          />
          <Grid container className={styles.datePickerWrapper}>
            <DateTimePicker
              selectsRange={true}
              onlyWeekDays={true}
              startTime={startTime}
              endTime={endTime}
              filterDate={isWeekday}
              handleDateRange={handleDateRange}
              calculatedDays={calculateDays}
              selectedDays={selectedDays}
              disabled={appointmentRequestStatus !== "draft" ? true : false}
            />
            <Grid item>
              {selectedDays < calculateDays && (
                <>
                  <h4 style={{ fontSize: "1rem", margin: "0" }}>
                    Mit weniger Tagen als berechnet fortfahren?
                  </h4>
                  <CheckBox
                    label={t("yes")}
                    labelClassName={styles.checkBoxLabel}
                    onChange={handleCheckoxChange}
                  />
                </>
              )}
            </Grid>
          </Grid>

          <GenericButton
            disabled={disableButtonCheck}
            onClick={editAppointmentRequest}
            className={styles.submitButton}
            waitingForResponse={true}
            showSpinnerOnDisable={!disableButtonCheck}
          >
            {appointmentrequestId && appointmentRequestStatus === "open"
              ? t("back_to", { name: t("appointmentrequest_multi") })
              : t("save")}
          </GenericButton>
        </div>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}

//TODO: add Translations
