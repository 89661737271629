import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import GenericButton from "./forms/inputs/button/genericButton";
import { useCallback, useEffect } from "react";

interface Props {
  submitButtonText?: string | null;
  cancelButtonText?: string;
  hideSubmitButton?: boolean;
  hideDialogActions?: boolean;
  onSubmit?: () => void;
  open: boolean;
  title?: string;
  onClose?: () => void;
  children?: any;
  presetValues?: any;
  disableSubmit?: boolean;
  disableCancelButton?: boolean;
  contentStyle?: React.CSSProperties;
  contentClassName?: string;
  buttonPlacement?:
    | "center"
    | "space-around"
    | "space-evenly"
    | "space-between"
    | string
    | null
    | undefined;
}

export default function Modal(props: Props) {
  const { t } = useTranslation();

  let submitButtonText = t("save");
  let cancelButtonText = t("cancel");

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && props.onClose) {
        event.preventDefault();
        props.onClose();
      } else if (
        event.key === "Enter" &&
        props.onSubmit &&
        !props.disableSubmit
      ) {
        event.preventDefault();
        props.onSubmit();
      }
    },
    [props.onClose, props.onSubmit, props.disableSubmit]
  );

  useEffect(() => {
    if (props.open) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props.open, handleKeyDown]);

  if (props.submitButtonText) {
    submitButtonText = props.submitButtonText;
  }

  if (props.cancelButtonText) {
    cancelButtonText = props.cancelButtonText;
  }

  let submitButton = <></>;

  if (!props.hideSubmitButton) {
    submitButton = (
      <>
        <GenericButton
          variant="contained"
          disabled={props.disableSubmit}
          onClick={props.onSubmit}
        >
          {submitButtonText}
        </GenericButton>
      </>
    );
  }

  let dialogActions = <></>;

  if (!props.hideDialogActions) {
    dialogActions = (
      <DialogActions
        sx={{ p: "1.25rem", justifyContent: props.buttonPlacement }}
      >
        <GenericButton
          onClick={props.onClose}
          disabled={props.disableCancelButton}
        >
          {cancelButtonText}
        </GenericButton>
        {submitButton}
      </DialogActions>
    );
  }

  return (
    <Dialog
      open={props.open}
      disableEscapeKeyDown={true}
      PaperProps={{
        sx: { borderRadius: "0.25rem !important", width: "100%" },
      }}
    >
      <DialogTitle textAlign="center">{props.title}</DialogTitle>
      <DialogContent className={props.contentClassName} sx={props.contentStyle}>
        {props.children}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );
}
