import DefaultInput from "../forms/inputs/defaultInput";
import RadioButtons from "../forms/inputs/radioButtons";
import DropDown from "../forms/inputs/dropDown";
import DateField from "../forms/inputs/dateField";
import "./formEditorStyles.css";
import { DNDItem } from "./dndItem";
import { TextArea } from "../forms/inputs/textArea";
import { useEffect, useState } from "react";
import styles from "./dndWrapper.module.css";

interface Props {
  label: string;
  name: string;
  cols?: number;
  rows?: number;
  mandatory?: boolean;
  option?: any;
  id?: string;
  columnId?: string;
  type?: string;
  item?: DNDItem | any;
  onHandleCheckboxChange?: (id: string) => void;
  handleDeleteItem?: (id: string) => void;
  style?: any;
  visibility_ruleset?: any;
  preExistingFormElements?: any;
}

export function DndWrapper(props: Props) {
  const [dropDownOptions, setDropDownOptions] = useState<any>([]);

  useEffect(() => {
    let options: any = [];
    if (props.type === "choice" || props.type === "multiselect") {
      if (
        Array.isArray(props?.item?.value_map) &&
        props?.item?.value_map.length !== 0
      ) {
        options = props?.item?.value_map.map((obj: any) => {
          const [label, value] = Object.entries(obj)[0];
          return { value: value, label: label };
        });
        setDropDownOptions(options);
      }
    }
  }, [props.item]);

  return (
    <div className={styles.container}>
      {(function () {
        switch (props.type) {
          case "text":
            return (
              <TextArea
                label={props.label + " (" + props.name + ")"}
                cols={props.cols}
                rows={props.rows}
                mandatory={props.mandatory}
                withLabel={true}
                labelClassName={styles.textArea__label}
                labelStyle={{ color: "black" }}
              />
            );
            break;
          case "bool":
            return (
              <RadioButtons
                name={props.name}
                label={props.label + " (" + props.name + ")"}
                mandatory={props.mandatory}
              />
            );
            break;
          case "boolNoStatement":
            return (
              <RadioButtons
                name={props.name}
                label={props.label + " (" + props.name + ")"}
                mandatory={props.mandatory}
                boolOption
              />
            );
            break;
          case "choice":
            return (
              <DropDown
                options={dropDownOptions}
                label={props.label + " (" + props.name + ")"}
                mandatory={props.mandatory}
              />
            );
            break;
          case "multiselect":
            return (
              <DropDown
                // options={props.option}
                //TODO added [] so the editor doesnt crash. dont have an any options for the Dropdown to pass in
                options={dropDownOptions}
                isMulti={true}
                label={props.label + " (" + props.name + ")"}
                mandatory={props.mandatory}
              />
            );
            break;
          case "date":
            return (
              <DateField
                label={props.label + " (" + props.name + ")"}
                mandatory={props.mandatory}
              />
            );
            break;
          default:
            return (
              <DefaultInput
                label={props.label + " (" + props.name + ")"}
                // id={props.name}
                mandatory={props.mandatory}
                name={props.name}
              />
            );
        }
      })()}
    </div>
  );
}
