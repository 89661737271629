import React from "react";
import { Draggable } from "react-beautiful-dnd";
import Item from "./dndItem";
import { areEqual } from "react-window";

interface Props {
  data: {
    isScrolling?: boolean;
    columnId?: string;
    data?: any;
    handleDeleteItem?: () => void;
    preExistingFormElements?: any;
    onHandleCheckboxChange?: () => void;
    onHandleRulesetChange?: (event: any, id: any) => void;
  };
  index: number;
  style?: any;
}

export const Row = React.memo(function Row(props: Props) {
  const { data, index, style } = props;
  const columnId = props.data.columnId;
  const item = data.data[index];
  const handleDeleteItem = props.data.handleDeleteItem;
  const preExistingFormElements = props.data.preExistingFormElements;
  const onHandleCheckboxChange = props.data.onHandleCheckboxChange;
  const onHandleRulesetChange = props.data.onHandleRulesetChange;

  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={item.name} index={index} key={item.id}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={columnId}
          handleDeleteItem={handleDeleteItem}
          preExistingFormElements={preExistingFormElements}
          onHandleCheckboxChange={onHandleCheckboxChange}
          onHandleRulesetChange={onHandleRulesetChange}
        />
      )}
    </Draggable>
  );
}, areEqual);
