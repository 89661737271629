import { CloseRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { FC, useEffect } from "react";

interface Props {
  message: string | any;
  variant: "success" | "error" | "warning" | "info" | undefined;
  handleCloseSnackbar: () => void;
  persist?: boolean;
  snackbarKeyRef?: React.MutableRefObject<SnackbarKey | null>;
  showCloseButton?: boolean;
}

const GenericNotification: FC<Props> = ({
  message,
  variant,
  handleCloseSnackbar,
  persist = false,
  snackbarKeyRef,
  showCloseButton = true,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let snackMessage: any;

  if (typeof message === "string") {
    snackMessage = message;
  } else if (typeof message === "object") {
    snackMessage = Object.entries(message).map(
      ([fieldName, msg]: any) => fieldName + ":" + msg
    );
  }
  if (Array.isArray(snackMessage)) {
    snackMessage = snackMessage[0];
  }

  useEffect(() => {
    if (snackbarKeyRef?.current) {
      closeSnackbar(snackbarKeyRef.current);
    }

    const key = enqueueSnackbar(snackMessage, {
      variant,
      persist,
      action: (key: SnackbarKey) =>
        showCloseButton ? (
          <Button
            onClick={() => {
              closeSnackbar(key);
              handleCloseSnackbar?.();
            }}
            size="small"
            sx={{ color: "#fff" }}
          >
            <CloseRounded />
          </Button>
        ) : null,
    });

    if (snackbarKeyRef) {
      snackbarKeyRef.current = key;
    }
  }, [
    enqueueSnackbar,
    message,
    persist,
    closeSnackbar,
    snackbarKeyRef,
    handleCloseSnackbar,
    showCloseButton,
  ]);

  return null;
};

export default GenericNotification;
