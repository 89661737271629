import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
const clearAction = createAction("clear");

export interface LoginState {
  subjectId: string | number;
  companyName: string;
  firstname: string;
  lastname: string;
  email: string;
  permissions: string[];
  company_enforced_two_factor: boolean;
}

const initialState: LoginState = {
  subjectId: "",
  companyName: "",
  firstname: "",
  lastname: "",
  email: "",
  permissions: [],
  company_enforced_two_factor: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLogin: (
      state,
      action: PayloadAction<{
        subjectId: string | number;
        companyName: string;
        firstname: string;
        lastname: string;
        email: string;
        permissions: [];
        company_enforced_two_factor: boolean;
      }>
    ) => {
      state.subjectId = action.payload.subjectId;
      state.companyName = action.payload.companyName;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.company_enforced_two_factor =
        action.payload.company_enforced_two_factor;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAction, () => initialState);
  },
});

export const { setLogin: setLogin } = loginSlice.actions;
export default loginSlice.reducer;
