import { useTranslation } from "react-i18next";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import Restricted from "../../consumer/restricted";

export default function RuleList() {
  const { t } = useTranslation();

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };

  const selectOptions = [
    {
      value: "update_file",
      label: t("update_file"),
    },
    {
      value: "generate_pdf",
      label: t("generate_pdf"),
    },
    {
      value: "create_todos",
      label: t("create_todo"),
    },
    {
      value: "send_reminder_email_for_appointment_requests",
      label: t("send_reminder_email_for_appointment_requests"),
    },
    {
      value: "send_invitation_email_to_managers",
      label: t("send_invitation_email_to_managers"),
    },
    {
      value: "close_overdue_appointment_requests",
      label: t("close_overdue_appointment_requests"),
    },
    {
      value: "switch_appointment_status_when_tasks_are_finished",
      label: t("switch_appointment_status_when_tasks_are_finished"),
    },
    {
      value: "send_reminder_email_for_registration",
      label: t("send_reminder_email_for_registration"),
    },
    {
      value: "send_notification_email_for_new_files",
      label: t("send_notification_email_for_new_files"),
    },
    {
      value: "close_overdue_form_todos",
      label: t("close_overdue_form_todos"),
    },
    {
      value: "create_todo_from_additional_data",
      label: t("create_todo_from_additional_data"),
    },
    {
      value: "create_todo_from_gbu",
      label: t("create_todo_from_gbu"),
    },
    {
      value: "send_invitation_email_to_subject",
      label: t("send_invitation_email_to_subject"),
    },
    {
      value: "send_todo_notification_to_recently_registered_users",
      label: t("send_todo_notification_to_recently_registered_users"),
    },
  ];

  return (
    <Restricted permissionRequired="frontend_view_rule">
      <GenericCrudTable
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        heading={t("rules")}
        entity="rule"
        columns={[
          { header: "ID", accessorKey: "id", id: "id" },
          {
            header: "Name",
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
          },
          {
            header: "Classname",
            accessorKey: "trigger_class_name",
            id: "trigger_class_name",
            enableForCreate: true,
          },
          {
            header: t("conditions"),
            accessorKey: "conditions",
            id: "conditions",
            customColumnFilterId: "conditions",
            customFilterParameter: "_icontains",
            required: true,
            errorMessage: t("field_required"),
            enableForCreate: true,
            accessorFn: (row: any) => {
              const conditions = [];
              if (row.conditions) {
                conditions.push(JSON.stringify(row.conditions));
              }

              return conditions.join(", ");
            },
            isJson: true,
          },
          {
            header: t("action"),
            accessorKey: "action",
            id: "action",
            required: true,
            filterVariant: "select",
            filterSelectOptions: selectOptions,
            errorMessage: t("field_required"),
            enableForCreate: true,
            createAble: true,
            selectOptions: selectOptions,
          },
          {
            header: t("active"),
            accessorKey: "disabled",
            id: "disabled",
            filterVariant: "select",
            filterSelectOptions: [
              { value: "true", label: t("no") },
              { value: "false", label: t("yes") },
            ],
            enableForCreate: true,
            selectOptions: [
              { value: true, label: t("no") },
              { value: false, label: t("yes") },
            ],
            accessorFn: (row: any) => {
              if (row.disabled) {
                return t("no");
              } else return t("yes");
            },
          },

          {
            header: t("scheduled"),
            accessorKey: "scheduled",
            id: "scheduled",
            enableForCreate: true,
            filterVariant: "select",
            filterSelectOptions: [
              { value: "true", label: "Ja" },
              { value: "false", label: "Nein" },
            ],
            selectOptions: [
              { value: true, label: t("yes") },
              { value: false, label: t("no") },
            ],
            accessorFn: (row: any) => {
              if (row.scheduled) {
                return t("yes");
              } else return t("no");
            },
          },
          {
            header: t("additional_data"),
            accessorKey: "additional_data",
            customColumnFilterId: "additional_data",
            customFilterParameter: "_icontains",
            id: "additional_data",
            required: true,
            errorMessage: t("field_required"),
            enableForCreate: true,
            accessorFn: (row: any) => {
              const additionalData = [];
              if (row.additional_data) {
                additionalData.push(JSON.stringify(row.additional_data));
              }

              return additionalData.join(", ");
            },
            isJson: true,
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            filterVariant: "date",
            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",

            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            filterVariant: "date",

            id: "modified_on",
            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("modified_by"),
            accessorKey: "modified_by",
            customColumnFilterId: "modified_by_name",
            id: "modified_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
        ]}
      />
    </Restricted>
  );
}
