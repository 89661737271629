import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../helper/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormProvider, useForm } from "react-hook-form";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";

import GenericNotification from "../notification/genericNotification";

import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  userInfos?: any;
}

const UserSettings2fa: FC<Props> = ({ userInfos }) => {
  const [error, setError] = useState<any>();
  const [successForNotification, setSuccessForNotification] =
    useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [twoFactorChecked, setTwoFactorChecked] = useState<boolean>(false);
  const { showConfirmationModalAsync } = useConfirmationModal();

  const methods = useForm<any>({
    mode: "onChange",
  });

  const { t } = useTranslation();
  useEffect(() => {
    if (userInfos) {
      setTwoFactorChecked(userInfos.requires_two_factor);
    }
  }, [userInfos]);

  const resetStateOnCloseNotification = () => {
    setSuccessForNotification(false);
  };

  const handle2faChange = async (value: any) => {
    const result = { requires_two_factor: value };

    setTwoFactorChecked(value);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "user",
      method: "put",
      entityId: userInfos.user_id,
      successHandler: () => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setSuccessForNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("2fa")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <>
              <FormControlLabel
                label={t("2fa_authentication")}
                disabled={userInfos?.company_enforced_two_factor}
                control={
                  <Switch
                    name="requires_two_factor"
                    value={twoFactorChecked}
                    onChange={async (event) => {
                      const newChecked = event.target.checked;

                      const confirmMessage = newChecked
                        ? t("2fa_activation_confirmation_user")
                        : t("2fa_deactivation_confirmation_user");
                      const isConfirmed = await showConfirmationModalAsync(
                        confirmMessage
                      );
                      if (!isConfirmed) return;

                      handle2faChange(newChecked);
                    }}
                    checked={twoFactorChecked ?? false}
                  />
                }
              />
              {userInfos?.company_enforced_two_factor && (
                <FormHelperText sx={{ color: "red" }}>
                  {t("2fa_companyenforced_userinfo")}
                </FormHelperText>
              )}
              <FormHelperText>{t("2fa_infotext_user")}</FormHelperText>
            </>
          </FormProvider>
        </AccordionDetails>
      </Accordion>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {successForNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
};

export default UserSettings2fa;
