import { useState, useEffect } from "react";
import {
  useSignIn,
  useAuthUser,
  useIsAuthenticated,
  useSignOut,
} from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/login.css";
import { api } from "../../helper/api";
import { useDispatch } from "react-redux";
import { setLogin } from "../state/parent/loginSlice";
import { useForm } from "react-hook-form";
import PasswordInput from "./inputs/passwordInput";
import GenericButton from "./inputs/button/genericButton";

interface Props {
  errorHandler: (errorCode: string) => void;
  errorReset: () => void;
  portalName: () => any;
  setLoadingState: (state: boolean) => void;
  subdomain?: string;
  token?: string | null;
}

export default function LoginForm(props: Props) {
  const [loginstate, setLoginstate] = useState<number>(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const signOut = useSignOut();

  const getAuth = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  const { register, handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      userName: "",
      userPassword: "",
    },
  });

  const handleUserLogin = (data: any) => {
    const sessionExpiration = Math.floor(data.token_expiry_in / 60);
    if (
      signIn({
        token: data.email,
        expiresIn: sessionExpiration,
        tokenType: "Session",
        authState: {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          company_name: data.company_name,
        },
      })
    ) {
      setLoginstate(1);
      navigate("/");

      dispatch(
        setLogin({
          subjectId: data.subject_id,
          firstname: data.first_name,
          lastname: data.last_name,
          email: data.email,
          companyName: data.company_name,
          permissions: data.permissions,
          company_enforced_two_factor: data?.company_enforced_two_factor,
        })
      );
    } else {
      console.log("login was not successful");
    }
  };

  useEffect(() => {
    if (props.token !== null) {
      props.setLoadingState(true);
      const additionalUrlParameters: any = {};
      additionalUrlParameters["token"] = props.token;
      api.genericApiRequest({
        method: "get",
        entity: "verify-2fa",
        parametersToRender: additionalUrlParameters,
        successHandler: (res: any) => {
          props.setLoadingState(false);
          handleUserLogin(res.data);
        },
        failHandler: () => {
          props.errorHandler("invalid");
          props.setLoadingState(false);
        },
      });
    }
  }, [props.token]);
  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };

  useEffect(() => {
    if (isAuthenticated()) {
      const auth = getAuth();

      if (auth && auth.email) {
        setLoginstate(1);
      }
    }
  }, [getAuth, isAuthenticated]);

  useEffect(() => {
    if (loginstate === 1) {
      navigate("/");
    }
  }, [loginstate, navigate]);

  const onSubmit = (data: any) => {
    dispatch({ type: "clear" });

    const result = {
      username: data.userName,
      password: data.userPassword,
      // use_2fa: true,
    };
    api.genericApiRequest({
      entity: "login",
      method: "post",

      successHandler: (res: any) => {
        props.setLoadingState(true);
        const responseData = res.data;
        if (res.status === 202) {
          navigate("/login/2fa");
        }
        if (responseData) {
          handleUserLogin(responseData);
        }
      },
      failHandler: (error: any, code: any) => {
        if (error === "logout") {
          combinedSignout();
        } else {
          props.errorHandler(code);
        }
      },
      submitData: result,
    });
  };

  if (loginstate === 0) {
    return (
      <>
        <form
          className="login_form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <h1 className="heading textAlignCenter">{props.portalName()}</h1>

          <label htmlFor="login_email" className="login_formLabel">
            {t("login_email")}
          </label>
          <input
            {...register("userName")}
            className="login_formInput"
            onFocus={props.errorReset}
            id={"login_email"}
          />
          <PasswordInput
            control={control}
            name={"userPassword"}
            id={t("password")}
            label={t("password")}
            title={t("password")}
            dynamicLabel={false}
          />

          <div className="login_passwordReminderWrapper">
            <a className="login_passwordReminderLink" href="/login/reminder/">
              {t("password_forgotten")}
            </a>
            <GenericButton className="login_submitButton" type="submit">
              {t("login")}
            </GenericButton>
          </div>
        </form>
      </>
    );
  } else {
    return <></>;
  }
}
//TODO CUSTOMERROR
