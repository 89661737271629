import { t } from "i18next";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import Restricted from "../../consumer/restricted";

export default function MainCategoryList() {
  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };
  return (
    <Restricted permissionRequired="frontend_view_main_category">
      <GenericCrudTable
        entity="mainCategory"
        heading={t("main_categories")}
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
        additionalUrlParameter={{ include_relations: true }}
        columns={[
          { header: "ID", accessorKey: "id", id: "id", enableEditing: false },
          {
            header: t("name"),
            accessorKey: "name",
            id: "name",
            enableForCreate: true,
            required: true,
            errorMessage: t("field_required"),
          },
          {
            header: t("description"),
            accessorKey: "description",
            id: "description",
            enableForCreate: true,
            textarea: true,
          },
          {
            header: t("internal_usage"),
            id: "internal_usage",
            accessorKey: "internal_usage",
            enableForCreate: true,
            selectOptions: [
              { value: true, label: t("yes") },
              { value: false, label: t("no") },
            ],
            accessorFn: (row: any) => {
              if (row.internal_usage) {
                return t("yes");
              } else {
                return t("no");
              }
            },
            filterVariant: "checkbox",
          },
          {
            header: t("created_on"),
            accessorKey: "created_on",
            id: "created_on",
            sortingFn: "datetime",
            filterVariant: "date",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
          {
            header: t("created_by"),
            accessorKey: "created_by",
            customColumnFilterId: "created_by_name",

            id: "created_by",
            Cell: ({ cell }: any) => {
              return <span>{cell.getValue().name}</span>;
            },
          },
          {
            header: t("modified_on"),
            accessorKey: "modified_on",
            id: "modified_on",
            filterVariant: "date",
            sortingFn: "datetime",
            Cell: ({ cell }: any) => {
              return <span>{replaceDate(cell.getValue())}</span>;
            },
          },
        ]}
      />
    </Restricted>
  );
}
