import React, { ReactNode, useCallback, useMemo, useState } from "react";
import GenericConfirmationModal from "../components/forms/comfirmations/genericConfirmationModal";
import { ConfirmationModalContext } from "./confirmationModalContext";
import { t } from "i18next";

type ConfirmationModalProps = {
  isOpen: boolean;
  title?: string;
  message: string | ReactNode;
  confirmButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  autoCloseAfter?: number;
  disableConfirmFor?: number;
  //   resolve?: (value: boolean) => void;
};

export const ConfirmationModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [modalState, setModalState] = useState<ConfirmationModalProps>({
    isOpen: false,
    message: "",
  });

  const showConfirmationModal = useCallback(
    (
      message: string | ReactNode,
      options?: {
        title?: string;
        confirmButtonText?: string;
        onConfirm?: () => void;
        onCancel?: () => void;
      }
    ) => {
      setModalState({
        isOpen: true,
        message,
        title: options?.title || t("confirm_modal_close_title"),
        confirmButtonText: options?.confirmButtonText || t("ok_capital"),
        onConfirm: () => {
          options?.onConfirm?.();
          setModalState({ isOpen: false, message: "" });
        },
        onCancel: () => {
          options?.onCancel?.();
          setModalState({ isOpen: false, message: "" });
        },
      });
    },
    []
  );
  const showConfirmationModalAsync = useCallback(
    (
      message: string | ReactNode,
      options?: {
        title?: string;
        confirmButtonText?: string;
        autoCloseAfter?: number;
        disableConfirmFor?: number;
      }
    ): Promise<boolean> => {
      return new Promise((resolve) => {
        setModalState({
          isOpen: true,
          message,
          title: options?.title || t("confirm_modal_close_title"),
          confirmButtonText: options?.confirmButtonText || t("ok_capital"),
          disableConfirmFor: options?.disableConfirmFor,
          onConfirm: () => {
            resolve(true);
            setModalState({ isOpen: false, message: "" });
          },
          onCancel: () => {
            resolve(false);
            setModalState({ isOpen: false, message: "" });
          },
        });
        if (options?.autoCloseAfter) {
          setTimeout(() => {
            resolve(false);
            setModalState({ isOpen: false, message: "" });
          }, options.autoCloseAfter);
        }
      });
    },
    []
  );
  const contextValue = useMemo(
    () => ({ showConfirmationModal, showConfirmationModalAsync }),
    [showConfirmationModal, showConfirmationModalAsync]
  );

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      {children}
      <GenericConfirmationModal {...modalState} />
    </ConfirmationModalContext.Provider>
  );
};
