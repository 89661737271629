import { FC, ReactNode, useEffect, useState } from "react";
import GenericCrudTable from "../../components/tables/genericCrudTable";
import { t } from "i18next";
import {
  Backdrop,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
} from "@mui/material";
import { api } from "../../helper/api";
import { DotLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../components/state/store";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import Modal from "../../components/modal";
import GenericNotification from "../../components/notification/genericNotification";
import Restricted from "../../consumer/restricted";
import { Link, useNavigate } from "react-router-dom";
import DatasetIcon from "@mui/icons-material/Dataset";
import PaymentsIcon from "@mui/icons-material/Payments";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { setSubjectSearch } from "../../components/state/subjectSearch/subjectSearchSlice";
import styles from "./companyList.module.css";
import { KaerIcon } from "../../icons/kaerIcon";
import GenericButton from "../../components/forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";
import DropDown from "../../components/forms/inputs/dropDown";

const CompanyList: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showCoredataModal, setShowCoredataModal] = useState<boolean>(false);
  const [coreDataIds, setCoreDataIds] = useState<any>([]);
  const [combinedMetrics, setCombinedMetrics] = useState<any>([]);
  const [selectedCompanyname, setSelectedCompanyName] = useState<string>("");
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [subjectId, setSubjectId] = useState<any>("");
  const [demoCompanies, setDemoCompanies] = useState<any[]>([]);
  const [demoCompanyId, setDemoCompanyId] = useState<string | null>(null);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const user = useSelector((state: RootState) => state.login);
  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const navigateToSubjectOverviewForCompany = (subject: any) => {
    dispatch(setSubjectSearch(subject));
    navigate("/subjectOverview");
  };

  useEffect(() => {
    const additionalUrlParameters: any = {};
    additionalUrlParameters["demo"] = true;
    api.genericApiRequest({
      method: "get",
      entity: "company",
      parametersToRender: additionalUrlParameters,
      successHandler: (res: any) => {
        const data = res.data.results;
        const tempCompanies = data.map((company: any) => {
          return { value: company.id, label: company.name };
        });
        setDemoCompanies(tempCompanies);
      },
    });
  }, []);

  const handleDeleteDemoCompany = async () => {
    const isConfirmed = await showConfirmationModalAsync(
      t("confirm_reset_demo")
    );

    if (!isConfirmed) {
      return;
    } else setIsLoading(true);

    const additionalRouteParts: any = {};
    additionalRouteParts["reset_demo_company"] = "/";
    const result = {};
    api.genericApiRequest({
      entity: "company",
      method: "put",
      successHandler: (res: any) => {
        console.log(res);
        setShowNotification(true);
        setNotificationMessage(t("reset_successful"));
        setNotificationVariant("success");
        setIsLoading(false);
        setDemoCompanyId(null);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      additionalRouteParts: additionalRouteParts,
      entityId: demoCompanyId,
      submitData: result,
    });
  };

  let disallowDelete = true;
  if (user.email === "admin@kaer.de") {
    disallowDelete = false;
  }

  const handleGetCoreDataIds = (row: any) => {
    const companySubjectId = row.subject.id;

    const additionalUrlParameters: any = {};
    if (companySubjectId !== undefined) {
      additionalUrlParameters["subject"] = companySubjectId;
    }

    api.genericApiRequest({
      entity: "coreData",
      method: "get",
      parametersToRender: {
        depth: "0",
        additionalUrlParameters: additionalUrlParameters,
      },
      successHandler: (res: any) => {
        const ids = [];
        for (const key in res.data.results) {
          const coredataId = res.data.results[key].id;
          ids.push(coredataId);
        }
        if (ids.length === 0) {
          setError(t("no_coredata_available"));
        } else {
          setCoreDataIds(ids);
          setSelectedCompanyName(row.name);
        }
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const stammdatenQuery =
    "stammdaten_basis_adresse_strasse_hausnummer,stammdaten_basis_adresse_postleitzahl,stammdaten_basis_adresse_ort,stammdaten_basis_anzahl_mitarbeiter,stammdaten_basis_besonderheiten_parken_kunden,stammdaten_basis_unternehmen_arbeitsschutz_kontaktperson,stammdaten_basis_telefonnummer_unternehmen_arbeitsschutz,stammdaten_basis_email_unternehmen_arbeitsschutz,stammdaten_intern_beauftragt_fuer,stammdaten_intern_duzen_siezen,stammdaten_intern_zustaendiger_arzt,stammdaten_intern_zustaendige_sifa_kaer,stammdaten_intern_med_assistenz_kaer,stammdaten_intern_allgemeine_infos_firma";

  useEffect(() => {
    if (coreDataIds.length > 0) {
      const fetchData = async () => {
        try {
          const promises = coreDataIds.map((id: any) => {
            return new Promise((resolve) => {
              const additionalUrlParameters: any = {};
              additionalUrlParameters["coredata_id"] = id;
              additionalUrlParameters["name__in"] = stammdatenQuery;

              api.genericApiRequest({
                entity: "metric",
                method: "get",
                parametersToRender: {
                  additionalUrlParameters: additionalUrlParameters,
                  depth: "0",
                },
                successHandler: (res: any) => {
                  resolve(res.data.results);
                },
                failHandler: (error: any) => {
                  setError(error);
                },
              });
            });
          });
          await Promise.all(promises).then((value: any) => {
            const data = value.reduce(
              (acc: any, current: any) => [...acc, ...current],
              []
            );
            setCombinedMetrics(data);
            setShowCoredataModal(true);
          });
        } catch (error: any) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [coreDataIds]);

  const getCleanValue = (values: any, name: any) => {
    if (name in values) {
      if (values[name].constructor === Array) {
        return values[name].join(", ");
      }
      return values[name];
    }

    return "-";
  };

  const renderCoreDataRow = (name: any, value: any) => {
    return (
      <ListItem
        key={name}
        sx={{
          paddingBottom: "0 ",
          paddingTop: "0 ",
        }}
      >
        <ListItemText
          primary={name}
          secondary={value}
          secondaryTypographyProps={{ whiteSpace: "preserve" }}
        />
      </ListItem>
    );
  };

  const renderStammdatenView = (itemsFromApi: any) => {
    const renderedItems = [];
    const items: any = {};

    for (const key in itemsFromApi) {
      const item = itemsFromApi[key];
      items[item.name] = item.value;
    }

    renderedItems.push(
      renderCoreDataRow(
        t("address"),
        getCleanValue(items, "stammdaten_basis_adresse_strasse_hausnummer") +
          ", " +
          getCleanValue(items, "stammdaten_basis_adresse_postleitzahl") +
          " " +
          getCleanValue(items, "stammdaten_basis_adresse_ort")
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("number_of_employees"),
        getCleanValue(items, "stammdaten_basis_anzahl_mitarbeiter")
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("special_parking_situation"),
        getCleanValue(items, "stammdaten_basis_besonderheiten_parken_kunden")
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("contact_person_occupational_safety"),
        getCleanValue(
          items,
          "stammdaten_basis_unternehmen_arbeitsschutz_kontaktperson"
        ) +
          ", " +
          getCleanValue(
            items,
            "stammdaten_basis_email_unternehmen_arbeitsschutz"
          ) +
          ", " +
          getCleanValue(
            items,
            "stammdaten_basis_telefonnummer_unternehmen_arbeitsschutz"
          )
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("responsible_for"),
        getCleanValue(items, "stammdaten_intern_beauftragt_fuer")
      )
    );

    renderedItems.push(
      renderCoreDataRow(
        t("informal_or_formal_addressing"),
        getCleanValue(items, "stammdaten_intern_duzen_siezen")
      )
    );

    renderedItems.push(
      renderCoreDataRow(
        t("responsible_doctor"),
        getCleanValue(items, "stammdaten_intern_zustaendiger_arzt")
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("responsible_sifa_kaer"),
        getCleanValue(items, "stammdaten_intern_zustaendige_sifa_kaer")
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("medical_assistance_kaer"),
        getCleanValue(items, "stammdaten_intern_med_assistenz_kaer")
      )
    );
    renderedItems.push(
      renderCoreDataRow(
        t("general_information"),
        getCleanValue(items, "stammdaten_intern_allgemeine_infos_firma")
      )
    );

    return renderedItems;
  };

  const handleNavigate = (row: any, target: string) => {
    switch (target) {
      case "buildings":
        navigate(`/buildings/${row.subject.id}`);
        break;
      case "trackedservice":
        navigate(`/trackedservice/company/${row.subject.id}`);
        break;
      case "actionchain":
        navigate(`/actionchain/company/${row.subject.id}`);
    }
  };

  const setSubjectIdForBuildings = (row: any) => {
    setSubjectId(row.subject.id);
  };

  const customRowActions = (row: any) => {
    return (
      <>
        <Tooltip
          arrow
          placement="right"
          title={t("show_coredata", {
            company: row.original.name,
          })}
        >
          <GenericButton
            onClick={() => handleGetCoreDataIds(row.original)}
            variant="icon"
            color="iconBase"
          >
            <DatasetIcon />
          </GenericButton>
        </Tooltip>
        <Tooltip arrow placement="right" title={"Gebäude"}>
          <GenericButton
            onClick={() => handleNavigate(row.original, "buildings")}
            variant="icon"
            color="iconBase"
          >
            <MapsHomeWorkIcon />
          </GenericButton>
        </Tooltip>

        <Tooltip arrow placement="right" title={t("trackedservice")}>
          <GenericButton
            variant="icon"
            color="iconBase"
            onClick={() => handleNavigate(row.original, "trackedservice")}
          >
            <PaymentsIcon />
          </GenericButton>
        </Tooltip>
        <Tooltip arrow placement="right" title={t("actionchain_active_chains")}>
          <GenericButton
            onClick={() => handleNavigate(row.original, "actionchain")}
            variant="icon"
            color="iconBase"
          >
            <KaerIcon icon="actionChain" color="#707070" />
          </GenericButton>
        </Tooltip>
      </>
    );
  };

  const handleDemoCompanyChange = (selectedOption: any) => {
    if (selectedOption !== undefined) {
      setDemoCompanyId(selectedOption?.value);
    }
  };
  const handleDemoCompyResetInput = () => {
    setDemoCompanyId(null);
  };

  const customTopToolBarActions = () => {
    return (
      <>
        <section style={{ width: "25vw" }}>
          <DropDown
            options={demoCompanies}
            onChange={handleDemoCompanyChange}
            isClearable
            resetInput={handleDemoCompyResetInput}
            wrapperStyle={{ width: "100%" }}
            placeholder={t("choose_demo_company")}
          />
        </section>
        {demoCompanyId !== null && demoCompanyId !== undefined && (
          <GenericButton
            variant="outlined"
            color="tertiary"
            onClick={() => handleDeleteDemoCompany()}
          >
            {t("reset_demo")}
          </GenericButton>
        )}
      </>
    );
  };

  const handleTwoFactor = async (event: any, row: any) => {
    const newChecked = event.target.checked;
    const companyID = row.id;
    const name = row.name;

    const confirmationMessage = event.target.checked
      ? t("2fa_activation_company_admin", { name: name })
      : t("2fa_deactivation_company_admin", { name: name });

    const isConfirmed = await showConfirmationModalAsync(confirmationMessage, {
      disableConfirmFor: 3000,
    });

    if (!isConfirmed) return;

    api.genericApiRequest({
      method: "put",
      entity: "company",
      entityId: companyID,
      submitData: { requires_two_factor: newChecked },
      successHandler: () => {
        setNotificationMessage(t("successfully_saved"));
        setNotificationVariant("success");
        setShowNotification(true);
        setRefreshTable((prev: any) => !prev);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  return (
    <Restricted permissionRequired="frontend_view_company">
      {isLoading ? (
        <Backdrop className={styles.backdrop} open={true}>
          <div className={styles.loadingContainer}>
            <DotLoader color="#8c1ec8" size={65} />
            <h1>{t("reset_demo_loading")}</h1>
          </div>
        </Backdrop>
      ) : (
        <GenericCrudTable
          allowColumnFiltering={true}
          showColumnFilterbyDefault={true}
          showSubjectOnCompany={true}
          subjectIdForBuildings={subjectId}
          handleSetSubjectIdForBuildings={setSubjectIdForBuildings}
          heading={t("company")}
          entity={"company"}
          refreshTable={refreshTable}
          disallowDelete={disallowDelete}
          handleShowCoredata={handleGetCoreDataIds}
          customRowActions={customRowActions}
          additionalCustomTopToolbarElements={customTopToolBarActions}
          additionalColumnsForEdit={[
            {
              header: "",
              entity: "building",
              id: "main_building",
              accessorKey: "main_building",
              accessorFn: (row: any) => {
                if (
                  row.main_building !== null ||
                  row.main.building !== undefined
                ) {
                  return row.main_building.name;
                } else return null;
              },
              enableForCreate: true,
            },
          ]}
          additionalColumnsForCreate={[
            {
              header: `${t("buildings")} ${t("name")}`,
              accessorKey: "main_building.name",
              childEntityName: "main_building",
              id: "building_name",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
            },
            {
              header: `${t("buildings")} ${t("building_street_house")}`,
              accessorKey: "main_building.street_house",
              childEntityName: "main_building",
              id: "street_house",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
            },
            {
              header: `${t("buildings")} ${t("building_zip")}`,
              accessorKey: "main_building.zip",
              childEntityName: "main_building",
              id: "zip",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
            },
            {
              header: `${t("buildings")} ${t("building_city")}`,
              accessorKey: "main_building.city",
              childEntityName: "main_building",
              id: "city",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
            },
            {
              header: `${t("buildings")} ${t("building_contact_name")}`,
              accessorKey: "main_building.contact_name",
              childEntityName: "main_building",
              id: "contact_name",
              enableForCreate: true,
            },
            {
              header: `${t("buildings")} ${t("building_contact_email")}`,
              accessorKey: "main_building.contact_email",
              childEntityName: "main_building",
              id: "contact_email",
              enableForCreate: true,
            },
            {
              header: `${t("buildings")} ${t("building_contact_phone")}`,
              accessorKey: "main_building.contact_phone",
              childEntityName: "main_building",
              id: "contact_phone",
              enableForCreate: true,
            },
            {
              header: t("building_parking_situation"),
              accessorKey: "parking_situation",
              id: "parking_situation",
              enableForCreate: true,
            },
            {
              header: t("building_additional_information"),
              accessorKey: "additional_information",
              id: "additional_information",
              enableForCreate: true,
              textarea: true,
            },
          ]}
          columns={[
            {
              header: "ID",
              accessorKey: "id",
              id: "id",
              enableForCreate: true,
              hideField: true,
            },
            {
              header: t("name"),
              accessorKey: "name",
              id: "name",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
              accessorFn: (row: any) => {
                const companySubjectId = row.subject?.id;
                const companyName = row.name;
                const companyId = row.id;
                const companySubjectToDispatch = {
                  value: companySubjectId,
                  label: companyName,
                  companyId: companyId,
                };

                return (
                  <Link
                    to={"/subjectOverview"}
                    className={styles.companyNameLink}
                    onClick={() =>
                      navigateToSubjectOverviewForCompany(
                        companySubjectToDispatch
                      )
                    }
                  >
                    {companyName}
                  </Link>
                );
              },
            },
            {
              header: t("phone"),
              accessorKey: "phone",
              id: "phone",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
              enableColumnFilter: false,
            },
            {
              header: t("email"),
              accessorKey: "email",
              id: "email",
              enableForCreate: true,
              required: true,
              errorMessage: t("field_required"),
              customColumnFilterId: "email",
              customFilterParameter: "_icontains",
            },
            {
              header: t("team"),
              accessorFn: (row: any) => {
                if (row.team !== null) {
                  return row.team.name;
                } else return null;
              },
              id: "team",
              enableForCreate: true,
              entity: "team",
              required: true,
              errorMessage: t("field_required"),
              customColumnFilterId: "team",
              customFilterParameter: "_icontains",
            },
            {
              header: t("service_level"),
              id: "service_level",
              accessorKey: "service_level",
              accessorFn: (row: any) => {
                if (row.service_level) {
                  return t("service_level_" + row.service_level);
                }
              },
              filterVariant: "select",
              filterSelectOptions: [
                { value: "normal", label: t("service_level_normal") },
                { value: "key_account", label: t("service_level_key_account") },
                {
                  value: "single_service",
                  label: t("service_level_single_service"),
                },
                { value: "flatrate", label: t("service_level_flatrate") },
              ],
              enableForCreate: true,
              selectOptions: [
                {
                  value: "normal",
                  label: t("service_level_normal"),
                },
                {
                  value: "key_account",
                  label: t("service_level_key_account"),
                },
                {
                  value: "single_service",
                  label: t("service_level_single_service"),
                },
                {
                  value: "flatrate",
                  label: t("service_level_flatrate"),
                },
              ],
            },
            {
              header: t("parent"),
              accessorFn: (row: any) => {
                if (row.parent !== null) {
                  return row.parent.name;
                } else return null;
              },
              id: "parent",
              asyncDropDown: true,
              customColumnFilterId: "parent__name",
              asyncEntity: "company",
              enableForCreate: true,
              customLabel: t("parent_company"),
              queryParameter: "companies",
              setCompanyId: true,
              validate: true,
            },
            {
              header: t("2fa"),
              id: "requires_two_factor",
              accessorKey: "requires_two_factor",
              enableColumnFilter: false,
              accessorFn: (row: any) => {
                return (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Switch
                      checked={row.requires_two_factor}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(event: any) => handleTwoFactor(event, row)}
                    />
                  </div>
                );
              },
            },
            {
              header: t("building_main"),
              accessorKey: "main_building",
              enableColumnFilter: false,
              id: "main_building",
              accessorFn: (row: any) => {
                if (
                  row.main_building &&
                  (row.main_building !== null ||
                    row.main_building !== undefined)
                ) {
                  const building = row.main_building;
                  return (
                    <>
                      <span className={styles.buildingName}>
                        {building.name}
                      </span>
                      <br />
                      <span>
                        {building.street_house}, {building.zip} {building.city}
                      </span>
                    </>
                  );
                }
              },
            },
          ]}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          error={error}
          title={t("error_occurred")}
          onClosehandler={() => setError("")}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
      {showCoredataModal && (
        <Modal
          open={showCoredataModal}
          onClose={() => {
            setShowCoredataModal(false), setCombinedMetrics([]);
          }}
          title={t("coredata") + " - " + selectedCompanyname}
          hideSubmitButton={true}
          cancelButtonText={t("Ok")}
        >
          <List>{renderStammdatenView(combinedMetrics)}</List>
        </Modal>
      )}
    </Restricted>
  );
};

export default CompanyList;
