import React, { FC, useEffect, useState } from "react";
import Restricted from "../../consumer/restricted";
import { useNavigate, useParams } from "react-router-dom";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { replaceDate } from "../../helper/dateHandling";
import { convertEurocentToEuroForLabels } from "../../helper/formattingValues";
import styles from "./trackedService.module.css";
import GenericCrudTableModal from "../tables/genericCrudTableModal";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import GenericButton from "../forms/inputs/button/genericButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirmationModal } from "../../context/confirmationModalContext";

const TrackedServiceCompanyOverrideTemplate: FC = () => {
  const [error, setError] = useState<any>();
  const [subject, setSubject] = useState<any>(null);
  const [overrideModalOpen, setOverrideModalOpen] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>("insert");
  const [modalPresetValues, setModalPresetValues] = useState<any>([]);
  const [trackedServiceTemplateId, setTrackedServiceTemplateId] =
    useState<string>("");
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const [
    trackedServiceTemplateCompanyOverrideId,
    setTrackedServiceTemplateCompanyOverrideId,
  ] = useState<string>("");

  const { subjectId } = useParams() as {
    subjectId: string;
  };
  const { showConfirmationModalAsync } = useConfirmationModal();

  const triggerRefresh = () => {
    setRefreshTable((prev) => !prev);
  };

  useEffect(() => {
    if (subjectId) {
      api.genericApiRequest({
        entity: "subject",
        entityId: subjectId,
        method: "get",
        parametersToRender: { depth: "0" },
        successHandler: (res: any) => {
          setSubject(res.data);
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [subjectId]);

  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate("/admin/companyList");
  };

  let heading = t("buildings");
  if (subject) {
    heading =
      t("trackedservice_companyOverride") + ` - ${subject.company.name}`;
  }

  const trackedServiceCompanyColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("internal_name"),
      accessorKey: "internal_name",
      id: "internal_name",
    },
    {
      header: t("description"),
      accessorKey: "description",
      id: "description",
    },
    {
      header: t("estimated_duration"),
      accessorKey: "estimated_duration",
      id: "estimated_duration",

      errorMessage: t("field_required"),
    },
    {
      header:
        t("price_with_currency", { currencySymbol: "€" }) +
        " /" +
        "Standardpreis",
      accessorKey: "price",
      id: "price",

      accessorFn: (row: any) => {
        if (row.price !== null) {
          const overridePrice = convertEurocentToEuroForLabels(row.price);

          const originalPrice = `(${convertEurocentToEuroForLabels(
            row.original_price
          )})`;
          return (
            <section className={styles.priceColumnWrapper}>
              <span
                className={
                  row.override_applied ? styles.overridePrice : undefined
                }
              >
                {overridePrice}
              </span>
              {row.override_applied && <span>{originalPrice}</span>}
            </section>
          );
        } else return "-";
      },
    },
    {
      header: t("trackedservice_type"),
      accessorKey: "tracked_service_type",
      id: "tracked_service_type",

      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("category"),
      accessorKey: "category",
      id: "category",

      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("created_on"),
      accessorKey: "created_on",
      id: "created_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("created_by"),
      accessorKey: "created_by",
      customColumnFilterId: "created_by_name",
      id: "created_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("modified_on"),
      accessorKey: "modified_on",
      filterVariant: "date",
      id: "modified_on",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("modified_by"),
      accessorKey: "modified_by",
      customColumnFilterId: "modified_by_name",
      id: "modified_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
  ];
  const columnsForEdit = [
    {
      header: t("trackedservice_template"),
      accessorKey: "tracked_service_template",
      id: "tracked_service_template",
      entity: "trackedServiceTemplate",
      // enableForCreate: true,

      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("price_with_currency", { currencySymbol: "€" }),
      accessorKey: "price",
      id: "price",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{convertEurocentToEuroForLabels(cell.getValue())}</span>;
      },
    },
  ];

  const additionalDataforCreate = { company: subject?.company?.id };

  const customRowActions = (row: any) => {
    return (
      <>
        <Tooltip
          arrow
          placement="right"
          title={
            row.original.tracked_service_template_company_override_id === null
              ? t("trackedservice_add_override")
              : t("trackedservice_edit_override")
          }
        >
          <GenericButton
            variant="icon"
            onClick={() =>
              row.original.tracked_service_template_company_override_id === null
                ? openCreateModal(row.original)
                : openEditModal(row.original)
            }
          >
            {row.original.tracked_service_template_company_override_id ===
            null ? (
              <AddIcon />
            ) : (
              <EditIcon />
            )}
          </GenericButton>
        </Tooltip>
        {row.original.override_applied && (
          <Tooltip
            arrow
            placement="right"
            title={t("trackedservice_delete_override")}
          >
            <GenericButton
              variant="icon"
              color="error"
              onClick={() =>
                deleteCompanyOverride(
                  row.original.tracked_service_template_company_override_id
                )
              }
            >
              <DeleteIcon />
            </GenericButton>
          </Tooltip>
        )}
      </>
    );
  };
  const openEditModal = (row: any) => {
    setModalMode("edit");
    setOverrideModalOpen(true);
    setTrackedServiceTemplateCompanyOverrideId(
      row.tracked_service_template_company_override_id
    );
    setTrackedServiceTemplateId(row.id);
    setModalPresetValues({
      trackedServiceTemplateCompanyOverrideId:
        row.tracked_service_template_company_override_id,
      tracked_service_template: row.id,
      price: row.price,
    });
  };
  const openCreateModal = (row: any) => {
    setModalMode("insert");
    setOverrideModalOpen(true);
    setTrackedServiceTemplateId(row.id);

    setModalPresetValues({
      tracked_service_template: row.id,
      price: row.price,
    });
  };

  const submitCompanyOverride = (values: any) => {
    const result = {
      tracked_service_template: trackedServiceTemplateId,
      price: values.price,
      company: subject?.company?.id,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "trackedServiceTemplateCompanyOverride",
      method: modalMode === "insert" ? "post" : "put",
      successHandler: () => {
        setOverrideModalOpen(false);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
      ...(modalMode === "edit" && trackedServiceTemplateCompanyOverrideId
        ? { entityId: trackedServiceTemplateCompanyOverrideId }
        : {}),
    });
  };

  const deleteCompanyOverride = async (
    overrideId: string,
    skipQuestion = false
  ) => {
    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("trackedservice_delete_override_confirm")
      );
      if (!isConfirmed) return;
    }
    api.genericApiRequest({
      method: "delete",
      entity: "trackedServiceTemplateCompanyOverride",
      entityId: overrideId,
      successHandler: () => {
        triggerRefresh();
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  return (
    <Restricted permissionRequired="view_trackedservicetemplatecompanyoverride">
      {subjectId && (
        <Tooltip placement="right" title={t("back_to", { name: t("company") })}>
          <IconButton
            onClick={handleNavigateBack}
            sx={{ alignSelf: "flex-start" }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      {subject !== null && (
        <>
          <GenericCrudTable
            additionalSubmitDataForCreate={additionalDataforCreate}
            heading={heading}
            additionalUrlParameter={{ company_id: subject?.company?.id }}
            companyId={subject?.company?.id}
            refreshTable={refreshTable}
            disableAddItem
            hideDeleteButtonForRows
            hideEditButtonForRows
            additionalRoutePartsForGet={{ fetch_templates: "/" }}
            entity="trackedServiceTemplate"
            columns={trackedServiceCompanyColumns}
            customRowActions={customRowActions}
            customColumnVisibility={{
              description: false,
              estimated_duration: false,
              created_on: false,
              created_by: false,
              modified_on: false,
              modified_by: false,
            }}
          />
          <GenericCrudTableModal
            open={overrideModalOpen}
            title={modalMode === "edit" ? t("edit") : t("add")}
            columns={columnsForEdit}
            presetValues={modalPresetValues}
            onClose={() => setOverrideModalOpen(false)}
            submitButtonText={(function () {
              if (modalMode === "edit") {
                return t("save");
              }
              return t("add");
            })()}
            onSubmit={(function () {
              if (modalMode === "edit") {
                return submitCompanyOverride;
              }
              return submitCompanyOverride;
            })()}
          />
        </>
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </Restricted>
  );
};

export default TrackedServiceCompanyOverrideTemplate;
