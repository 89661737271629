import "./documents.css";
import { ReactNode, useEffect, useMemo, useState, Fragment } from "react";
import Modal from "../modal";
import DefaultInput from "../forms/inputs/defaultInput";
import FolderTree from "./folderTree";
import { IFileItem } from "../../types/FileItem";
import { useTranslation } from "react-i18next";
import FileUploadInput from "../forms/inputs/upload";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "./fileList.module.css";
import GenericButton from "../forms/inputs/button/genericButton";
import useFileAction from "../../helper/customHooks/useFileActions";
import { Box, Tooltip } from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { api } from "../../helper/api";
import dayjs from "dayjs";
// import styles from "./fileTable.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { ButtonColor } from "../forms/inputs/button/genericButton";
import { formatFilesize } from "../../helper/formattingValues";
import { replaceDate } from "../../helper/dateHandling";
import { formatFilenameString, previewFile } from "./fileUtils";
//ICONS
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import FileTableIcon from "./fileTableIcon";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FileTableRowActionMenuItem } from "./fileTableRowActionMenuItem";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  subjectId?: string;
  folderId: string;
  folderName?: string;
  onClickHandler?: (id: string) => void;
  query?: string;
  sortByName?: any;
  sortByDate?: any;
  folderUpdate?: any;
}

export default function FileList(props: Props) {
  const { t } = useTranslation();
  const [modalPresetValues, setModalPresetValues] = useState<IFileItem>();
  const [modalValue, setModalValue] = useState<any>([]);
  const [modalUploadOpen, setModalUploadOpen] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  // _____down new states from old filetable

  // InfiniteScroll
  const [isPageAtTop, setIsPageAtTop] = useState(false);
  const [usePagination, setUsePagination] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [paginationModeToggled, setPaginationModeToggled] = useState(false);
  const [showLoadingMoreSpinner, setShowLoadingMoreSpinner] = useState(false);
  const [loadMoreDataTriggerEl, setLoadMoreDataTriggerEl] =
    useState<HTMLElement | null>(null);
  // relevant TablesStates
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState(0);
  const [fileData, setFileData] = useState<IFileItem[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [isRefetching, setIsRefetching] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  //componenent/page related states
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [disableDeleteAllButton, setDisableDeleteAllButton] =
    useState<boolean>(false);
  const { showConfirmationModalAsync } = useConfirmationModal();

  // material ui
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // get current portal
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  // infinite scroll related
  const rootOutletContentContainer =
    document.getElementById("rootOutletContent");
  // default columVisibilities & sorting
  const columnSorting = "-created_on";
  const columnVisibility = {
    id: false,
    name: true,
    size: false,
    visible_to_subject: false,
    locked: false,
    created_on: false,
    extension: false,
    modified_on: false,
    created_by: false,
    modified_by: false,
  };
  // ReduxState
  const children: any = useSelector((state: RootState) => state.parent);
  const selectedChildCompany: any = useSelector(
    (state: RootState) => state.parent
  );
  //customHook imports
  const {
    performFileAction,
    uploadSuccess,
    updateUploadSuccess,
    moveFileSource,
    moveFileTarget,
    updateMoveFileSource,
    updateMoveFileTarget,
    moveFile,
    toggleModalMoveOpen,
    modalMoveOpen,
    editFileName,
    modalEditOpen,
    toggleModalEditOpen,
    deleteFile,
    handleDownloadFile,
    markFileAsDownloaded,
  } = useFileAction(setError, selectedChildCompany?.subjectId);

  //ColumnDefinitions--------------------------------------------------------------------------------
  //baseColumns - always visible (all Portals)
  //adminColumns for Portal === "admin"
  const baseColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: subdomain !== "admin" ? false : true,
        visibleInShowHideMenu: subdomain !== "admin" ? false : true,
      },

      {
        header: t("name"),
        accessorKey: "name",
        id: "name",
        enableColumnFilter: false,
        size: isMobile ? window.innerWidth * 0.8 : 400,
        Cell: ({ row, renderedCellValue }: any) => {
          return (
            <Box className={styles.fileTableName}>
              <FileTableIcon extension={row.getValue("extension")} />
              <a
                href={previewFile(row, children?.subjectId)}
                target="_blank"
                rel="noreferrer"
                className="fileTableNameLink"
                onClick={() => handleMarkFileAsDownloaded(row.getValue("id"))}
              >
                <Tooltip title={formatFilenameString(renderedCellValue)}>
                  <span
                    className={
                      !row.original.has_been_downloaded
                        ? styles.undownloadedFileBold
                        : undefined
                    }
                  >
                    {formatFilenameString(renderedCellValue)}
                  </span>
                </Tooltip>
              </a>
            </Box>
          );
        },
      },
      {
        header: t("filesize"),
        size: 80,
        enableColumnFiltering: false,
        id: "size",
        accessorKey: "size",
        enableColumnFilter: false,
        Cell: ({ row }: any) => {
          return formatFilesize(row.original.size);
        },
      },
      {
        header: t("status"),
        size: 80,
        id: "visible_to_subject, locked",
        Cell: ({ row }: any) => {
          return (
            <div className={styles.statusIcons}>
              {row.getValue("visible_to_subject") ? (
                <Tooltip title={t("visible")}>
                  <VisibilityIcon color="success" />
                </Tooltip>
              ) : (
                <Tooltip title={t("not_visible")}>
                  <VisibilityOffIcon color="error" />
                </Tooltip>
              )}

              {row.getValue("locked") ? (
                <Tooltip title={t("locked")}>
                  <LockIcon color="error" />
                </Tooltip>
              ) : (
                <Tooltip title={t("not_locked")}>
                  <LockOpenIcon color="success" />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        header: t("created_on"),
        accessorKey: "created_on",
        id: "created_on",
        size: 80,
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return (
            <span>
              {subdomain !== "admin"
                ? replaceDate(cell.getValue())
                : replaceDate(cell.getValue(), true)}
            </span>
          );
        },
      },
      {
        header: t("extension"),
        accessorKey: "extension",
        id: "extension",
      },
      {
        header: t("visible"),
        enableHiding: false,
        Header: <VisibilityIcon />,
        accessorKey: "visible_to_subject",
        visibleInShowHideMenu: false,

        id: "visible_to_subject",
        Cell: ({ row }: any) => {
          return (
            <>
              {row.getValue("visible_to_subject") ? (
                <VisibilityIcon color="success" />
              ) : (
                <VisibilityOffIcon color="error" />
              )}
            </>
          );
        },
      },
      {
        header: t("locked"),
        enableHiding: false,
        Header: <LockIcon />,
        accessorKey: "locked",
        visibleInShowHideMenu: false,

        id: "locked",
        Cell: ({ row }: any) => {
          return (
            <>
              {row.getValue("locked") ? (
                <LockIcon color="error" />
              ) : (
                <LockOpenIcon color="success" />
              )}
            </>
          );
        },
      },
    ],
    []
  );
  const adminColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: t("modified_on"),
        accessorKey: "modified_on",
        id: "modified_on",
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return <span>{replaceDate(cell.getValue())}</span>;
        },
      },
      {
        header: t("created_by"),
        accessorKey: "created_by",
        customColumnFilterId: "created_by_name",

        id: "created_by",
        Cell: ({ cell }: any) => {
          return <span>{cell.getValue().name}</span>;
        },
      },
      {
        header: t("modified_by"),
        accessorKey: "modified_by",
        customColumnFilterId: "modified_by_name",
        id: "modified_by",
        Cell: ({ cell }: any) => {
          return <span>{cell.getValue().name}</span>;
        },
      },
    ],
    []
  );
  //Setting columns for table - checking subdomain for baseColumns or adding adminColumns--------------------------------------------------------------------------------
  const columns =
    subdomain === "admin" ? [...baseColumns, ...adminColumns] : baseColumns;

  //defining TableOptions for setting them in one Place (easier adding and maintaining them) and accessing them within table.
  const tableOptions = {
    filters: true,
    columnHiding:
      subdomain === "admin" || subdomain === "manage" ? true : false,
  };
  //Defining the Table--------------------------------------------------------------------------------
  const table = useMaterialReactTable({
    data: fileData,
    columns,
    localization: {
      ...MRT_Localization_DE,
    },
    enableHiding: tableOptions.columnHiding,
    enableFullScreenToggle: false,
    enableFilters: tableOptions.filters,
    positionToolbarAlertBanner: "none",
    enableDensityToggle: isMobile ? false : true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    positionGlobalFilter: "left",
    enableColumnFilters: subdomain === "admin" ? true : false,
    manualPagination: true,
    rowCount: rowCount,
    enableRowSelection: true,
    manualFiltering: true,
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        visibleInShowHideMenu: false,
        size: 40,
        muiTableHeadCellProps: {
          align: "center",
          sx: {
            padding: "0.5rem",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            padding: "0.5rem",
            textAlign: "center",
            borderRight: "1px solid #e0e0e0",
            "& .MuiIconButton-root": {
              margin: 0,
            },
          },
        },
      },
      "mrt-row-select": {
        visibleInShowHideMenu: false,
        size: 40,
        muiTableHeadCellProps: {
          align: "center",
          sx: {
            padding: "0.5rem",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            padding: "0.5rem",
            textAlign: "center",
            borderRight: "1px solid #e0e0e0",
            "& .MuiIconButton-root": {
              margin: 0,
            },
          },
        },
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 240px)",
      },
    },
    muiTablePaperProps: {
      sx: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: isMobile ? "0" : "1rem",

        "&& .MuiCollapse-root, && .MuiCollapse-wrapperInner": {
          width: "100%",
        },
        "& .MuiAlert-root": {
          backgroundColor: "#fff",
        },
        "& .MuiInputBase-root, && .MuiInput-root::before, && .MuiInput-root::after":
        {
          borderBottom: "none",
        },
        "& .MuiTextField-root": {
          backgroundColor: "#f4f2f2",
          outline: "none",
          width: "100%",
          "& .MuiOutlinedInput-root": {
            fontSize: "1.25rem",
          },
          "& .MuiInput-root": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
        },
        "&& .MuiBox-root": {
          alignItems: "center",
        },
        "& .MuiTablePagination-root": {
          display: usePagination ? undefined : "none",
        },
        "& .MuiTableHead-root": { opacity: 1 },
      },
    },
    muiTopToolbarProps: {
      sx: isMobile
        ? {
          "&& .MuiBox-root": {
            display: "block",
          },
        }
        : {},
    },
    muiTableBodyRowProps: ({ row }: any) => {
      const isSecondToLastRow = row.index === fileData.length - 2;
      const endOfTableReached = fileData.length === rowCount;
      const shouldSetRef =
        !usePagination || isSecondToLastRow || !endOfTableReached;
      return {
        className: styles.tableRow,
        ref: shouldSetRef ? setLoadMoreDataTriggerEl : null,
        sx: {
          backgroundColor: row.index % 2 === 0 ? "#f5f5f5" : "#FFF",
        },
      };
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0",
      },
    },
    muiSearchTextFieldProps: {
      placeholder: t("search_files"),
      onClick: () => {
        setGlobalFilter("");
      },
    },
    initialState: {
      columnVisibility: columnVisibility,
      density: "comfortable",
      showGlobalFilter: true,
      showColumnFilters: subdomain === "admin" ? true : false,
    },
    state: {
      pagination: pagination,
      globalFilter: globalFilter,
      columnFilters: columnFilter,
      isLoading: isLoading,
      showProgressBars: isRefetching,
      sorting: sorting,
      rowSelection: rowSelection,
    },
    onRowSelectionChange: (rows: any) => setRowSelection(rows),
    onPaginationChange: (e: any) => {
      setPagination(e);
      setInitialLoading(false);
    },
    onGlobalFilterChange: (e: any) => {
      setGlobalFilter(e);
      setInitialLoading(false);
    },
    onColumnFiltersChange: (e: any) => {
      handleColumnFilter(e);
    },
    onSortingChange: (e) => {
      setSorting(e), setInitialLoading(false);
    },
    renderRowActionMenuItems: ({ closeMenu, row }) =>
      rowActionMenuDefinition.map((action: any) => (
        <FileTableRowActionMenuItem
          key={action.key}
          onClick={() => action.onClick(row, closeMenu)}
          disabled={action.disabled(row)}
          icon={action.icon(row)}
          label={action.label(row)}
        />
      )),
    renderBottomToolbar: () => {
      if (!usePagination) {
        return (
          <Box className={styles.bottomToolbarWrapper}>
            <div>
              <span className={styles.numOfFilesDisplayed}>
                {t("number_of_files_displayed")}:&nbsp;
              </span>
              {fileData.length} {t("out_of")} {rowCount}
            </div>
            <div className={styles.spinnerContainer}>
              <CircularProgress
                size={20}
                thickness={5}
                color="secondary"
                className={`
                      ${styles.loadingMoreSpinner}
                      ${showLoadingMoreSpinner
                    ? styles.loadingMoreSpinnerVisible
                    : styles.loadingMoreSpinnerHidden
                  }
                    `}
              />
            </div>
          </Box>
        );
      }
      return null;
    },
    renderTopToolbarCustomActions: ({ table }) => {
      let toolBarItems: ReactNode = <></>;

      const submitMultiple = async (
        submitMethod: any,
        submitEntity: any,
        data?: any,
        skipQuestion?: false
      ) => {
        const additionalRouteParts: any = {};
        let confirmQuestion = "";

        if (submitMethod === "download") {
          const selectedRows = table.getSelectedRowModel().flatRows;
          const rows = selectedRows.map((file) => file.original);

          handleDownloadFile(rows);
          return;
        }

        if (submitMethod === "edit") {
          for (const file of table.getSelectedRowModel().flatRows) {
            const row = file.original;
            handleOpenEditModal(row);
          }
          return;
        }

        if (submitMethod === "delete") {
          confirmQuestion = t("selected_files_delete");
        }

        if (submitMethod === "put") {
          for (const key in data) {
            if (key === "visible_to_subject") {
              if (data[key] === true) {
                confirmQuestion = t("selected_files_set_visible");
              } else confirmQuestion = t("selected_files_set_invisible");
            }
            if (key === "locked") {
              if (data[key] === true) {
                confirmQuestion = t("selected_files_lock_all");
              } else confirmQuestion = t("selected_files_unlock_all");
            }
          }
        }

        if (!skipQuestion) {
          const isConfirmed = await showConfirmationModalAsync(confirmQuestion);
          if (!isConfirmed) return;
        }
        try {
          const promises = table.getSelectedRowModel().flatRows.map((row) => {
            return new Promise((resolve) => {
              api.genericApiRequest({
                method: submitMethod,
                entity: submitEntity,
                entityId: row.original.id,
                additionalRouteParts: additionalRouteParts,
                submitData: data,

                successHandler: (res: any) => {
                  if (submitMethod === "put") {
                    setFileData((prevData) => {
                      return prevData.map((file) =>
                        file.id === row.original.id
                          ? {
                            ...file,
                            ...data,
                          }
                          : file
                      );
                    });
                  }

                  if (submitMethod === "delete") {
                    setFileData((prevData) =>
                      prevData.filter((file) => file.id !== row.original.id)
                    );
                  }

                  resolve(res.data);
                },
                failHandler: (error: any) => {
                  console.log(error);
                },
              });
            });
          });
          await Promise.all(promises).then(() => {
            setRowSelection({});

            fetchData();
          });
        } catch (error) {
          console.log(error);
        }
      };

      let paginationScrollToggle: ReactNode = <></>;
      if (!isMobile) {
        paginationScrollToggle = (
          <Tooltip
            title={
              usePagination
                ? t("change_to_infinite_scroll")
                : t("change_to_pagination")
            }
            placement="top"
          >
            <GenericButton
              variant="icon"
              color="iconBase"
              onClick={handlePaginationScrollToggle}
            >
              {usePagination ? <AutoStoriesIcon /> : <AllInclusiveIcon />}
            </GenericButton>
          </Tooltip>
        );
      }
      const renderActionButton = (
        title: any,
        icon: any,
        color: ButtonColor,
        action: any
      ) => (
        <Tooltip title={t(title)} placement="top">
          <GenericButton variant="icon" color={color} onClick={action}>
            {icon}
          </GenericButton>
        </Tooltip>
      );
      const renderMobileActionButton = (
        title: any,
        icon: any,
        action: any,
        disabled = false
      ) => (
        <Tooltip
          title={disabled && t("at_least_one_file_must_be_selected")}
          placement="top"
        >
          <span>
            <GenericButton
              startIcon={icon}
              className={styles.mobileActionButton}
              onClick={action}
              disabled={disabled}
            >
              {t(title)}
            </GenericButton>
          </span>
        </Tooltip>
      );

      toolBarItems = (
        <>
          {subdomain === "admin" && (
            <>
              {renderActionButton(
                t("visible_to_subject_all"),
                <VisibilityIcon color="success" />,
                "secondary",
                () =>
                  submitMultiple("put", "file", {
                    visible_to_subject: true,
                  })
              )}
              {renderActionButton(
                t("not_visible_to_subject_all"),
                <VisibilityOffIcon />,
                "error",
                () =>
                  submitMultiple("put", "file", {
                    visible_to_subject: false,
                  })
              )}
              {renderActionButton(
                t("file_unlock_all"),
                <LockOpenIcon />,
                "secondary",
                () => submitMultiple("put", "file", { locked: false })
              )}
              {renderActionButton(
                t("file_lock_all"),
                <LockIcon />,
                "error",
                () => submitMultiple("put", "file", { locked: true })
              )}
            </>
          )}
          {/* DownloadButton combining mobile and desktop */}
          <Tooltip title={t("download")} placement="top">
            <GenericButton
              variant={subdomain === "admin" ? "icon" : "contained"}
              onClick={() => submitMultiple("download", "file")}
              startIcon={subdomain !== "admin" && <DownloadIcon />}
              className={
                isMobile && subdomain !== "admin"
                  ? styles.mobileActionButton
                  : ""
              }
            >
              {subdomain === "admin" ? <DownloadIcon /> : t("download")}
            </GenericButton>
          </Tooltip>

          {/* Separate container for action buttons on Mobile */}
          <Box className={styles.mobileActionButtonsContainer}>
            {isMobile &&
              renderMobileActionButton(
                t("edit"),
                <EditIcon />,
                () => submitMultiple("edit", "file"),
                (subdomain !== "admin" &&
                  table
                    .getSelectedRowModel()
                    .flatRows.some((row: any) => row.original.locked)) ||
                Object.keys(rowSelection).length === 0 ||
                Object.keys(rowSelection).length > 1
              )}
            {/* DeleteButton */}
            <Tooltip
              placement="top"
              title={
                disableDeleteAllButton &&
                Object.keys(rowSelection).length !== 0 &&
                t("file_multidelte_not_all_eligible")
              }
            >
              <span>
                <GenericButton
                  onClick={() => {
                    submitMultiple("delete", "file");
                  }}
                  disabled={disableDeleteAllButton}
                  startIcon={<DeleteIcon />}
                  color="error"
                  className={`${isMobile ? styles.mobileActionButton : undefined
                    }`}
                >
                  {t("delete")}
                </GenericButton>
              </span>
            </Tooltip>
          </Box>
        </>
      );
      return (
        <Fragment>
          {Object.keys(rowSelection).length !== 0 && (
            <div className={styles.toolBarItemsContainer}>{toolBarItems}</div>
          )}
          {!isMobile && <div>{paginationScrollToggle}</div>}
        </Fragment>
      );
    },
  });

  //Effects & fetch--------------------------------------------------------------------------------
  useEffect(() => {
    if (
      (!props.folderId && moveFileSource.length !== 0) ||
      (!props.folderId && !uploadSuccess)
    ) {
      setInitialLoading(true);
    } else if (uploadSuccess || props.folderId || props.subjectId) {
      setInitialLoading(false);
    }
  }, [
    props.folderId,
    moveFileSource,
    uploadSuccess,
    props.subjectId,
    columnFilter,
  ]);

  const fetchData = async (pageIndex?: number) => {
    if (fileData.length === 0) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    const additionalUrlParameters: any = {};
    additionalUrlParameters["folder_id"] = props.folderId;

    if (props.subjectId) {
      additionalUrlParameters["impersonate_subject"] = props.subjectId;
    }
    if (columnFilter.length !== 0) {
      for (const key in columnFilter) {
        const singleColumnValues: any = columnFilter[key];
        const column = singleColumnValues.id;
        let columnSearchValue = singleColumnValues.value;
        const columnDef: any = table.getColumn(column).columnDef;
        let customFilterId = "";
        let filterVariant = "";
        if (columnDef?.customColumnFilterId) {
          customFilterId = columnDef?.customColumnFilterId;
        }
        if (columnDef?.filterVariant) {
          filterVariant = columnDef?.filterVariant;
        }

        if (filterVariant === "date") {
          const tempDateString = new Date(
            singleColumnValues.value
          ).toISOString();
          columnSearchValue = dayjs(tempDateString).format("YYYY-MM-DD");
          additionalUrlParameters[column + "__date"] = columnSearchValue;
        } else if (customFilterId !== "") {
          additionalUrlParameters[customFilterId + "__icontains"] =
            columnSearchValue;
        } else
          additionalUrlParameters[column + "__icontains"] = columnSearchValue;
      }
    }

    if (!pageIndex) {
      pageIndex = pagination.pageIndex;
    }

    try {
      /*eslint-disable */
      const res = await api.genericApiRequest({
        entity: "file",
        method: "get",
        parametersToRender: {
          depth: "0",
          ordering: columnSorting,
          limit: pagination.pageSize,
          offset: pageIndex * pagination.pageSize,
          search: globalFilter,
          additionalUrlParameters: additionalUrlParameters,
        },

        successHandler: (res: any) => {
          /*eslint-enable */
          const data = res.data;
          if (usePagination) {
            setFileData(data.results);
          } else {
            setFileData((prevData) => {
              const newData = data.results.filter(
                (newFile: any) =>
                  !prevData.some((prevFile: any) => prevFile.id === newFile.id)
              );
              return [...prevData, ...newData];
            });
          }
          setRowCount(data.count);
          setIsLoading(false);
          setInitialLoading(true);
          const files = [];
          for (const key in data.results) {
            const item = data.results[key];
            const id = item.id;
            const name = item.name;
            files.push({ id: id, name: name });
          }

          handleUploadReset();
        },
      });
    } catch (error) {
      setError(error);
      return;
    }
    setIsRefetching(false);
  };

  useEffect(() => {
    if (!initialLoading) {
      fetchData();
    }
  }, [
    props.folderId,
    moveFileSource,
    props.subjectId,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    if (!globalFilter) {
      setIsLoading(true);
      setFileData([]);
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      fetchData(0);
      return;
    }

    if (globalFilter.length < 3) {
      return;
    }

    setIsLoading(true);
    setFileData([]);
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    fetchData(0);
  }, [globalFilter, columnFilter]);

  const debounce = (func: any, delay: number) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    let observer: IntersectionObserver | null = null;
    const tableContainer = document.querySelector(".MuiTableContainer-root");

    const handleIntersection = debounce(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];
        if (
          entry.isIntersecting &&
          !isLoading &&
          !isRefetching &&
          fileData.length < rowCount
        ) {
          setShowLoadingMoreSpinner(true);
          handleLoadMoreData();
        }
      },
      300
    );

    const handleScroll = debounce(() => {
      if (!usePagination && !isLoading && !isRefetching && tableContainer) {
        const bottomReached =
          tableContainer.scrollHeight - tableContainer.scrollTop <=
          tableContainer.clientHeight + 100;
        const allDataFetched = fileData.length === rowCount;

        if (bottomReached && !allDataFetched) {
          setShowLoadingMoreSpinner(true);
          handleLoadMoreData();
        } else if (allDataFetched) {
          setShowLoadingMoreSpinner(false);
        }
      }
    }, 300);

    if (loadMoreDataTriggerEl && !usePagination) {
      observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.5,
      });
      observer.observe(loadMoreDataTriggerEl);
    }

    if (isMobile && tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (observer && loadMoreDataTriggerEl) {
        observer.unobserve(loadMoreDataTriggerEl);
        observer.disconnect();
        observer = null;
      }
      if (isMobile && tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
      setTimeout(() => {
        setShowLoadingMoreSpinner(false);
      }, 2000);
    };
  }, [
    loadMoreDataTriggerEl,
    isLoading,
    isRefetching,
    usePagination,
    isMobile,
    fileData.length,
    rowCount,
  ]);

  useEffect(() => {
    const savedPaginationMode = localStorage.getItem("savedPaginationMode");

    if (isMobile) {
      setUsePagination(false);
    } else if (savedPaginationMode === "paginationMode") {
      setUsePagination(true);
    } else {
      setUsePagination(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (paginationModeToggled) {
      setFileData([]);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 0,
      }));
      fetchData(0);
      setPaginationModeToggled(false);
    }
  }, [paginationModeToggled]);

  useEffect(() => {
    const isElementScrollable = (element: any) => {
      return element && element.scrollHeight > element.clientHeight;
    };

    const checkScrollable = () => {
      if (rootOutletContentContainer) {
        if (isElementScrollable(rootOutletContentContainer) && rowCount !== 0) {
          if (!showScrollButton) {
            setShowScrollButton(true);
          }
        } else {
          if (showScrollButton) {
            setTimeout(() => setShowScrollButton(false), 250);
          }
        }
      }
    };

    checkScrollable();
    window.addEventListener("resize", checkScrollable);
    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, [rowCount, showScrollButton]);

  useEffect(() => {
    const handleScroll = () => {
      if (rootOutletContentContainer) {
        const scrollTop = rootOutletContentContainer.scrollTop;
        const isPageAtTop = scrollTop <= 10;
        setIsPageAtTop(isPageAtTop);
      }
    };
    const debouncedHandleScroll = debounce(handleScroll, 100);

    if (rootOutletContentContainer) {
      rootOutletContentContainer.addEventListener(
        "scroll",
        debouncedHandleScroll
      );
    }

    handleScroll();
    return () => {
      if (rootOutletContentContainer) {
        rootOutletContentContainer.removeEventListener(
          "scroll",
          debouncedHandleScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    const data = table
      .getSelectedRowModel()
      .flatRows.map((item: any) => item.original);
    const hasLockedRows = data.some((item: any) => item.locked);
    setDisableDeleteAllButton(hasLockedRows);
  }, [rowSelection]);

  const handleDeleteFile = (file: any) => {
    deleteFile(file, (deletedFileId) => {
      setFileData((prev) => prev.filter((f) => f.id !== deletedFileId));
      fetchData();
    });
  };
  const rowActionMenuDefinition = [
    {
      key: 0,
      onClick: (row: any, closeMenu: () => void) => {
        handleOpenEditModal(row.original);
        closeMenu();
      },
      disabled: (row: any) => subdomain !== "admin" && row.original.locked,
      icon: () => <EditIcon />,
      label: () => t("edit"),
    },
    {
      key: 1,
      onClick: (row: any, closeMenu: () => void) => {
        // deleteFile(row.original);
        handleDeleteFile(row.original);
        closeMenu();
      },
      disabled: (row: any) => row.original.locked,
      icon: () => <DeleteIcon color="error" />,
      label: () => t("delete"),
    },
    {
      key: 2,
      onClick: (row: any, closeMenu: () => void) => {
        handleDownloadFile(row.original);
        closeMenu();
      },
      disabled: () => false,
      icon: () => <DownloadIcon />,
      label: () => t("download"),
    },
    subdomain === "admin"
      ? {
        key: 3,
        onClick: (row: any, closeMenu: () => void) => {
          if (moveFileSource?.length === 0) {
            openMoveFileModal(row.original);
          }
          closeMenu();
        },
        disabled: () => false,
        icon: () => <DriveFileMoveIcon />,
        label: () => t("move"),
      }
      : null,

    subdomain === "admin"
      ? {
        key: 4,
        onClick: (row: any, closeMenu: () => void) => {
          row.original.reviewed
            ? handleMarkAsUnreviewed(row.original)
            : handleMarkAsReviewed(row.original);
          closeMenu();
        },
        disabled: () => false,
        icon: (row: any) =>
          row.original.reviewed ? (
            <CheckCircleOutlineIcon color="error" />
          ) : (
            <CheckCircleOutlineIcon color="success" />
          ),
        label: (row: any) =>
          row.original.reviewed ? t("set_unreviewed") : t("set_reviewed"),
      }
      : null,

    subdomain === "admin"
      ? {
        key: 5,
        onClick: (row: any, closeMenu: () => void) => {
          row.original.visible_to_subject
            ? handleMarkAsInvisibleToSubject(row.original)
            : handelMarkAsVisibleToSubject(row.original);
          closeMenu();
        },
        disabled: () => false,
        icon: (row: any) =>
          row.original.visible_to_subject ? (
            <VisibilityOffIcon color="error" />
          ) : (
            <VisibilityIcon color="success" />
          ),
        label: (row: any) =>
          row.original.visible_to_subject
            ? t("not_visible_to_subject")
            : t("visible_to_subject"),
      }
      : null,

    subdomain === "admin"
      ? {
        key: 6,
        onClick: (row: any, closeMenu: () => void) => {
          row.original.locked
            ? handleMarkAsUnlocked(row.original)
            : handleMarkAsLocked(row.original);
          closeMenu();
        },
        disabled: () => false,
        icon: (row: any) =>
          row.original.locked ? (
            <LockOpenIcon color="success" />
          ) : (
            <LockIcon color="error" />
          ),
        label: (row: any) =>
          row.original.locked ? t("file_locked") : t("file_unlocked"),
      }
      : null,
  ].filter(Boolean);

  //HandleFunctions--------------------------------------------------------------------------------
  const handleUpdateFileData = (updatedFields: any) => {
    setFileData((prev: any[]) =>
      prev.map((file) =>
        file.id === updatedFields.id ? { ...file, ...updatedFields } : file
      )
    );
  };
  const handleUploadReset = () => {
    updateUploadSuccess(false);
  };

  const handleOpenEditModal = (item: any) => {
    setModalPresetValues(item);
    setModalValue({
      name: item.name,
      extension: item.extension,
    });
    toggleModalEditOpen(true);
  };

  const handleEditModalValueChange = (event: any) => {
    event.preventDefault();
    setModalValue({
      name: event.target.value,
      extension: modalValue.extension,
    });
  };

  const handleMoveFileCancel = () => {
    updateMoveFileSource([]);
    toggleModalMoveOpen(false);
  };

  const openMoveFileModal = (item: IFileItem) => {
    updateMoveFileSource(item);
    toggleModalMoveOpen(true);
  };

  const handleMarkAsReviewed = (item: any) => {
    performFileAction(item, "markAsReviewed", (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };
  const handleMarkAsUnreviewed = (item: any) => {
    performFileAction(item, "markAsUnreviewed", (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };

  const handelMarkAsVisibleToSubject = (item: any) => {
    performFileAction(item, "markAsVisibleToSubject", (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };

  const handleMarkAsInvisibleToSubject = (item: any) => {
    performFileAction(item, "markAsInvisibleToSubject", (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };

  const handleMarkAsLocked = (item: any) => {
    performFileAction(item, "markAsLocked", (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };

  const handleMarkAsUnlocked = (item: any) => {
    performFileAction(item, "markAsUnLocked", (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };
  const handleColumnFilter = (searchValues: any) => {
    setColumnFilter(searchValues);
  };

  const handleMarkFileAsDownloaded = (id: string) => {
    markFileAsDownloaded(id, subdomain, (updatedFile) => {
      handleUpdateFileData(updatedFile);
      fetchData();
    });
  };

  const handleLoadMoreData = () => {
    if (isRefetching || fileData.length >= rowCount) {
      return;
    } else if (fileData.length < rowCount) {
      const nextPageIndex = pagination.pageIndex + 1;
      fetchData(nextPageIndex);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: nextPageIndex,
      }));
    }
  };

  const handlePaginationScrollToggle = () => {
    const fileTablePaginationMode = !usePagination;
    setUsePagination((prev) => !prev);
    setPaginationModeToggled(true);
    localStorage.setItem(
      "savedPaginationMode",
      fileTablePaginationMode ? "paginationMode" : "infiniteScrollMode"
    );
  };
  const handleScrollToTop = () => {
    if (rootOutletContentContainer) {
      rootOutletContentContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleScrollToBottom = () => {
    if (rootOutletContentContainer) {
      rootOutletContentContainer.scrollTo({
        top: rootOutletContentContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleEditFileName = (
    modalValue: any,
    selectedChildCompany: any,
    modalPresetValues: any
  ) => {
    editFileName(
      modalValue,
      selectedChildCompany,
      modalPresetValues,
      (updatedFile) => {
        handleUpdateFileData(updatedFile);
        fetchData();
      }
    );
  };
  const handleMoveFile = () => {
    moveFile((movedFileId) => {
      setFileData((prev) => prev.filter((f) => f.id !== movedFileId));
      fetchData();
    });
  };
  return (
    <>
      {(((subdomain === "manage" || subdomain === "portal") &&
        props.folderName?.toLowerCase() === "upload") ||
        (subdomain === "admin" &&
          props.folderName &&
          props.folderName.toLowerCase() !== "upload")) && (
          <>
            <GenericButton
              className={styles.uploadButton}
              onClick={() => {
                setModalUploadOpen(true);
              }}
            >
              {t("upload")}
            </GenericButton>

            <Modal
              open={modalUploadOpen}
              onClose={() => setModalUploadOpen(false)}
              title={t("upload_file")}
              hideDialogActions={true}
            >
              <FileUploadInput
                multiple={true}
                subjectId={props.subjectId}
                folderId={props.folderId}
                name="file"
                onCancel={() => {
                  setModalUploadOpen(false);
                }}
                handleUploadSuccess={(modalOpen: boolean) => {
                  if (modalOpen) {
                    setModalUploadOpen(false);
                  }
                  updateUploadSuccess(true);
                  props.folderUpdate();
                }}
              />
            </Modal>
          </>
        )}
      <Modal
        presetValues={modalPresetValues}
        open={modalEditOpen}
        onClose={() => toggleModalEditOpen(false)}
        submitButtonText={t("save")}
        onSubmit={() =>
          handleEditFileName(
            modalValue,
            selectedChildCompany,
            modalPresetValues
          )
        }
        title={t("edit_file")}
      >
        <DefaultInput
          value={modalValue.name}
          onChange={handleEditModalValueChange}
        />
      </Modal>
      <Modal
        presetValues={modalPresetValues}
        open={modalMoveOpen}
        onClose={handleMoveFileCancel}
        submitButtonText={t("move")}
        onSubmit={handleMoveFile}
        title={t("choose_folder")}
      >
        <div>
          <FolderTree
            onClickHandler={(folderId: any) => updateMoveFileTarget(folderId)}
            folderId={props.folderId}
            moveFileTarget={moveFileTarget}
            subjectId={props.subjectId}
          />
        </div>
      </Modal>
      <MaterialReactTable table={table} />
      {showScrollButton && (
        <GenericButton
          onClick={isPageAtTop ? handleScrollToBottom : handleScrollToTop}
          className={styles.scrollToTopButton}
        >
          {isPageAtTop ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </GenericButton>
      )}

      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
}
