import { FC } from "react";
import {
  Handle,
  Position,
  NodeProps,
  NodeToolbar,
  useStore,
} from "@xyflow/react";
import styles from "./actionChains.module.css";
import classNames from "classnames";
import GenericButton from "../forms/inputs/button/genericButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface CustomNodeProps extends NodeProps {
  data: {
    label: string;
    onDelete?: (id: string) => void;
    onOpenModal: (open: boolean, id: string, data: any) => void;
    copyPasteItem: (selectedNodes: any) => void;
    action?: string;
    itemId?: string;
    additional_data?: string;
    id?: string;
    status?: any;
    is_entrypoint?: any;
    previous_items_and?: any[];
    previous_items_or?: any[];
  };
  nodeWidth?: string;
  nodeHeight?: string;
  fontSize?: string;
  selected?: any;
}

const ActionChainCustomNode: FC<CustomNodeProps> = ({
  id,
  data,
  type,
  nodeWidth,
  nodeHeight,
  fontSize,
  selected,
}) => {
  const selectedNodes = useStore((state) =>
    state.nodes.filter((n) => n.selected)
  );
  const companyProgressWrapperClassnames = classNames(
    styles.companyProgressWrapper,
    {
      [styles.status_open]: data.status === "open" || !data.status,
      [styles.status_inprogress]: data.status === "in_progress",
      [styles.status_completed]: data.status === "completed",
      [styles.is_entrypoint]: data.is_entrypoint === true,
    }
  );
  const withDeleteHandlerWrapperClassnames = classNames(
    styles.withDeleteHandlerWrapper,
    {
      [styles.is_entrypoint]: data.is_entrypoint === true,
      [styles.noConnection]:
        data.previous_items_and?.length === 0 &&
        data.previous_items_or?.length === 0 &&
        !data.is_entrypoint,
      [styles.nodeSelected]: selected,
    }
  );

  const renderCustomNode = () => {
    switch (type) {
      case "withDeleteHandler":
        return (
          <>
            {!data.is_entrypoint && (
              <>
                <NodeToolbar
                  className={styles.nodeToolbar}
                  position={Position.Left}
                >
                  <GenericButton
                    variant="icon"
                    color="iconBase"
                    onClick={() => {
                      if (!data.is_entrypoint) {
                        data.onOpenModal(true, id, data);
                      }
                    }}
                  >
                    <EditIcon />
                  </GenericButton>

                  <GenericButton
                    variant="icon"
                    color="iconBase"
                    onClick={() => data.copyPasteItem(selectedNodes)}
                  >
                    <ContentCopyIcon />
                  </GenericButton>
                </NodeToolbar>
                <NodeToolbar
                  className={styles.nodeToolbar}
                  position={Position.Right}
                >
                  <GenericButton
                    variant="icon"
                    color="error"
                    onClick={(event: any) => {
                      event.stopPropagation();
                      data?.onDelete?.(id);
                    }}
                  >
                    <DeleteIcon />
                  </GenericButton>
                </NodeToolbar>
              </>
            )}
            <div
              className={withDeleteHandlerWrapperClassnames}
              style={{
                height: nodeHeight || "auto",
                width: nodeWidth || "auto",
              }}
            >
              {/* {!data.is_entrypoint && (
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation();
                    data?.onDelete?.(id);
                  }}
                  color="error"
                  className={styles.customNodeDeleteButton}
                  sx={{
                    position: "absolute",
                    top: -30,
                    right: -25,
                    cursor: "pointer",
                    background: "#fff",
                    padding: "0",
                  }}
                >
                  <HighlightOffIcon fontSize="large" />
                </IconButton>
              )} */}

              <h1 className={styles.actionHeading}>{data.label}</h1>
              {!data.is_entrypoint && (
                <div className={styles.actionLabel}> {data.action}</div>
              )}
              {!data.is_entrypoint && (
                <Handle
                  type="target"
                  position={Position.Top}
                  className={styles.nodeHandle}
                />
              )}
              <Handle
                type="source"
                position={Position.Bottom}
                className={styles.nodeHandle}
              />
            </div>
          </>
        );
        break;
      case "companyProgress":
        return (
          <div
            className={companyProgressWrapperClassnames}
            style={{ height: nodeHeight || "auto", width: nodeWidth || "auto" }}
          >
            <div style={{ fontSize: fontSize }}>{data.label}</div>
            {!data.is_entrypoint && (
              <Handle
                type="target"
                position={Position.Top}
                className={styles.nodeHandle_progress}
                isConnectable={false}
              />
            )}
            <Handle
              type="source"
              position={Position.Bottom}
              className={styles.nodeHandle_progress}
              isConnectable={false}
            />
          </div>
        );
    }
  };
  return <>{renderCustomNode()}</>;
};

export default ActionChainCustomNode;
