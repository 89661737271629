import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  ListItem,
  Tooltip,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import FileTableIcon from "../files/fileTableIcon";
import { t } from "i18next";
import AppointmentTreeItemUploadButton from "./appointmentTreeItemUploadButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useConfirmationModal } from "../../context/confirmationModalContext";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import GenericButton from "../forms/inputs/button/genericButton";
import styles from "./appointmentTreeItemFileList.module.css";

interface Props {
  appointment: any;
  handleReloadTrigger?: any;
}

const AppointmentTreeItemFileList: FC<Props> = ({
  appointment,
  handleReloadTrigger,
}) => {
  const [expanded, setExpanded] = useState<string | false>("fileList");
  const [notification, setNotification] = useState<string | null>(null);
  const [error, setError] = useState<any>(null);

  const { showConfirmationModalAsync } = useConfirmationModal();

  const [documentsVisible, setDocumentsVisible] = useState<boolean>(false);
  useEffect(() => {
    const fetchVisibility = async () => {
      try {
        const response = await fetch(
          `/api/v1/file/?impersonate_subject=${appointment.subject.id}`
        );
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          const allVisible = data.results.every((file: any) => file.visible_to_subject === true);
          setDocumentsVisible(allVisible);
        } else {
          setDocumentsVisible(false);
        }
      } catch (error) {
        console.error("Failed to fetch visibility:", error);
      }
    };
    fetchVisibility();
  }, [appointment]);

  let appointmentFilesList: any = <></>;
  const renderAppointmentFilesList = () => {
    const files: any = {};
    const renderedListItems = [];
    for (const fileNumber in appointment.file_set) {
      const fileItem = appointment.file_set[fileNumber];
      files[fileItem.name] = fileItem.view_link;
    }

    for (const filename in files) {
      const pretifiedFilename = filename.replace("_", " ");
      renderedListItems.push(
        <ListItem key={files[filename]} className={styles.listItem}>
          <FileTableIcon extension={"pdf"} />
          <a
            className={styles.link}
            href={"/api/v1" + files[filename]}
            target="_blank"
            rel="noreferrer"
          >
            {pretifiedFilename}
          </a>
        </ListItem>
      );
    }

    if (renderedListItems.length > 0) {
      return renderedListItems;
    }

    return false;
  };
  if (appointment) {
    appointmentFilesList = renderAppointmentFilesList();
  }

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleToggleVisibility = async () => {
    const newVisible = !documentsVisible;
    const promise = Object.values(appointment.file_set).map(
      async (file: any) => {
        const response = await fetch(
          `/api/v1/file/${file.id}/?impersonate_subject=${appointment.subject.id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ visible_to_subject: newVisible }),
          }
        );
        if (!response.ok) {
          throw new Error("File visibility update failed");
        }
      }
    );

    try {
      await Promise.all(promise);
      setDocumentsVisible(newVisible);
      setNotification(
        newVisible ? t("set_to_visible_success") : t("set_to_invisible_success")
      );
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleDeleteDocuments = async () => {
    const promises = Object.values(appointment.file_set).map(
      async (file: any) => {
        const unlockResponse = await fetch(`/api/v1/file/${file.id}/`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ locked: false }),
        });
        if (!unlockResponse.ok) {
          throw new Error(`Unlocking failed for file ${file.id}`);
        }
        const deleteResponse = await fetch(
          `/api/v1/file/${file.id}/?impersonate_subject=${appointment.subject.id}`,
          { method: "DELETE" }
        );
        if (!deleteResponse.ok) {
          throw new Error("Delete operation failed");
        }
      }
    );

    try {
      await Promise.all(promises);
      setNotification(t("delete_successful"));
      if (handleReloadTrigger) {
        handleReloadTrigger();
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const accordionActionPopperStyle = {
    modifiers: [
      {
        name: "offset",
        options: { offset: [0, -7] },
      },
    ],
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Accordion
          className={styles.accordion}
          expanded={expanded === "fileList"}
          onChange={handleExpand("fileList")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={styles.accordionSummary}>
              <h2 className={styles.accordionTitle}>
                {t("documents_of_appointment")}
              </h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === "fileList" && (
              <div className={styles.accordionActions}>
                {appointment &&
                  appointment.file_set &&
                  Object.keys(appointment.file_set).length > 0 && (
                    <>
                      <Tooltip
                        title={
                          documentsVisible
                            ? t("switch_generated_documents_to_invisible")
                            : t("switch_generated_documents_to_visible")
                        }
                        placement="top"
                        PopperProps={accordionActionPopperStyle}
                      >
                        <GenericButton
                          variant="icon"
                          onClick={async () => {
                            const confirmed = await showConfirmationModalAsync(
                              documentsVisible
                                ? t("switch_generated_documents_to_invisible_warning")
                                : t("switch_generated_documents_to_visible_warning"),
                            );
                            if (confirmed) {
                              handleToggleVisibility();
                            }
                          }}
                        >
                          {documentsVisible ? <VisibilityIcon color="success" /> : <VisibilityOffIcon color="error" />}
                        </GenericButton>
                      </Tooltip>
                      <Tooltip
                        title={t("delete_generated_documents")}
                        placement="top"
                        PopperProps={accordionActionPopperStyle}
                      >
                        <GenericButton
                          variant="icon"
                          onClick={async () => {
                            const confirmed = await showConfirmationModalAsync(
                              t("delete_generated_documents_warning"),
                            );
                            if (confirmed) {
                              handleDeleteDocuments();
                            }
                          }}
                        >
                          <DeleteIcon color="error" />
                        </GenericButton>
                      </Tooltip>
                    </>
                  )}
                <AppointmentTreeItemUploadButton
                  useCase="documentWidget"
                  appointmentId={appointment?.id}
                  subject={appointment?.subject}
                  handleTriggerReload={handleReloadTrigger}
                />
              </div>
            )}
            {appointmentFilesList}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {notification && (
        <GenericNotification
          message={notification}
          variant="success"
          handleCloseSnackbar={() => setNotification(null)}
        />
      )}
      {error && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => setError(null)}
        />
      )}
    </Grid>
  );
};

export default AppointmentTreeItemFileList;
