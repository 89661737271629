import { getStyle } from "./dndStyle";
import { DndWrapper } from "./dndWrapper";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./formEditorStyles.css";
import { LeftColumnWrapper } from "./dndLeftColumnWrapper";
import { Tooltip, IconButton } from "@mui/material";
import { DnDCollapsible } from "./dndCollapsible";
import { t } from "i18next";
import { Link } from "react-router-dom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styles from "./dndItem.module.css";

interface DNDStyle {
  height?: number;
  left?: number;
  position?: string;
  right?: number;
  top?: number;
  width?: string;
  borderRadius?: string;
  border?: string;
  alignItems?: string;
  justifyContent?: string;
}
export interface DNDItem {
  conditions?: any;
  created_on?: string;
  created_by?: string;
  id: string;
  label: string;
  modified_on?: string;
  modified_by?: string;
  name: string;
  next_version?: number;
  reuse?: boolean;
  sample_data?: any;
  trace_id?: string;
  type?: string;
  unit?: string;
  value_map?: any;
  version?: number;
  mandatory?: boolean;
  visibility_ruleset?: any;
  observation?: any;
  assessment?: any;
  dangerous_answer?: any;
  possible_remedy?: any;
  danger_potential?: any;
}
interface Props {
  provided?: any;
  item: DNDItem | any;
  style?: DNDStyle | string;
  isDragging?: boolean;
  columnId?: string;
  handleDeleteItem?: (id: string | undefined) => void;
  onHandleCheckboxChange?: (id: string) => void;
  onHandleRulesetChange?: (event?: any, id?: any) => void;

  preExistingFormElements?: any;
}

export default function Item({
  provided,
  item,
  style,
  isDragging,
  columnId,
  handleDeleteItem,
  preExistingFormElements,
  onHandleCheckboxChange,
  onHandleRulesetChange,
}: Props) {
  function renderWarningSign() {
    const propertiesToCheck = [
      "observation",
      "assessment",
      "dangerous_answer",
      "possible_remedy",
      "danger_potential",
    ];
    let warning = false;
    for (const property of propertiesToCheck) {
      if (
        item[property] === null ||
        item[property] === undefined ||
        item[property] === ""
      ) {
        warning = true;
      }
    }

    if (warning) {
      return (
        <Tooltip
          arrow
          placement="top"
          title={t("formeditor_variable_not_usable")}
        >
          <IconButton>
            <WarningAmberIcon color="warning" />
          </IconButton>
        </Tooltip>
      );
    }
  }

  function renderVisibilityIndicator() {
    if (
      item?.visibility_ruleset &&
      (typeof item.visibility_ruleset === "string"
        ? item.visibility_ruleset.trim() !== "{}"
        : JSON.stringify(item.visibility_ruleset) !== "{}")
    ) {
      return (
        <Tooltip
          arrow
          placement="top"
          title={t("formeditor_visibility_ruleset_present")}
        >
          <IconButton>
            <VisibilityIcon color="success" />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  }

  return (
    <div
      {...provided.draggableProps}
      {...(columnId === "column-0" && provided.dragHandleProps)}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging,
      })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
    >
      {columnId === "column-1" && (
        <div className={styles.iconWrapper}>
          <>
            <div {...provided.dragHandleProps}>
              <DragHandleIcon />
              {renderWarningSign()}
              {renderVisibilityIndicator()}
            </div>
            <div className={styles.rightColumnWrapper}>
              <Tooltip
                arrow
                placement="left"
                title={t("formeditor_measurementdefinition_edit", {
                  name: item?.label,
                })}
              >
                <Link
                  to={"/settings/createMeasurementDefinition/" + item?.id}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip arrow placement="left" title={t("formeditor_copy_name")}>
                <IconButton
                  type="button"
                  aria-label="copy"
                  onClick={() => {
                    navigator.clipboard.writeText(item?.name);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title={t("delete")}>
                <IconButton
                  color="error"
                  type="button"
                  aria-label="delete"
                  onClick={() => handleDeleteItem?.(item?.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </>
        </div>
      )}
      {columnId === "column-0" && (
        <LeftColumnWrapper
          label={item?.label}
          type={item?.type}
          name={item?.name}
        />
      )}
      {columnId === "column-1" && (
        <>
          <DndWrapper
            columnId={columnId}
            type={item?.type}
            name={item?.name}
            label={item?.label}
            rows={2}
            cols={55}
            id={item?.id}
            style={style}
            item={item}
            mandatory={item?.mandatory}
            visibility_ruleset={item?.visibility_ruleset}
            preExistingFormElements={preExistingFormElements}
            handleDeleteItem={handleDeleteItem}
            onHandleCheckboxChange={onHandleCheckboxChange}
          />
          <DnDCollapsible
            visibility_ruleset={item?.visibility_ruleset}
            // visibilityRuleset={
            //   preExistingFormElements?.[item.id]?.fields.visibility_ruleset
            // }
            // mandatory={preExistingFormElements?.[item.id]?.fields.mandatory}
            mandatory={item?.mandatory}
            onHandleCheckboxChange={() => onHandleCheckboxChange?.(item.id)}
            onHandleRulesetChange={onHandleRulesetChange}
            id={item.id}
          />
        </>
      )}
    </div>
  );
}
