import React from "react";
import { ItemList } from "./dndItemList";
import { t } from "i18next";
import styles from "./dndColumn.module.css";

interface Props {
  column?: any;
  index?: any;
  preExistingFormElements?: any;
  onHandleCheckboxChange?: (id: string) => void;
  onHandleRulesetChange?: (event: any, id: any) => void;
  onShowConfirmationModal?: (id: string) => void;
  leftColumnPlaceholderText?: string;
  handleDeleteItem?: (id: string) => void;
}

export const Column = React.memo(function Column({
  column,
  index,
  preExistingFormElements,
  onHandleCheckboxChange,
  onHandleRulesetChange,
  onShowConfirmationModal,
  leftColumnPlaceholderText,
  handleDeleteItem,
}: Props) {
  const columnWrapperStyle = {} as React.CSSProperties;

  return (
    <div style={columnWrapperStyle}>
      {column.id === "column-0" && (
        <div className={styles.columnHeader}>
          <h5 className={styles.columnTitle}>{column.title}</h5>
        </div>
      )}
      <ItemList
        column={column}
        index={index}
        handleDeleteItem={handleDeleteItem}
        preExistingFormElements={preExistingFormElements}
        onHandleCheckboxChange={onHandleCheckboxChange}
        onHandleRulesetChange={onHandleRulesetChange}
        leftColumnPlaceholderText={leftColumnPlaceholderText}
      />
    </div>
  );
});
