import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import getMinutes from "date-fns/getMinutes";
import getHours from "date-fns/getHours";
import GeneralListItem from "../general/generaListItem";
import { api } from "../../helper/api";
import { Backdrop, Grid } from "@mui/material";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { t } from "i18next";
import dayjs from "dayjs";
import styles from "./timeSlots.module.css";
import { useConfirmationModal } from "../../context/confirmationModalContext";
import { DotLoader } from "react-spinners";

interface Props {
  selectedDay?: any;
  appointmentInformation?: any;
  handleShowConfirmationAfterPick: () => void;
  handleGetResponseAftetPick: (data: any) => void;
}

export function TimeSlots(props: Props) {
  const [slotData, setSlotData] = useState<any>([]);
  const [error, setError] = useState<any>();
  const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { showConfirmationModalAsync } = useConfirmationModal();
  const { appointmentrequestId } = useParams() as {
    appointmentrequestId: string;
  };

  let convertedDate: any;
  if (props.selectedDay) {
    const year = getYear(props.selectedDay);
    let month: any = getMonth(props.selectedDay) + 1;
    let day: any = getDate(props.selectedDay);

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    convertedDate = year + "-" + month + "-" + day;
  }
  useEffect(() => {
    if (props.selectedDay || reloadTrigger) {
      const additionalUrlParameters: any = {};
      const additionalRouteParts: any = {};
      additionalRouteParts["free_slots"] = "/";
      additionalUrlParameters["day"] = convertedDate;
      api.genericApiRequest({
        entity: "appointmentRequest",
        method: "get",

        successHandler: (res: any) => {
          const data = res.data.results;
          const sortedData = data.sort((a: any, b: any) =>
            a.start_date.localeCompare(b.start_date)
          );
          setSlotData(sortedData);
          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
        },
        entityId: appointmentrequestId,
        additionalRouteParts: additionalRouteParts,
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
      });
      setReloadTrigger(false);
    }
  }, [props.selectedDay, reloadTrigger]);

  const createAppointment = async (
    id: any,
    label: any,
    skipQuestion: false
  ) => {
    const result = {
      slot_id: id,
    };

    const confirmDate = dayjs(props.selectedDay).format("DD.MM.YYYY");

    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("timeslot_confirm_pick", { date: confirmDate, time: label })
      );
      if (!isConfirmed) return;
    }
    setIsLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "appointment",
      method: "get",
      parametersToRender: {
        depth: "1",
        additionalUrlParameters: {
          categoryexamination_set: "true",
          status__in:
            "open,prefilled_by_user,in_progress,review_preparation_required,waiting_for_review,in_review,post_processing_required,completed",
          datetime__gte: dayjs().hour(11).minute(0).second(0).millisecond(0).toISOString(),
        },
      },
      successHandler: (res: any) => {
        const appointments = res.data.results;
        const existingAppointment = appointments?.find(
          (app: any) => app.appointment_request_id === appointmentrequestId
        );
        if (existingAppointment && existingAppointment.datetime) {
          api.genericApiRequest({
            entity: "appointment",
            method: "put",
            entityId: existingAppointment.id,
            additionalRouteParts: { reschedule: "/" },
            parametersToRender: { depth: "1" },
            submitData: result,
            config,
            successHandler: (res: any) => {
              const response = res.data;
              props.handleGetResponseAftetPick(response);
              props.handleShowConfirmationAfterPick();
              setIsLoading(false);
            },
            failHandler: (error: any, status: any) => {
              setError(status === 400 ? t("timeslot_already_reserved") : error);
              setIsLoading(false);
            },
          });
        } else {
          api.genericApiRequest({
            entity: "appointment",
            method: "post",
            additionalRouteParts: { create_from_slot: "/" },
            parametersToRender: { depth: "1" },
            submitData: result,
            config,
            successHandler: (res: any) => {
              const response = res.data;
              props.handleGetResponseAftetPick(response);
              props.handleShowConfirmationAfterPick();
              setIsLoading(false);
            },
            failHandler: (error: any, status: any) => {
              setError(status === 400 ? t("timeslot_already_reserved") : error);
              setIsLoading(false);
            },
          });
        }
      },
      failHandler: (error: any, status: any) => {
        setError(error);
        setIsLoading(false);
      },
    });
  };

  const handleCloseModal = () => {
    setError("");
    setReloadTrigger(true);
  };

  const items = [];
  for (const key in slotData) {
    const slot = slotData[key];
    const slotId = slot.id;
    const slotTime = slot.start_date;

    const convertedSlotTimeToDate = new Date(slotTime);
    const timeSlotHours =
      getHours(convertedSlotTimeToDate) < 10
        ? "0" + getHours(convertedSlotTimeToDate)
        : getHours(convertedSlotTimeToDate);

    const timeSlotMinutes =
      getMinutes(convertedSlotTimeToDate) < 10
        ? "0" + getMinutes(convertedSlotTimeToDate)
        : getMinutes(convertedSlotTimeToDate);

    const timeSlotTimeLabel = timeSlotHours + ":" + timeSlotMinutes;

    items.push(
      <Grid
        xs={3}
        sm={2}
        md={1.5}
        lg={1}
        item
        className={styles.gridContainer}
        key={slotId}
        onClick={() => createAppointment(slotId, timeSlotTimeLabel, false)}
      >
        <GeneralListItem
          allowHover={true}
          hideButton={true}
          wrapperClassName={styles.generalListItemWrapper}
        >
          {timeSlotTimeLabel}
        </GeneralListItem>
      </Grid>
    );
  }

  return (
    <>
      {isLoading ? (
        <Backdrop open={true} className={styles.backdrop}>
          <DotLoader
            color="#8c1ec8"
            size={65}
            cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
          />
        </Backdrop>
      ) : (
        <Grid container columns={6} columnSpacing={"1rem"}>
          {items}
        </Grid>
      )}

      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={handleCloseModal}
        />
      )}
    </>
  );
}
