import React from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./radioButtonsWithRef.module.css";

interface Props {
  onChange?: (target: any) => void;
  name?: string;
  choices?: any;
  required?: boolean;
  mandatory?: boolean;
  label?: string;
  defaultValue?: any;
  readonly?: boolean;
  boolOption?: boolean;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
}

export const RadioButtonsWithRef = React.forwardRef<HTMLInputElement, Props>(
  function RadioButtonsWithRef(props, ref) {
    const theme = useTheme();

    let items: any = [];
    const itemsRendered = [];

    if (props.choices !== undefined) {
      if (Object.keys(props.choices).length > 0) {
        items = props.choices;
      }
    } else if (Object.prototype.hasOwnProperty.call(props, "boolOption")) {
      items.push({ name: "yes", label: "Ja" });
      items.push({ name: "no", label: "Nein" });
      items.push({ name: "noStatement", label: "Keine Angabe" });
    } else {
      items.push({ name: "yes", label: "Ja" });
      items.push({ name: "no", label: "Nein" });
    }
    const normalizeDefaultValue = (defaultValue: any) => {
      if (defaultValue === true) return "yes";
      if (defaultValue === false) return "no";
      return defaultValue;
    };
    const defaultValueMapped = normalizeDefaultValue(props.defaultValue);

    for (const key in items) {
      itemsRendered.push(
        <React.Fragment key={items[key].name}>
          <label
            htmlFor={props.name + items[key].name}
            className={styles.radioLabel}
          >
            <input
              ref={ref}
              type="radio"
              id={props.name + items[key].name}
              value={items[key].name}
              disabled={props.readonly}
              readOnly={props.readonly}
              name={props.name}
              required={props.required}
              onChange={props.onChange}
              defaultChecked={items[key].name === defaultValueMapped}
              // checked={items[key].name === defaultValueMapped}
              className={styles.radioButtonInput}
            />
            <span className={styles.customRadioButton}>{items[key].label}</span>
          </label>
        </React.Fragment>
      );
    }

    return (
      <div>
        <label className={styles.radioGroupLabel}>
          {props.mandatory === true || props.required === true ? (
            <>
              {props.label}
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            <>{props.label}</>
          )}

          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <div
          className={
            props.boolOption
              ? styles.radioButtonWrapperThirdOption
              : styles.radioButtonWrapper
          }
          style={{
            gridTemplateColumns: useMediaQuery(theme.breakpoints.up("sm"))
              ? "1fr 1fr 1fr"
              : "1fr",
          }}
        >
          {itemsRendered}
        </div>
      </div>
    );
  }
);
