import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IDefaultInputProps } from "../../types/DefaultInputProps";
import { TextAreaWithRef } from "./inputsWithRef/textAreaWithRef";
import { RadioButtonsWithRef } from "./inputsWithRef/radioButtonsWithRef";
import { DateFieldWithRef } from "./inputsWithRef/dateFieldWithRef";
import { InputWithRef } from "./inputsWithRef/inputWithRef";
import { DropdownWithRef } from "./inputsWithRef/dropdownWithRef";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import CustomErrorMessage from "./inputsWithRef/customErrorMessage";
import FindingFormVisibilityWrapper from "./findingFormVisibilityWrapper";
import { Grid, Paper } from "@mui/material";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import Modal from "../modal";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
import GenericNotification from "../notification/genericNotification";
import styles from "./defaultForm.module.css";
import GenericButton from "./inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface Props {
  onSubmit?: (data: any) => void;
  onEdit?: (data: any) => void;
  visibilityHandler?: (data: any) => void;
  input: IDefaultInputProps[] | any;
  entity: any;
  entityId?: any;
  visibility?: any;
  onSuccessHandler?: () => void;
  //-------------------------------
  dataCallback?: any;
  preDefinedData?: any;
  preSubmitHandler?: any;
  onCancelFormHandler?: () => void;
  preSubmitConfirmMessage?: string;
  submitButtonText?: any;
  cancelButtonText?: any;
  cancelButton: boolean;
  findingId?: any;
  additionalUrlParameters?: any;
  targetEntity?: any; //for PUT & POST request - might differ from props.entity for GET
  targetEntityId?: any; //for PUT & POST request - might differ from props.entityId for GET
  withPaper?: boolean;
  isEditAllowed?: any;
  onCloseModal?: () => void;
}

const DefaultForm: FC<Props> = (props: Props) => {
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    formState: { errors, isDirty },
  } = useForm<any>();
  const { t } = useTranslation();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  // const handleCloseErrorModal = () => {
  //   setErrorOccured(false);
  //   setErrorModalOpen(false);
  // };

  // useEffect(() => {
  //   if (errorOccured) {
  //     setErrorModalOpen(true);
  //   } else setErrorModalOpen(false);
  // }, [errorOccured]);

  // useEffect(() => {
  //   if (Object.keys(errors).length > 0) {
  //     setErrorOccured(true);
  //   }
  // }, [errors]);

  // const onClickhandler = () => {
  //   if (Object.keys(errors).length > 0) {
  //     setErrorOccured(true);
  //   }
  // };
  useEffect(() => {
    setIsLoading(true);
    if (props.preDefinedData) {
      setData(props.preDefinedData);
      setIsLoading(false);
    } else {
      api.genericApiRequest({
        method: "get",
        entity: props.entity,
        entityId: props.entityId,
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: props.additionalUrlParameters,
        },
        successHandler: (res: any) => {
          if (props.dataCallback) {
            if (res.data.results) {
              props.dataCallback(res.data.results);
            } else {
              props.dataCallback(res.data);
            }
          }
          if (res.data.results) {
            setData(res.data.results);
            setIsLoading(false);
          } else {
            setData(res.data);
            setIsLoading(false);
          }
        },
        failHandler: (error: any) => {
          setError(error);
        },
      });
    }
  }, [props.entity]);

  const input: any = [];

  for (const key in props.input) {
    const inputProps = props.input.sort((a: any, b: any) => a.order - b.order)[
      key
    ];

    const type = inputProps.type;
    const name = inputProps.name;
    const preDefinedValues = inputProps.preDefinedValues;

    //setting values if  and predefinedValues are present
    useEffect(() => {
      if (data) {
        setValue(name, data[name]);
        if (props.entity === "measurementDefinition") {
          if (data.reuse !== undefined) {
            if (data.reuse) {
              setValue("reuse", "yes");
            } else setValue("reuse", "no");
          }
        }
      }
      if (preDefinedValues) {
        setValue(name, preDefinedValues);
      }

      if (props.isEditAllowed) {
        props.isEditAllowed(data);
      }
      if (data.unit && (data.unit === 0 || data.unit === "0")) {
        setValue("unit", "");
      }
    }, [data]);
    let element = <></>;

    switch (type) {
      case "text":
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={inputProps.name}
            visibility={props.visibility}
            fieldname={name}
          >
            <TextAreaWithRef
              {...register(inputProps.name, {
                pattern: {
                  value: inputProps.pattern,
                  message: inputProps.errorMessageValidation,
                },
                onChange: inputProps.onChange,
                onBlur: inputProps.onBlur,
                required: {
                  value: inputProps.required,
                  message: inputProps.errorMessageRequired,
                },
              })}
              label={inputProps.label}
              cols={inputProps.cols}
              rows={inputProps.rows}
              defaultValue={inputProps.defaultValue}
              mandatory={inputProps.mandatory}
              name={inputProps.name}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              required={inputProps.required}
              readonly={inputProps.readOnly}
            />
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
              render={({ message }) => (
                <CustomErrorMessage
                  error={inputProps.error}
                  errorMessage={message}
                />
              )}
            />
          </Wrapper>
        );
        break;
      case "choice":
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={name}
            visibility={props.visibility}
            fieldname={name}
          >
            <DropdownWithRef
              key={inputProps.name + inputProps.label}
              name={inputProps.name}
              options={inputProps.options}
              control={control}
              label={inputProps.label}
              disabled={inputProps.disabled}
              isSearchable={inputProps.isSearchable}
              isClearable={inputProps.isClearable}
              required={inputProps.required}
              onBlur={inputProps.onBlur}
              onChange={inputProps.onDropdownChange}
              id={inputProps.name}
              error={inputProps.error}
              errorMessage={inputProps.errorMessageRequired}
              isMulti={false}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              readOnly={inputProps.readOnly}
            />
          </Wrapper>
        );
        break;
      case "multiselect":
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={name}
            visibility={props.visibility}
            fieldname={name}
          >
            <DropdownWithRef
              key={inputProps.name}
              name={inputProps.name}
              options={inputProps.options}
              control={control}
              label={inputProps.label}
              isMulti={true}
              disabled={inputProps.disabled}
              isSearchable={inputProps.isSearchable}
              isClearable={inputProps.isClearable}
              required={inputProps.required}
              onBlur={inputProps.onBlur}
              onChange={inputProps.onDropdownChange}
              id={inputProps.name}
              error={inputProps.error}
              errorMessage={inputProps.errorMessageRequired}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              readOnly={inputProps.readOnly}
            />
          </Wrapper>
        );
        break;
      case "bool":
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={inputProps.name}
            visibility={props.visibility}
            fieldname={name}
          >
            <RadioButtonsWithRef
              {...register(inputProps.name, {
                pattern: {
                  value: inputProps.pattern,
                  message: inputProps.errorMessageValidation,
                },
                onChange: inputProps.onChange,
                onBlur: inputProps.onBlur,
                required: {
                  value: inputProps.required,
                  message: inputProps.errorMessageRequired,
                },
              })}
              name={inputProps.name}
              readonly={inputProps.readOnly}
              choices={inputProps.choices}
              defaultValue={inputProps.defaultValue}
              label={inputProps.label}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              required={inputProps.required}
            />
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
              render={({ message }) => (
                <CustomErrorMessage
                  error={inputProps.error}
                  errorMessage={message}
                />
              )}
            />
          </Wrapper>
        );
        break;
      case "boolNoStatement":
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={inputProps.name + Math.random()}
            visibility={props.visibility}
            fieldname={name}
          >
            <RadioButtonsWithRef
              {...register(inputProps.name, {
                pattern: {
                  value: inputProps.pattern,
                  message: inputProps.errorMessageValidation,
                },
                onChange: inputProps.onChange,
                onBlur: inputProps.onBlur,
                required: {
                  value: inputProps.required,
                  message: inputProps.errorMessageRequired,
                },
              })}
              name={inputProps.name}
              label={inputProps.label}
              readonly={inputProps.readOnly}
              boolOption
              choices={inputProps.choices}
              defaultValue={inputProps.defaultValue}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              required={inputProps.required}
            />
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
              render={({ message }) => (
                <CustomErrorMessage
                  error={inputProps.error}
                  errorMessage={message}
                />
              )}
            />
          </Wrapper>
        );
        break;
      case "date":
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={inputProps.name}
            visibility={props.visibility}
            fieldname={name}
          >
            <DateFieldWithRef
              {...register(inputProps.name, {
                pattern: {
                  value: /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/,
                  message: inputProps.errorMessageValidation,
                },
                onChange: inputProps.onChange,
                onBlur: inputProps.onBlur,
                required: {
                  value: inputProps.required,
                  message: inputProps.errorMessageRequired,
                },
              })}
              label={inputProps.label}
              name={inputProps.name}
              readonly={inputProps.readOnly}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              required={inputProps.required}
            />
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
              render={({ message }) => (
                <CustomErrorMessage
                  error={inputProps.error}
                  errorMessage={message}
                />
              )}
            />
          </Wrapper>
        );
        break;
      // handling of customComponent not through useForm
      case "custom":
        element = (
          <React.Fragment key={inputProps.name}>
            {inputProps.visible && (
              <Wrapper className={styles.defaultWrapper} key={inputProps.name}>
                {inputProps.customElement}
              </Wrapper>
            )}
          </React.Fragment>
        );
        break;

      default:
        element = (
          <Wrapper
            className={styles.defaultWrapper}
            key={name + type}
            visibility={props.visibility}
            fieldname={name}
          >
            <InputWithRef
              {...register(inputProps.name, {
                pattern: {
                  value: inputProps.pattern,
                  message: inputProps.errorMessageValidation,
                },
                onChange: inputProps.onChange,
                onBlur: inputProps.onBlur,
                required: {
                  value: inputProps.required,
                  message: inputProps.errorMessageRequired,
                },
              })}
              label={inputProps.label}
              onFocus={inputProps.onFocus}
              // value={inputProps.defaultValue}
              name={inputProps.name}
              autoComplete={inputProps.autoComplete}
              placeholder={inputProps.placeholder}
              tooltip={inputProps.tooltip}
              tooltipMessage={inputProps.tooltipMessage}
              placement={inputProps.placement}
              required={inputProps.required}
              type={inputProps.type}
              readonly={inputProps.readOnly}
              disabled={inputProps.disabled}
            />
            <ErrorMessage
              errors={errors}
              name={inputProps.name}
              render={({ message }) => (
                <CustomErrorMessage
                  error={inputProps.error}
                  errorMessage={message}
                />
              )}
            />
          </Wrapper>
        );
    }

    input.push(element);
  }

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let result = data;

    if (props.preSubmitConfirmMessage) {
      const isConfirmed = await showConfirmationModalAsync(
        props.preSubmitConfirmMessage
      );
      if (!isConfirmed) {
        setIsLoading(false);
        return;
      }
    }

    if (props.preSubmitHandler) {
      result = props.preSubmitHandler(data);
    }

    if (props.entity === "finding") {
      result = { form: props.entityId, values: data, finding: props.findingId };
    }

    if (props.onCloseModal) props.onCloseModal();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (props.entityId) {
      try {
        // eslint-disable-next-line
        const response = await new Promise<any>((resolve, reject) => {
          api.genericApiRequest({
            method: "put",
            entityId: props.targetEntityId,
            entity: props.targetEntity,
            successHandler: (res: any) => {
              resolve(res);
              setIsLoading(false);
              setNotificationMessage(t("save_successful"));
              setNotificationVariant("success");
              setShowNotification(true);

              props.onSuccessHandler?.();
            },
            failHandler: (error: any, status: any, code: any) => {
              if (error === undefined) {
                if (code === "ERR_NETWORK") {
                  reject(t("submit_failed"));
                } else reject(t("generic_error_message", { error: status }));
              } else reject(error);
              setIsLoading(false);
            },
            submitData: result,
            config: config,
          });
        });
      } catch (error) {
        setError(error);
      }
    } else {
      try {
        // eslint-disable-next-line
        const response = await new Promise<any>((resolve, reject) => {
          api.genericApiRequest({
            method: "post",
            entity: props.targetEntity,
            successHandler: (res: any) => {
              resolve(res);
              setIsLoading(false);
              setNotificationMessage(t("create_successful"));
              setNotificationVariant("success");
              setShowNotification(true);
              props.onSuccessHandler?.();
            },
            failHandler: (error: any, status: any, code: any) => {
              if (error === undefined) {
                if (code === "ERR_NETWORK") {
                  reject(t("submit_failed"));
                } else reject(t("generic_error_message", { error: status }));
              } else reject(error);
              setIsLoading(false);
              setError(error);
            },
            submitData: result,
            config: config,
          });
        });
      } catch (error) {
        setError(error);
      }
    }
  };
  let submitButtonText = t("save");
  let cancelButtonText = t("cancel");

  if (props.submitButtonText) {
    submitButtonText = props.submitButtonText;
  }

  if (props.cancelButtonText) {
    cancelButtonText = props.cancelButtonText;
  }

  const disableSubmitButton = () => {
    if (props.entity === "coreData") {
      if (!isDirty) {
        return true;
      }
    }
    return false;
  };

  const handleManualFormSubmit = async (values: any) => {
    try {
      await onSubmit(values);
    } catch {
      //errorhandling is done in the onSubmit. Error not thrown again
    }
  };
  let tu;
  return (
    <>
      {isLoading && tu === "2" ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : props.withPaper ? (
        <Paper
          sx={{
            padding: "0.75rem",
            borderRadius: "0.75rem",
            maxWidth: "1000px",
            margin: "0 auto",
            overflow: "scroll",
            width: "100%",
          }}
        >
          <form noValidate>
            <div className={styles.inputWrapper}>{input}</div>
            <div className={styles.buttonWrapper}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  {props.cancelButton && (
                    <GenericButton
                      variant="outlined"
                      onClick={props.onCancelFormHandler}
                    >
                      {cancelButtonText}
                    </GenericButton>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <GenericButton
                    // type="submit"
                    onClick={handleSubmit(handleManualFormSubmit)}
                    className={styles.submitButton}
                    // onClick={onClickhandler}
                    disabled={disableSubmitButton()}
                    waitingForResponse={true}
                    showSpinnerOnDisable={!disableSubmitButton()}
                  >
                    {submitButtonText}
                  </GenericButton>
                </Grid>
              </Grid>
            </div>
          </form>
        </Paper>
      ) : (
        <>
          <form noValidate>
            <div className={styles.inputWrapper}>{input}</div>
            <div className={styles.buttonWrapper}>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  {props.cancelButton && (
                    <>
                      <GenericButton
                        className={styles.cancelButton}
                        onClick={props.onCancelFormHandler}
                        variant="outlined"
                      >
                        {cancelButtonText}
                      </GenericButton>
                    </>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <>
                    <GenericButton
                      disabled={disableSubmitButton()}
                      className={styles.submitButton}
                      // type="submit"
                      onClick={handleSubmit(handleManualFormSubmit)}
                      waitingForResponse={true}
                      showSpinnerOnDisable={!disableSubmitButton()}
                    >
                      {submitButtonText}
                    </GenericButton>
                  </>
                </Grid>
              </Grid>
            </div>
          </form>
        </>
      )}
      <Modal
        open={errorModalOpen}
        // onClose={handleCloseErrorModal}
        title={t("error_occurred")}
        hideSubmitButton={true}
        cancelButtonText={t("confirm")}
      >
        <CustomErrorMessage
          error={"error"}
          errorMessage={t("complete_required_fields")}
          errorFontSize={"1rem"}
        />
      </Modal>
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

const Wrapper = (props: any) => {
  if (!props.visibility) {
    return <div className={`test1 ${props.className}`}>{props.children}</div>;
  } else {
    return (
      <FindingFormVisibilityWrapper
        visibility={props.visibility}
        fieldname={props.fieldname}
      >
        {props.children}
      </FindingFormVisibilityWrapper>
    );
  }
};

export default DefaultForm;
