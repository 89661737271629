import { HTMLInputTypeAttribute, ReactNode, useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CustomErrorMessage from "../forms/inputsWithRef/customErrorMessage";
import { DEFAULTPICKERDATE } from "../../types/Entities";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DropDownSubjectSearch } from "../forms/inputs/dropDownSubjectSearch";
import { IDropDownOption } from "../../types/DropDown";
import { t } from "i18next";
import { api } from "../../helper/api";
import { DropDownCreatable } from "../forms/inputs/dropDownCreateable";
import GenericCrudSelect from "./genericCrudTableModalWrapperElements/genericCrudSelect";
import GenericErrorMessageModal from "../forms/errorHandling/genericErrorMessageModal";
import { useDispatch } from "react-redux";
import { useSignOut } from "react-auth-kit";
import styles from "./genericCrudTableModalWrapperField.module.css";
import { MuiTelInput } from "mui-tel-input";
import { emailValidationRegex } from "../../helper/regex";
import { eurocentToEuro, euroToEurocent } from "../../helper/formattingValues";
import { useConditionalWatch } from "../../helper/customHooks/useConditionalWatch";

interface Props {
  entity?: string;
  entityOfTable?: string;
  companySubjectId?: string;
  multiselect?: boolean;
  checkBox?: boolean;
  textarea?: boolean;
  textareaRows?: number;
  onChange?: (value: any) => void;
  id?: string;
  name?: string;
  label: string;
  value?: any;
  selectOptions?: any;
  isJson?: boolean;
  hideField?: boolean;
  datePicker?: boolean;
  dateTimePicker?: boolean;
  validate?: boolean;
  rule?: any;
  errorMessage?: any;
  helperText?: string;
  autoComplete?: string;
  companyId?: any;
  defaultPickerDate?: any;
  required: boolean;
  errorMessageValidation?: string;
  modalMode?: string;
  asyncEntity?: string;
  asyncDropDown?: boolean;
  userEmails?: string[];
  queryParameter?: "users" | "companies"; //if set asyncDropDown returns just the specified
  customLabel?: string; // customLabel for asyncDropDrown
  customLabelForGenericSelect?: string;
  setCompanyId?: boolean; // used in companyList/Parent for using the actual companyId instead of SubjectId
  filterByCategory?: boolean;
  disableField?: boolean;
  createAble?: boolean;
  maxLengthMessage?: string;
  maxLength?: any;
  additionalUrlParameters?: any;
  subjectIdForBuildings?: any;
  additionalComponentForModal?: ReactNode;
  getOnlyActiveUsers?: any;
  type?: HTMLInputTypeAttribute;
  showAddressForBuildingSelect?: boolean;
  companyIdForFilteringAsync?: any;
  limit?: string;
  autoFocus?: boolean;
  allowSubjectSearchForNonAdmin?: boolean;
  additionalRoutePartsforGet?: any;
}

interface Options extends IDropDownOption {
  treeId?: string;
}

export default function GenericCrudTableModalWrapperField(props: Props) {
  const [error, setError] = useState<any>(null);

  const defaultDate =
    props.defaultPickerDate === DEFAULTPICKERDATE.TODAY ? dayjs() : null;

  const [entityItems, setEntityItems] = useState<any>([]);
  const [values, setValues] = useState<any>("");
  const [multiselect, setMultiselect] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<any>(defaultDate);
  const [dateTimeValue, setDateTimeValue] = useState<any>(dayjs());
  /*eslint-disable */
  const [asyncOptions, setAsyncOptions] = useState<Options[]>([]);
  /*eslint-enable */
  const [createableOptions, setCreateableOptions] = useState<any>([]);
  const [getOptionsFinished, setGetOptionsFinished] = useState<boolean>(false);

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const {
    setValue,
    getValues,
    control,
    trigger,
    // formState: { errors },
  } = useFormContext();
  const additionalUrlParameters: any = {};

  const dispatch = useDispatch();
  const signOut = useSignOut();

  // const isAnalog = useWatch({ name: "is_analog" });

  const isScheduled = useConditionalWatch(control, {
    entity: props.entityOfTable,
    field: "scheduled",
    condition: (e) => e === "rule",
  });

  const isAnalog = useConditionalWatch(control, {
    entity: props.entityOfTable,
    field: "is_analog",
    condition: (e) => e === "user",
  });

  const mandatoryRulesClassname =
    isScheduled === "false" || isScheduled == null || isScheduled === false
      ? true
      : false;

  const disabledEmailForAnalogUser =
    isAnalog === true || isAnalog === "true" ? true : false;

  if (props.entity === "department" && subdomain === "admin") {
    additionalUrlParameters["impersonate_subject"] = props.companySubjectId;
  }
  if (props.additionalUrlParameters) {
    for (const key in props.additionalUrlParameters) {
      additionalUrlParameters[key] = props.additionalUrlParameters[key];
    }
  }

  if (props.entity === "building" && subdomain === "admin") {
    additionalUrlParameters["subject"] = props.subjectIdForBuildings
      ? props.subjectIdForBuildings
      : props.companySubjectId;
  }
  if (props.entity === "user" && props.getOnlyActiveUsers) {
    additionalUrlParameters["impersonate_subject"] =
      props.getOnlyActiveUsers.companySubjectId;
    additionalUrlParameters["is_active"] = props.getOnlyActiveUsers.status;
  }

  const combinedSignout = () => {
    dispatch({ type: "clear" });
    signOut();
  };

  const tempParametersToRender: any = {};
  tempParametersToRender["additionalUrlParameters"] = additionalUrlParameters;
  tempParametersToRender["depth"] = "0";
  if (props.limit !== undefined) {
    tempParametersToRender["limit"] = props.limit;
  }

  let additionalRouteParts: any = {};
  if (props.additionalRoutePartsforGet) {
    additionalRouteParts = props.additionalRoutePartsforGet;
  }

  useEffect(() => {
    if ((props.entity || props.asyncDropDown) && entityItems.length === 0) {
      if (props.asyncDropDown) {
        if (props.value) {
          if (typeof props.value === "object") {
            const id = props.value?.id;
            const name = props.value?.name;
            const preSelected = [];
            preSelected.push({ value: id, label: name });
            setValues(preSelected);
            setEntityItems([{ id: id, name: name }]);
          }
        }
      } else {
        api.genericApiRequest({
          method: "get",
          entity: props.entity,
          parametersToRender: tempParametersToRender,
          additionalRouteParts: additionalRouteParts,
          successHandler: (res: any) => {
            // return if company has no departments - prevent ongoing calls
            if (res.data.results.length === 0) {
              setGetOptionsFinished(true);
              return;
            } else {
              setEntityItems(res.data.results);
              setGetOptionsFinished(true);
            }
            if (props.multiselect) {
              //if the the field is a multiselect, get the IDs of the selected items and add them to an array
              const selectedIds = [];
              for (const key in props.value) {
                selectedIds.push(props.value[key].id);
              }
              setMultiselect(true);
              setValues(selectedIds);
              setValue(props.name as string, selectedIds);
              props.onChange?.(selectedIds);
              setGetOptionsFinished(true);
            } else {
              //if the field is a single select, we only need the id of the item, as a single value
              if (props.value) {
                //if the field is an object, take it's id
                if (props.value.id) {
                  setValues(props.value.id);
                  setValue(props.name as string, props.value.id);
                  setGetOptionsFinished(true);
                } else {
                  //if the field is not an object, then it's just an id string
                  setValues(props.value);
                  setValue(props.name as string, props.value);
                  setGetOptionsFinished(true);
                }
              } else {
                setValues("");
                setValue(props.name as string, "");
                setGetOptionsFinished(true);
              }
            }
          },
          failHandler: (error: any) => {
            if (error === "logout") {
              combinedSignout();
              setError(error);
            } else console.log(error);
          },
        });
      }
    } else if (props.selectOptions) {
      setValue(props.name as string, props.value);
    }
  }, [
    props.entity,
    props.value,
    entityItems,
    props.multiselect,
    props.asyncDropDown,
    props.selectOptions,
  ]);
  useEffect(() => {
    if (props.value) {
      setValue(props.name as string, props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.asyncEntity) {
      setValue(props.name as string, []);
    }
  }, [props.asyncEntity]);

  useEffect(() => {
    if (props.createAble) {
      setCreateableOptions(props.selectOptions);
      setValue(props.name as string, []);
    }
  }, [props.createAble]);

  useEffect(() => {
    if (props.dateTimePicker && !props.value) {
      const value = dateTimeValue.$d.toISOString();
      props.onChange?.(value);
    }

    if (props.datePicker && props.value) {
      let date = props.value;
      if (date instanceof Date) {
        date = dayjs(date);
        setDateValue(date);
      } else {
        date = new Date(props.value);
        date = dayjs(date);
        setDateValue(date);
      }
    }
    if (props.dateTimePicker && props.value) {
      let date = props.value;
      if (date instanceof Date) {
        date = dayjs(date);
        setDateTimeValue(date);
      } else {
        date = new Date(props.value);
        date = dayjs(date);
        setDateTimeValue(date);
      }
    }
  }, [props.datePicker, props.dateTimePicker, props.value]);
  const handleChange = (
    event: any,
    onChange: any,
    multiselect?: boolean,
    valueArray?: any
  ) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;

    if (target.name === "price") {
      let euroValue = parseFloat(target.value);
      if (!isNaN(euroValue)) {
        euroValue = euroToEurocent(euroValue);
      }
      value = euroValue;
    }

    setValues(typeof value === "string" ? value.split(",") : value);

    if (multiselect) {
      props.onChange?.(valueArray);
      onChange(valueArray);
    } else {
      props.onChange?.(value);
      onChange(value);
    }
  };

  const handleDateChange = (
    newDate: any,
    onChange: any,
    isDateTime: boolean
  ) => {
    if (!newDate || !newDate.isValid()) {
      onChange(dateTimeValue || dateValue);
      return;
    }

    const updatedDate = isDateTime
      ? dayjs(dateTimeValue || dayjs())
          .set("year", newDate.year())
          .set("month", newDate.month())
          .set("date", newDate.date())
          .set("hour", newDate.hour())
          .set("minute", newDate.minute())
      : dayjs(dateValue || dayjs())
          .set("year", newDate.year())
          .set("month", newDate.month())
          .set("date", newDate.date());

    const formattedValue = isDateTime
      ? updatedDate.format("YYYY-MM-DDTHH:mm:ss")
      : updatedDate.format("YYYY-MM-DD");

    setDateTimeValue(updatedDate);
    onChange(formattedValue);
    props.onChange?.(formattedValue);
  };

  const handleAsyncChange = (selectedOption: any, onChange: any) => {
    const value = [];
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      if (props.setCompanyId) {
        value.push({
          value:
            selectedOption.companyId !== undefined
              ? selectedOption.companyId
              : selectedOption.value,
          label: selectedOption.label,
        });
        props.onChange?.(
          selectedOption.companyId !== undefined
            ? selectedOption.companyId
            : selectedOption.value
        );
      } else {
        value.push({
          value: selectedOption.value,
          label: selectedOption.label,
        });
        props.onChange?.(selectedOption.value);
      }
    }
    onChange(value);
  };
  const handleUpdateOptions = (newOption: any) => {
    setCreateableOptions([...createableOptions, newOption]);
  };
  const handleCreateableChange = (selectedOption: any, onChange: any) => {
    const value = [];
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      value.push(selectedOption.value);
      props.onChange?.(selectedOption.value);
    }
    onChange(value);
  };

  if (
    multiselect ||
    props.entity ||
    (props.selectOptions && !props.createAble)
  ) {
    let newArray = entityItems;
    let newValues = values;
    // following filteredValue of filterByCategory is used in AppointmentEditor
    if (props.entity && props.entity === "examination") {
      if (props.filterByCategory) {
        const filtered = entityItems.filter((item: any) =>
          item.category_set.some((x: any) => x.id === getValues("category"))
        );
        newArray = filtered;
      }
    }
    if (props.selectOptions) {
      newArray = props.selectOptions;
      newValues = "";
      if (props.value !== null && props.value !== undefined) {
        if (props.value === false) {
          newValues = "false";
        } else {
          newValues = props.value;
        }
      }
    }

    return (
      <>
        <GenericCrudSelect
          autoFocus={props.autoFocus}
          name={props.name}
          control={control}
          required={props.required}
          errorMessage={props.errorMessage}
          entity={props.entity}
          entityItems={newArray}
          id={props.id}
          label={props.label}
          customLabel={props.customLabelForGenericSelect}
          onHandleChange={handleChange}
          values={newValues}
          modalMode={props.modalMode}
          multiSelect={props.multiselect}
          getValues={getValues}
          getOptionsFinished={getOptionsFinished}
          showAddressForBuildingSelect={props.showAddressForBuildingSelect}
        />
      </>
    );
  } else if (props.createAble && props.selectOptions) {
    let value = "";
    if (props.value !== null && props.value !== undefined) {
      if (props.value === false) {
        value = "false";
      } else {
        value = props.value;
      }
    }

    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ref, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div className={styles.selectWrapper}>
              <FormControlLabel
                label={props.label}
                labelPlacement="top"
                sx={{
                  margin: "0 ",
                  width: "100%",
                  alignItems: "start",
                  fontFamily: "inherit !important",
                }}
                disableTypography
                control={
                  <DropDownCreatable
                    {...rest}
                    ref={ref}
                    options={createableOptions}
                    name={props.name}
                    defaultValue={value}
                    isClearable={true}
                    disabled={props.disableField}
                    onChange={(selectedOption: any) => {
                      handleCreateableChange(selectedOption, onChange);
                    }}
                    error={error}
                    updateOptions={handleUpdateOptions}
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else if (props.asyncDropDown) {
    let disableAsyncDropDown = false;
    const toDoForStaff = useWatch({ name: "staff" });
    if (toDoForStaff) {
      disableAsyncDropDown = true;
    }
    if (values.length !== 0) {
      if (
        values[0]?.value !== undefined &&
        values[0]?.value === "11111111-1111-1111-1111-111111111113"
      ) {
        disableAsyncDropDown = true;
      }
    }
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ref, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div className={styles.selectWrapper}>
              <DropDownSubjectSearch
                {...rest}
                customRef={ref}
                allowSubjectSearchForNonAdmin={
                  props.allowSubjectSearchForNonAdmin
                }
                wrapperClassName={styles.dropDownSubjectSearch}
                name={
                  props.required ? `${props.label + "\u{002A}"}` : props.label
                }
                error={error}
                options={asyncOptions as []}
                preExistingValue={values}
                onChange={(selectedOption: any) =>
                  handleAsyncChange(selectedOption, onChange)
                }
                subdomain={subdomain}
                queryParameter={props.queryParameter}
                customLabel={props.customLabel}
                companyIdforParent={getValues("id")}
                isClearable={true}
                useGlobalState={false}
                companyIdForFiltering={props.companyIdForFilteringAsync}
                disabled={disableAsyncDropDown}
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else if (props.isJson) {
    let textAreaValue = props.value;

    if (typeof props.value === "object") {
      textAreaValue = JSON.stringify(props.value);
    }
    return (
      <>
        {!props.hideField && (
          <Controller
            name={props.name as string}
            control={control}
            rules={{
              required: {
                value: props.required,
                message: props.errorMessage,
              },
              pattern: {
                value: props.rule,
                message: props.errorMessageValidation
                  ? props.errorMessageValidation
                  : t("error_msg_invalid_characters_change_your_input"),
              },
            }}
            render={({
              field: { onChange, ...rest },
              fieldState: { error },
              formState: { errors },
            }) =>
              ((props.modalMode === "edit" && textAreaValue) ||
                props.modalMode === "insert") && (
                <div>
                  <FormControlLabel
                    labelPlacement="top"
                    sx={{
                      margin: "0 ",
                      width: "100%",
                      alignItems: "start",
                      fontFamily: "inherit !important",
                    }}
                    disableTypography
                    label={
                      props.required ? (
                        <section style={{ margin: "0 0 0.25rem" }}>
                          {props.label}
                          <span className={styles.labelRequired}>
                            {"\u{002A}"}
                          </span>
                        </section>
                      ) : (
                        <section style={{ margin: "0 0 0.25rem" }}>
                          {props.label}
                        </section>
                      )
                    }
                    control={
                      <TextField
                        {...rest}
                        error={error ? true : false}
                        name={props.name}
                        onChange={(event: any) => {
                          handleChange(event, onChange);
                        }}
                        value={textAreaValue}
                        className={`
                        ${styles.textField}
                        ${styles.preWhiteSpace}
                        ${props.hideField ? styles.noDisplay : ""}
                      `}
                        multiline
                        inputProps={{ className: styles.resizeVertical }}
                        rows={props.textareaRows ? props.textareaRows : 8}
                      />
                    }
                  />
                  <ErrorMessage
                    errors={errors}
                    name={props.name as string}
                    render={({ message }) => (
                      <CustomErrorMessage
                        error={"error"}
                        errorMessage={message}
                      />
                    )}
                  />
                </div>
              )
            }
          />
        )}
      </>
    );
  } else if (props.checkBox) {
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          defaultValue={false}
          render={({
            field: { onChange, value, ...rest },
            fieldState: { error },
          }) => (
            <div>
              <FormControlLabel
                label={props.label}
                control={
                  <Checkbox
                    {...rest}
                    checked={value || false}
                    onChange={(event) => handleChange(event, onChange)}
                  />
                }
              />
              {error && <p>{error.message}</p>}
            </div>
          )}
        />
      </>
    );
  } else if (props.datePicker) {
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            validate: {
              value: (value: any) => {
                if (
                  props.validate &&
                  dayjs(value).format("DD.MM.YYYY") ===
                    dayjs().format("DD.MM.YYYY")
                ) {
                  return props.errorMessageValidation;
                }
                if (
                  props.validate &&
                  dayjs(value).format("DD.MM.YYYY") ===
                    dayjs().format("01.01.1900")
                ) {
                  return t("field_required");
                }
              },
            },
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ...rest },
            fieldState: { error },
            formState: { errors },
          }) => (
            <div>
              <FormControlLabel
                labelPlacement="top"
                sx={{
                  margin: "0 ",
                  width: "100%",
                  alignItems: "start",
                  fontFamily: "inherit !important",
                }}
                disableTypography
                label={
                  props.required ? (
                    <section style={{ margin: "0 0 0.25rem" }}>
                      {props.label}
                      <span className={styles.labelRequired}>{"\u{002A}"}</span>
                    </section>
                  ) : (
                    <section style={{ margin: "0 0 0.25rem" }}>
                      {props.label}
                    </section>
                  )
                }
                control={
                  <DatePicker
                    {...rest}
                    value={dateValue}
                    onChange={(newValue) =>
                      handleDateChange(newValue, onChange, false)
                    }
                    slotProps={{
                      textField: {
                        name: props.name,
                        error: !!error,
                      },
                      field: {
                        clearable: true,
                        onClear: () => {
                          setDateValue(null);
                          setValue(props.name as string, "");
                          trigger(props.name as string);
                        },
                      },
                    }}
                    className={styles.datePicker}
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else if (props.dateTimePicker) {
    return (
      <>
        <Controller
          name={props.name as string}
          control={control}
          rules={{
            required: {
              value: props.required,
              message: props.errorMessage,
            },
          }}
          render={({
            field: { onChange, ...rest },
            // fieldState: { error },
            formState: { errors },
          }) => (
            <div>
              <FormControlLabel
                labelPlacement="top"
                sx={{
                  margin: "0 ",
                  width: "100%",
                  alignItems: "start",
                  fontFamily: "inherit !important",
                }}
                disableTypography
                label={
                  props.required ? (
                    <section style={{ margin: "0 0 0.25rem" }}>
                      {props.label}
                      <span className={styles.labelRequired}>{"\u{002A}"}</span>
                    </section>
                  ) : (
                    <section style={{ margin: "0 0 0.25rem" }}>
                      {props.label}
                    </section>
                  )
                }
                control={
                  <DateTimePicker
                    {...rest}
                    value={dateTimeValue}
                    onChange={(newValue) => {
                      handleDateChange(newValue, onChange, true);
                    }}
                    ampm={false}
                    slotProps={{
                      textField: {
                        name: props.name,
                        error: !!errors[props.name as string],
                      },
                      field: {
                        clearable: true,
                        onClear: () => {
                          setDateTimeValue(null);
                          setValue(props.name as string, "");
                          trigger(props.name as string);
                        },
                      },
                    }}
                    className={styles.dateTimePicker}
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name={props.name as string}
                render={({ message }) => (
                  <CustomErrorMessage error={"error"} errorMessage={message} />
                )}
              />
            </div>
          )}
        />
      </>
    );
  } else {
    let fieldValue = props.value;
    if (!fieldValue) {
      fieldValue = "";
    }

    if (props.name && props.name === "price") {
      if (fieldValue || fieldValue === "") {
        fieldValue = eurocentToEuro(fieldValue);
      }
    }

    if (props.textarea && props.textarea === true) {
      return (
        <>
          {!props.hideField && (
            <>
              <Controller
                name={props.name as string}
                control={control}
                rules={{
                  pattern: {
                    value: props.rule,
                    message: props.errorMessageValidation
                      ? props.errorMessageValidation
                      : t("error_msg_invalid_characters_change_your_input"),
                  },
                  required: {
                    value: props.required,
                    message: props.errorMessage,
                  },
                  maxLength: {
                    value: props.maxLength,
                    message: props.maxLengthMessage
                      ? props.maxLengthMessage
                      : "",
                  },
                }}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { error },
                  formState: { errors },
                }) => (
                  <>
                    <FormControlLabel
                      labelPlacement="top"
                      sx={{
                        margin: "0 ",
                        width: "100%",
                        alignItems: "start",
                        fontFamily: "inherit !important",
                      }}
                      disableTypography
                      label={
                        props.required ? (
                          <section style={{ margin: "0 0 0.25rem" }}>
                            {props.label}
                            <span className={styles.labelRequired}>
                              {"\u{002A}"}
                            </span>
                          </section>
                        ) : (
                          <section style={{ margin: "0 0 0.25rem" }}>
                            {props.label}
                          </section>
                        )
                      }
                      control={
                        <TextField
                          {...rest}
                          error={error ? true : false}
                          name={props.name}
                          onChange={(event: any) => {
                            handleChange(event, onChange);
                          }}
                          value={fieldValue}
                          className={`
                        ${styles.textField}
                        ${styles.preWhiteSpace}
                        ${props.hideField ? styles.noDisplay : ""}
                      `}
                          multiline
                          inputProps={{
                            className: styles.resizeVertical,
                            // maxLength: 255,
                          }}
                          rows={props.textareaRows ? props.textareaRows : 8}
                        />
                      }
                    />
                    <ErrorMessage
                      errors={errors}
                      name={props.name as string}
                      render={({ message }) => (
                        <CustomErrorMessage
                          error={"error"}
                          errorMessage={message}
                        />
                      )}
                    />
                    {props.additionalComponentForModal &&
                      props.modalMode === "edit" &&
                      props.additionalComponentForModal}
                  </>
                )}
              />
            </>
          )}
        </>
      );
    }

    if (props.name === "phone") {
      return (
        <div>
          <Typography variant="body1" className={styles.phoneInputLabel}>
            {props.label}
          </Typography>
          <MuiTelInput
            placeholder={t("input_your_phone_number")}
            defaultCountry="DE"
            langOfCountryName="DE"
            value={props.value || ""}
            onChange={(value: string) => {
              if (value) {
                props.onChange?.(value);
                setValue(props.name as string, value);
              } else {
                props.onChange?.("");
                setValue(props.name as string, "");
              }
            }}
            className={styles.fullWidth}
          />
        </div>
      );
    }

    return (
      <>
        {!props.hideField && (
          <div>
            <Controller
              name={props.name as string}
              control={control}
              rules={{
                pattern: {
                  value: props.rule,
                  message: props.errorMessageValidation
                    ? props.errorMessageValidation
                    : t("error_msg_invalid_characters_change_your_input"),
                },
                required: {
                  value: (() => {
                    if (props.name === "email") {
                      return !disabledEmailForAnalogUser;
                    }
                    if (props.name === "trigger_class_name") {
                      return mandatoryRulesClassname;
                    }
                    return props.required;
                  })(),
                  message: props.errorMessage
                    ? props.errorMessage
                    : t("field_required"),
                },

                maxLength: {
                  value: props.maxLength,
                  message: props.maxLengthMessage ? props.maxLengthMessage : "",
                },
                validate: {
                  value: (value: any) => {
                    if (props.name === "email" && disabledEmailForAnalogUser) {
                      return true;
                    }
                    if (
                      props.name === "email" &&
                      props.modalMode === "insert" &&
                      props.validate &&
                      props.userEmails?.includes(value)
                    ) {
                      return props.errorMessageValidation;
                    }
                    if (
                      props.name === "email" &&
                      !emailValidationRegex.test(value)
                    ) {
                      return t("enter_valid_email");
                    }

                    return true;
                  },
                },
              }}
              render={({
                field: { onChange, ...rest },
                fieldState: { error },
                formState: { errors },
              }) => (
                <>
                  <Tooltip
                    title={
                      subdomain === "manage" &&
                      props.modalMode === "edit" &&
                      getValues("is_registered") === true &&
                      props.name === "email"
                        ? t("email_no_edit")
                        : ""
                    }
                    placement="top"
                  >
                    <FormControlLabel
                      labelPlacement="top"
                      sx={{
                        margin: "0 ",
                        width: "100%",
                        alignItems: "start",
                        fontFamily: "inherit !important",
                      }}
                      disableTypography
                      label={
                        props.required ? (
                          <section style={{ margin: "0 0 0.25rem" }}>
                            {props.label}
                            <span className={styles.labelRequired}>
                              {"\u{002A}"}
                            </span>
                          </section>
                        ) : (
                          <section style={{ margin: "0 0 0.25rem" }}>
                            {props.label}
                          </section>
                        )
                      }
                      control={
                        <TextField
                          {...rest}
                          error={error ? true : false}
                          type={props.type}
                          name={props.name}
                          onChange={(event: any) => {
                            handleChange(event, onChange);
                          }}
                          value={fieldValue}
                          className={`
                        ${styles.textField}
                        ${props.hideField ? styles.noDisplay : ""}
                      `}
                          helperText={props.helperText}
                          autoComplete={props.autoComplete}
                          sx={{}}
                          disabled={
                            (subdomain === "manage" &&
                              props.modalMode === "edit" &&
                              getValues("is_registered") === true &&
                              props.name === "email") ||
                            (props.name === "email" &&
                              disabledEmailForAnalogUser)
                          }
                        />
                      }
                    />
                  </Tooltip>
                  <ErrorMessage
                    errors={errors}
                    name={props.name as string}
                    render={({ message }) => (
                      <CustomErrorMessage
                        error={"error"}
                        errorMessage={message}
                      />
                    )}
                  />
                </>
              )}
            />
          </div>
        )}
        {error && error !== "" && (
          <GenericErrorMessageModal
            title={t("error_occurred")}
            error={error}
            onClosehandler={() => {
              setError("");
            }}
          />
        )}
      </>
    );
  }
}

//TODO CUSTOMERROR
