import { useEffect, useRef, useState } from "react";
import Administration from "../components/userAdministration/administration";
import { IDropDownOption } from "../types/DropDown";
import {
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Modal from "../components/modal";
import DropDownSubjectSearch from "../components/forms/inputs/dropDownSubjectSearch";
import { api } from "../helper/api";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../components/state/store";
import GenericErrorMessageModal from "../components/forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../components/notification/genericNotification";
import Restricted from "../consumer/restricted";
import styles from "./userAdministration.module.css";
import { useConfirmationModal } from "../context/confirmationModalContext";

export default function UserAdministration() {
  const [companySubjectId, setCompanySubjectId] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const [entries, setEntries] = useState<any>([]);
  const [entriesModal, setEntriesModal] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /*eslint-disable */
  const [subjectOptions, setSubjectOptions] = useState<IDropDownOption[]>([]);
  /*eslint-enable */
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const selectedChildCompany = useSelector((state: RootState) => state.parent);

  const customerRef = useRef(null);
  const dispatch = useDispatch();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const handleCompanySelect = (selectedOption: any) => {
    if (
      selectedOption &&
      typeof selectedOption === "object" &&
      selectedOption.value
    ) {
      setCompanySubjectId(selectedOption.value);
      setCompanyId(selectedOption.companyId);
      dispatch({ type: "clearTableSearch" });
    }
  };

  const handleUploadSuccess = () => {
    setUploadSuccessful(true);
  };

  const handleCloseSkippedEntriesModal = () => {
    setEntriesModal(false);
    // setUploadSuccessful(true);
  };

  const entriesModalContent = (
    <div className={styles.entriesModalContainer}>
      <section className={styles.entriesSection}>
        <p className={styles.boldText}>{t("created_employees_entries")}:</p>
        <span>{entries["created entries"]?.length}</span>
      </section>
      <section className={styles.entriesSection}>
        <p className={styles.boldText}>{t("updated_employees_entries")}:</p>
        <span>{entries["updated entries"]?.length}</span>
      </section>
      <section className={styles.entriesSectionColumn}>
        <p className={styles.incorrectEntriesText}>{t("incorrect_entries")}:</p>
        <List>
          {entries["skipped entries"]?.map((entry: any, index: number) => (
            <ListItem
              key={entry.email + index}
              className={styles.errorListItem}
            >
              <ListItemText
                primary={entry?.email}
                secondary={Object.entries(entry?.fields || {})
                  .map(([field, errors]) =>
                    Array.isArray(errors)
                      ? errors
                          .map((error: any) => `${field}: ${error}`)
                          .join(", ")
                      : `${field}: ${errors}`
                  )
                  .join("; ")}
                secondaryTypographyProps={{ color: "red" }}
              />
            </ListItem>
          ))}
        </List>
      </section>
    </div>
  );

  useEffect(() => {
    if (uploadSuccessful) {
      setUploadSuccessful(false);
    }
  }, [uploadSuccessful]);

  const handleSendRegistrationEmail = async (
    row: any,
    skipQuestion?: false | undefined
  ) => {
    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        row.original.is_registered
          ? t("send_passwordreset_email_question", {
              email: row.original.email,
            })
          : t("send_registration_email_question", {
              email: row.original.email,
            })
      );
      if (!isConfirmed) return;
    }

    const result = { impersonate_subject: selectedChildCompany.subjectId };
    const additionalRouteParts: any = {};
    additionalRouteParts["send_user_registration_email"] = "/";

    api.genericApiRequest({
      entity: "subject",
      method: "post",
      successHandler: () => {
        setNotificationMessage(t("successfully_sent"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      entityId: row.original.subject_id,
      shouldNotImpersonate: true,
      additionalRouteParts: additionalRouteParts,
      submitData: result,
    });
  };

  const handleUnblockUser = async (
    row: any,
    skipQuestion?: false | undefined
  ) => {
    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("unblock_user_question", {
          username: row.original.first_name + " " + row.original.last_name,
        })
      );
      if (!isConfirmed) return;
    }

    api.genericApiRequest({
      entity: "user",
      entityId: row.original.id,
      method: "put",
      additionalRouteParts: { unblock_login_access: "/" },
      successHandler: () => {
        setNotificationMessage(t("user_successfully_unblocked"));
        setNotificationVariant("success");
        setShowNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  };

  const handleUndeleteUser = async (
    row: any,
    reset: () => void,
    skipQuestion?: false | undefined
  ) => {
    if (!skipQuestion) {
      const isConfirmed = await showConfirmationModalAsync(
        t("undelete_user_question", {
          username: row.original.first_name + " " + row.original.last_name,
        })
      );
      if (!isConfirmed) return;
    }
    const result = { is_active: true, email: row.original.email };
    api.genericApiRequest({
      entity: "user",
      entityId: row.original.id,
      method: "put",
      successHandler: () => {
        setNotificationMessage(t("user_successfully_undeleted"));
        setNotificationVariant("success");
        setShowNotification(true);
        reset();
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
    });
  };

  const handleTaskTrackingId = async (taskTrackingId: string) => {
    if (!taskTrackingId) return;

    const getTask = async (taskTrackingId: string) => {
      setIsLoading(true);
      setEntriesModal(true);
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const response: any = await new Promise((resolve, reject) => {
          api.genericApiRequest({
            method: "get",
            entity: "taskTracking",
            entityId: taskTrackingId,
            additionalRouteParts: { wait_for_task_to_complete: "/" },
            successHandler: (res: any) => resolve(res),
            failHandler: (error: any) => reject(error),
          });
        });

        const status = response?.data?.status;

        if (status === "completed") {
          return response;
        } else if (status === "failed") {
          throw new Error("Waiting for Task failed");
        }

        await new Promise((r) => setTimeout(r, 5000));
      }
    };

    try {
      const response = await getTask(taskTrackingId);
      setEntries(response.data.result);
      setIsLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  function Admin() {
    let content = <></>;
    if (subdomain === "admin" && companySubjectId) {
      content = (
        <Administration
          companySubjectId={companySubjectId}
          companyId={companyId}
          uploadSuccessful={uploadSuccessful}
          handleSendRegistrationEmail={handleSendRegistrationEmail}
          handleUnblockUser={handleUnblockUser}
          handleUndeleteUser={handleUndeleteUser}
          subdomain={subdomain}
          handleUploadSuccess={handleUploadSuccess}
          selectedChildCompany={selectedChildCompany}
          handleTaskTrackingId={handleTaskTrackingId}
          isEntriesModalOpen={entriesModal}
        />
      );
    } else if (subdomain === "manage") {
      content = (
        <Administration
          uploadSuccessful={uploadSuccessful}
          selectedChildCompany={selectedChildCompany}
          handleUploadSuccess={handleUploadSuccess}
          subdomain={subdomain}
          handleSendRegistrationEmail={handleSendRegistrationEmail}
          handleTaskTrackingId={handleTaskTrackingId}
          isEntriesModalOpen={entriesModal}
        />
      );
    }
    return content;
  }

  return (
    <Restricted permissionRequired="frontend_view_user_administration">
      <h1>{t("customer")}</h1>
      <Grid container>
        {subdomain === "admin" && (
          <Grid item xs={12} className={styles.dropDownContainer}>
            <DropDownSubjectSearch
              subdomain={subdomain}
              options={subjectOptions}
              wrapperClassName={styles.dropDownSubjectSearch}
              onChange={handleCompanySelect}
              customLabel={t("company")}
              queryParameter="companies"
              autofocus={true}
              customRef={customerRef}
              useGlobalState={true}
              isClearable={true}
            />
          </Grid>
        )}
      </Grid>

      <Admin />
      <Modal
        open={entriesModal}
        cancelButtonText={t("confirm")}
        hideSubmitButton={true}
        onClose={handleCloseSkippedEntriesModal}
        title={t("overview")}
        disableCancelButton={isLoading}
      >
        {isLoading ? (
          <>
            <LinearProgress />
            <span className={styles.uploadModalText}>
              {t("wait_for_csvupload_progress")}
            </span>
          </>
        ) : (
          entriesModalContent
        )}
      </Modal>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </Restricted>
  );
}
