import { createContext, ReactNode, useContext } from "react";

type ShowConfirmationModalOptions = {
  title?: string;
  confirmButtonText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  autoCloseAfter?: number;
  disableConfirmFor?: number;
};

type ConfirmationModalContextType = {
  showConfirmationModal: (
    message: string | ReactNode,
    options?: ShowConfirmationModalOptions
  ) => void;
  showConfirmationModalAsync: (
    message: string | ReactNode,
    options?: ShowConfirmationModalOptions
  ) => Promise<boolean>;
};

export const ConfirmationModalContext =
  createContext<ConfirmationModalContextType>({
    showConfirmationModal: () => {
      /* No function for Linter */
    },
    showConfirmationModalAsync: async () => false,
  });

export const useConfirmationModal = () => useContext(ConfirmationModalContext);
