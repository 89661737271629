import { ReactFlowProvider } from "@xyflow/react";
import { FC, ReactNode } from "react";

interface ActionChainProviderProps {
  children: ReactNode;
}

const ActionChainProvider: FC<ActionChainProviderProps> = ({ children }) => {
  return <ReactFlowProvider>{children}</ReactFlowProvider>;
};

export default ActionChainProvider;
