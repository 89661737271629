import { FC, useState } from "react";
import DropDown from "../forms/inputs/dropDown";
import { t } from "i18next";
import DefaultInput from "../forms/inputs/defaultInput";
import { Grid } from "@mui/material";
import { TextArea } from "../forms/inputs/textArea";
import styles from "./appointmentBuildingInformation.module.css";

interface props {
  appointment?: any;
  formValues?: any;
  handleInputChange?: any;
  buildingOptions?: any;
  selectedBuilding?: any;
  handleBuildingChange?: any;
  withGrid?: boolean;
}

const AppointmentBuildingInformation: FC<props> = ({
  appointment,
  formValues,
  handleInputChange,
  buildingOptions,
  selectedBuilding,
  handleBuildingChange,
  withGrid,
}) => {
  const [emailError, setEmailError] = useState<string>("");
  const [phoneError, setPhoneError] = useState<string>("");

  const readOnly =
    appointment && appointment?.appointmentslot_set?.length !== 0
      ? true
      : false;

  const handleSelectBuilding = (param: any) => {
    let selectedValue: string;

    if (param && param.target) {
      selectedValue = param.target.value;
    } else if (param && param.value) {
      selectedValue = param.value;
    } else {
      selectedValue = param;
    }

    if (selectedValue === selectedBuilding) return;

    const selectedBuildingData = buildingOptions?.find((b: any) => b.value === selectedValue);
    if (!selectedBuildingData) {
      return;
    }

    if (handleBuildingChange) {
      handleBuildingChange(selectedBuildingData);
    }

    if (handleInputChange) {
      const fields = [
        { name: 'contact_name', key: 'contact_name' },
        { name: 'contact_email', key: 'contact_email' },
        { name: 'contact_phone', key: 'contact_phone' },
        { name: 'parking_situation', key: 'parking_situation' },
        { name: 'additional_information', key: 'additional_information' },
      ];
      fields.forEach(field => {
        handleInputChange({
          target: {
            name: field.name,
            value: selectedBuildingData[field.key] || ""
          }
        });
      });
    }
  };

  const handleEmailBlur = (e: any) => {
    const email = e.target.value;
    const isValidEmail = (email: string): boolean => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if (email.trim() !== "") {
      if (!isValidEmail(email)) {
        setEmailError(t("please_enter_valid_email"));
      } else {
        setEmailError("");
      }
    } else {
      setEmailError("");
    }
    if (handleInputChange) {
      handleInputChange(e);
    }
  };

  const handlePhoneBlur = (e: any) => {
    const phone = e.target.value;
    const isValidPhone = (phone: string): boolean => {
      const phoneRegex = /^[0-9\s\-+()]+$/;
      return phoneRegex.test(phone) && phone.trim().length >= 6;
    };

    if (phone.trim() !== "") {
      if (!isValidPhone(phone)) {
        setPhoneError(t("please_enter_valid_phone_number"));
      } else {
        setPhoneError("");
      }
    } else {
      setPhoneError("");
    }
    if (handleInputChange) {
      handleInputChange(e);
    }
  };

  return (
    <>
      {withGrid ? (
        <>
          <Grid item xs={12}>
            <DropDown
              options={buildingOptions}
              label={t("buildings")}
              name="building"
              onChange={handleSelectBuilding}
              defaultValue={selectedBuilding}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              name="contact_name"
              label={t("building_contact_name")}
              value={formValues.contact_name}
              onChange={(e: any) => handleInputChange(e)}
              autoComplete="off"
              readonly={readOnly}
              inputClassName={styles.defaultInput}
            />
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              name="contact_email"
              label={t("building_contact_email")}
              value={formValues.contact_email}
              onBlur={handleEmailBlur}
              onChange={(e: any) => handleInputChange(e)}
              autoComplete="off"
              readonly={readOnly}
              inputClassName={styles.defaultInput}
            >
              {emailError && (
                <span className={styles.errorStyle}>
                  {emailError}
                </span>
              )}
            </DefaultInput>
          </Grid>
          <Grid item xs={12}>
            <DefaultInput
              name="contact_phone"
              label={t("building_contact_phone")}
              value={formValues.contact_phone}
              onBlur={handlePhoneBlur}
              onChange={(e: any) => handleInputChange(e)}
              autoComplete="off"
              readonly={readOnly}
              inputClassName={styles.defaultInput}
            >
              {phoneError && (
                <span className={styles.errorStyle}>
                  {phoneError}
                </span>
              )}
            </DefaultInput>
          </Grid>
          <Grid item xs={12}>
            <TextArea
              withLabel={true}
              name="parking_situation"
              label={t("building_parking_situation")}
              value={formValues.parking_situation}
              onChange={(e: any) => handleInputChange(e)}
              readonly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              withLabel={true}
              name="additional_information"
              label={t("building_additional_information")}
              value={formValues.additional_information}
              onChange={(e: any) => handleInputChange(e)}
              readonly={readOnly}
            />
          </Grid>
        </>
      ) : (
        <>
          <DropDown
            options={buildingOptions}
            label={t("buildings")}
            name="building"
            onChange={handleSelectBuilding}
            defaultValue={selectedBuilding}
            disabled={readOnly}
          />
          <DefaultInput
            name="contact_name"
            label={t("building_contact_name")}
            value={formValues.contact_name}
            onChange={(e: any) => handleInputChange(e)}
            autoComplete="off"
            readonly={readOnly}
            inputClassName={styles.defaultInput}
          />
          <DefaultInput
            name="contact_email"
            label={t("building_contact_email")}
            value={formValues.contact_email}
            onBlur={handleEmailBlur}
            onChange={(e: any) => handleInputChange(e)}
            autoComplete="off"
            readonly={readOnly}
            inputClassName={styles.defaultInput}
          >
            {emailError && (
              <span className={styles.errorStyle}>
                {emailError}
              </span>
            )}
          </DefaultInput>
          <DefaultInput
            name="contact_phone"
            label={t("building_contact_phone")}
            value={formValues.contact_phone}
            onBlur={handlePhoneBlur}
            onChange={(e: any) => handleInputChange(e)}
            autoComplete="off"
            readonly={readOnly}
            inputClassName={styles.defaultInput}
          >
            {phoneError && (
              <span className={styles.errorStyle}>
                {phoneError}
              </span>
            )}
          </DefaultInput>
          <TextArea
            withLabel={true}
            name="parking_situation"
            label={t("building_parking_situation")}
            value={formValues.parking_situation}
            onChange={(e: any) => handleInputChange(e)}
            readonly={readOnly}
          />
          <TextArea
            withLabel={true}
            name="additional_information"
            label={t("building_additional_information")}
            value={formValues.additional_information}
            onChange={(e: any) => handleInputChange(e)}
            readonly={readOnly}
          />
        </>
      )}
    </>
  );
};

export default AppointmentBuildingInformation;
