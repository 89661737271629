import React from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from "./textAreaWithRef.module.css";

interface Props {
  style?: React.CSSProperties;
  mandatory?: boolean;
  label?: string;
  name?: string;
  readonly?: boolean;
  required?: boolean;
  rows?: number;
  cols?: number;
  value?: any;
  defaultValue?: string;
  onChange?: (event: any) => void;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
}

export const TextAreaWithRef = React.forwardRef<HTMLTextAreaElement, Props>(
  function TextAreaWithRef(props, ref) {
    return (
      <div>
        <label className={styles.label}>
          {props.mandatory === true || props.required === true ? (
            <>
              {props.label}
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            <>{props.label}</>
          )}
          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <textarea
          ref={ref}
          name={props.name}
          id={props.name}
          readOnly={props.readonly}
          // required={props.required}
          rows={props.rows !== undefined ? props.rows : 4}
          cols={props.cols}
          defaultValue={props.defaultValue}
          onChange={(event: any) => props.onChange?.(event)}
          className={`${styles.textArea} ${
            props.cols !== undefined ? styles.textAreaWithCustomWidth : ""
          }`}
          {...(props.value !== null && props.value !== undefined
            ? { value: props.value }
            : {})}
        />
      </div>
    );
  }
);
