import { useState, useEffect, useMemo, useCallback } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import "../../components/dragndrop/formEditorStyles.css";
import { useNavigate, useParams } from "react-router-dom";
import { Column } from "../../components/dragndrop/dndColumn";
import CheckBox from "../../components/forms/inputs/checkBox";
import { IMeasurementDefinition } from "../../types/Entities";
import { useTranslation } from "react-i18next";
import { DotLoader } from "react-spinners";
import { api } from "../../helper/api";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../../components/notification/genericNotification";
import Restricted from "../../consumer/restricted";
import DefaultInput from "../../components/forms/inputs/defaultInput";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import styles from "./formEditor.module.css";
import GenericButton from "../../components/forms/inputs/button/genericButton";
import { useConfirmationModal } from "../../context/confirmationModalContext";

interface MdCatalogue extends IMeasurementDefinition {
  mandatory?: boolean;
  visibility_ruleset?: any;
  order?: number;
  is_used_in_form?: any;
}

interface FormValue {
  name?: string;
}

export function FormEditor() {
  const [availableElements, setAvailableElements] = useState<MdCatalogue[]>([]);
  const [query, setQuery] = useState<string>("");
  const [rightColumn, setRightColumn] = useState<MdCatalogue[]>([]);
  const [leftColumn, setLeftColumn] = useState<MdCatalogue[]>([]);
  const [leftColumnPlaceholderText, setLeftColumnPlaceholderText] =
    useState<string>("");
  const [formName, setFormName] = useState<string>("");
  const [probeId, setProbeId] = useState<string>("");
  const [isEditableBySubject, setIsEditableBySubject] = useState<boolean>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [formValue, setFormValue] = useState<FormValue>({
    name: "",
  });

  const { formId } = useParams() as { formId: string };
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { showConfirmationModalAsync } = useConfirmationModal();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  useEffect(() => {
    if (formId) {
      api.genericApiRequest({
        entity: "form",
        method: "get",
        successHandler: (res: any) => {
          setProbeId(res.data.probe.id);
          setFormName(res.data.name);
          setIsEditableBySubject(res.data.editable_by_subject);
          const preexisting = res.data.measurementdefinitiontoform_set.map(
            (item: any) => ({
              ...item.measurement_definition,
              mandatory: item.mandatory,
              order: item.order,
              visibility_ruleset: item.visibility_ruleset,
            })
          );
          const sorted = preexisting.sort(
            (a: any, b: any) => a.order - b.order
          );
          setRightColumn(sorted);
          setIsLoading(false);
        },
        failHandler: (error: any) => {
          setError(error);
          setIsLoading(false);
        },
        entityId: String(formId),
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query.trim().length >= 3) {
        setSearchLoading(true);
        const additionalUrlParameters: any = {};
        additionalUrlParameters["name__icontains"] = query;

        api.genericApiRequest({
          entity: "measurementDefinition",
          method: "get",
          getAllPages: true,
          parametersToRender: {
            depth: "0",
            limit: "50",
            additionalUrlParameters: additionalUrlParameters,
          },
          successHandler: (res: any) => {
            setAvailableElements(res.data.results);
            setSearchLoading(false);
            if (res.data.results.length === 0) {
              setLeftColumnPlaceholderText(t("no_results_found"));
            } else {
              setLeftColumnPlaceholderText(
                t("search_to_show_available_elements")
              );
            }
          },
          failHandler: (error: any) => {
            setError(error);
            setSearchLoading(false);
          },
        });
      } else {
        setAvailableElements([]);
        setLeftColumn([]);
        setLeftColumnPlaceholderText(t("search_to_show_available_elements"));
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  useEffect(() => {
    if (query.trim() !== "") {
      const filteredLeft = availableElements.filter(
        (item: any) =>
          !rightColumn.some((rightItem: any) => rightItem.id === item.id)
      );
      setLeftColumn(filteredLeft);
    }
  }, [availableElements, rightColumn, query]);

  //-- OnDrag - Start --
  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      if (result.source.droppableId !== "column-1") {
        return;
      }
      const items: any = reorderList(
        rightColumn,
        result.source.index,
        result.destination.index
      );
      setRightColumn(items);
      return;
    }

    if (result.source.droppableId === "column-1") {
      return;
    }
    const item = leftColumn[result.source.index];
    const newItem = { mandatory: false, visibility_ruleset: "{}" };
    Object.assign(item, newItem);

    const newLeftColumn = [...leftColumn];
    newLeftColumn.splice(result.source.index, 1);

    const newRightColumn = [...rightColumn];
    newRightColumn.splice(result.destination.index, 0, item);

    setRightColumn(newRightColumn);
    setLeftColumn(newLeftColumn);
  }
  //-- OnDrag - End --
  const handleMandatoryCheckbox = useCallback(
    (id: any) => {
      const newRightColumn: any = [...rightColumn];
      const target = newRightColumn.find((item: any) => item.id === id);
      if (target) {
        target.mandatory = !target.mandatory;
      }
      setRightColumn(newRightColumn);
    },
    [rightColumn]
  );

  const handleRulesetChange = useCallback(
    (value: any, id: any) => {
      const newRightColumn: any = [...rightColumn];
      const itemToUpdate = newRightColumn.find((item: any) => item.id === id);
      if (!itemToUpdate) {
        return;
      }
      itemToUpdate.visibility_ruleset = value;
      setRightColumn(newRightColumn);
    },
    [rightColumn]
  );

  const handleDelete = useCallback(
    async (idToDelete: any) => {
      const itemToDelete: any = rightColumn.find(
        (item: any) => item.id === idToDelete
      );
      if (!itemToDelete) {
        return;
      }

      const isConfirmed = await showConfirmationModalAsync(
        t("form_delete_field_confirm", {
          name: itemToDelete?.label,
        })
      );
      if (!isConfirmed) return;

      const newRightColumn = rightColumn.filter(
        (item: any) => item.id !== itemToDelete.id
      );
      const newLeftColumn: any = availableElements.filter(
        (id1: any) => !newRightColumn.some((id2: any) => id1 === id2)
      );
      setRightColumn(newRightColumn);
      setLeftColumn(newLeftColumn);
    },
    [rightColumn, availableElements]
  );

  const handleSaveForm = async () => {
    // const result: any = [];

    const updatedRightColumn = rightColumn.map((item: any) => {
      const visibilityRulesetString =
        typeof item.visibility_ruleset === "string"
          ? item.visibility_ruleset.trim()
          : JSON.stringify(item.visibility_ruleset);
      return visibilityRulesetString !== "{}"
        ? { ...item, mandatory: false }
        : item;
    });
    const result: any = updatedRightColumn.map((item: any) => ({
      id: item.id,
      mandatory: item.mandatory,
      visibility_ruleset:
        item.visibility_ruleset !== "" ? item.visibility_ruleset : "{}",
    }));

    setRightColumn(updatedRightColumn);

    const submitData = {
      name: formValue.name !== "" ? formValue.name : formName,
      probe: probeId,
      measurement_definition: result,
      editable_by_subject: isEditableBySubject,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await new Promise<any>((resolve, reject) => {
        api.genericApiRequest({
          entity: "form",
          method: "put",
          successHandler: (res: any) => {
            resolve(res);
          },
          failHandler: (error: any) => {
            reject(error);
          },
          entityId: String(formId),
          submitData: submitData,
          config: config,
        });
      });
      setNotificationMessage(t("save_successful"));
      setNotificationVariant("success");
      setShowNotification(true);
      setProbeId(response.data.probe?.id);
      navigate(`/settings/formEditor/${response.data.id}`);
    } catch (error) {
      setError(error);
    }
  };

  const handleNavigateBack = () => {
    if (probeId !== "") {
      navigate(`/settings/probeEditor/${probeId}`);
    } else {
      navigate(-1);
    }
  };

  function reorderList(list: any, startIndex: any, endIndex: any) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const handleChange = (event: any) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const handleCheckboxChange = (value: boolean) => {
    setIsEditableBySubject(value);
  };

  const memoizedLeftColumn = useMemo(
    () => (
      <Grid item xs={4}>
        {searchLoading ? (
          <div className={styles.searchLoadingWrapper}>
            <DotLoader color="#8c1ec8" size={40} />
          </div>
        ) : (
          <Column
            key={"column-0"}
            column={{
              id: "column-0",
              items: leftColumn,
              title: t("available_elements"),
            }}
            index={0}
            leftColumnPlaceholderText={leftColumnPlaceholderText}
          />
        )}
      </Grid>
    ),
    [leftColumn, searchLoading, leftColumnPlaceholderText]
  );

  const memoizedRightColumn = useMemo(
    () => (
      <Grid item xs={8}>
        <Column
          key={"column-1"}
          column={{
            id: "column-1",
            items: rightColumn,
          }}
          index={1}
          handleDeleteItem={handleDelete}
          onHandleCheckboxChange={handleMandatoryCheckbox}
          onHandleRulesetChange={handleRulesetChange}
        />
      </Grid>
    ),
    [rightColumn, handleDelete, handleMandatoryCheckbox, handleRulesetChange]
  );

  return (
    <Restricted permissionRequired="frontend_view_form_editor">
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container rowSpacing={"1rem"} className={styles.container1}>
              <Grid container item xs={12} className={styles.formEditorHeader}>
                <Grid item xs={12}>
                  <List
                    subheader={"Visibility Ruleset Beispiele:"}
                    className={styles.exampleList}
                  >
                    <ListItem className={styles.exampleListItem}>
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"is": "yes"}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"not": "no"}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"gt": 0.5}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"lt": 1.4}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t(
                          "variable_name"
                        )}": {"in": [0.1, 0.2, 5.0]}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`{"${t("variable_name")}": {"nin": ["${t(
                          "hay_fever"
                        )}", "${t("rash")}"]}}`}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          component: "span",
                          sx: { fontSize: "0.85rem" },
                        }}
                        primary={`Bei Mehrfachauswahl: {"${t(
                          "variable_name"
                        )}": {"contains": "Heuschnupfen"}}`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
              <Grid item className={styles.noPadding}>
                <Divider component={"div"} className={styles.noPadding} />
              </Grid>
              <Grid
                container
                item
                columnSpacing={"1rem"}
                className={styles.container2}
              >
                <Grid item xs={4}>
                  <DefaultInput
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Suche..."
                  />
                </Grid>
                <Grid item xs={8} className={styles.inputCheckboxContainer}>
                  {formName && (
                    <DefaultInput
                      name="name"
                      onChange={handleChange}
                      defaultValue={formName}
                    />
                  )}
                  <CheckBox
                    label={t("fillable_by_portal_user")}
                    onChange={handleCheckboxChange}
                    defaultValue={isEditableBySubject}
                    labelClassName={styles.checkBoxLabel}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                component={Paper}
                xs={12}
                className={styles.container3}
              >
                <Droppable
                  droppableId="all-droppables"
                  direction="horizontal"
                  type="column"
                >
                  {(provided) => (
                    <Grid
                      container
                      columnSpacing={"1rem"}
                      item
                      xs={12}
                      component={"div"}
                      className={styles.columnContainer}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {memoizedLeftColumn}
                      {memoizedRightColumn}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </Grid>
              <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                  <GenericButton
                    variant="outlined"
                    onClick={handleNavigateBack}
                  >
                    {t("back")}
                  </GenericButton>
                </Grid>
                <Grid item>
                  <GenericButton
                    waitingForResponse={true}
                    showSpinnerOnDisable={true}
                    onClick={handleSaveForm}
                  >
                    {t("save")}
                  </GenericButton>
                </Grid>
              </Grid>
            </Grid>

            <>
              {error && error !== "" && (
                <GenericErrorMessageModal
                  title={t("error_occurred")}
                  error={error}
                  onClosehandler={() => {
                    setError("");
                  }}
                />
              )}
              {showNotification && (
                <GenericNotification
                  message={notificationMessage}
                  variant={notificationVariant}
                  handleCloseSnackbar={resetStateOnCloseNotification}
                />
              )}
            </>
          </DragDropContext>
        </>
      )}
    </Restricted>
  );
}
