import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../helper/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormProvider, useForm } from "react-hook-form";
import GenericErrorMessageModal from "./errorHandling/genericErrorMessageModal";
import GenericDatePicker from "./inputs/genericDatePicker";
import GenericInputField from "./inputs/genericInputField";
import GenericNotification from "../notification/genericNotification";
import styles from "./userSettingsForm.module.css";
import GenericButton from "./inputs/button/genericButton";

interface Props {
  userInfos?: any;
}

const UserSettingsForm: FC<Props> = ({ userInfos }) => {
  const [error, setError] = useState<any>();
  const [successForNotification, setSuccessForNotification] =
    useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();

  const methods = useForm<any>({
    mode: "onChange",
  });

  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    const result = data;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    api.genericApiRequest({
      entity: "user",
      method: "put",
      entityId: userInfos.user_id,
      successHandler: () => {
        setNotificationMessage(t("save_successful"));
        setNotificationVariant("success");
        setSuccessForNotification(true);
      },
      failHandler: (error: any) => {
        setError(error);
      },
      submitData: result,
      config: config,
    });
  };

  const resetStateOnCloseNotification = () => {
    setSuccessForNotification(false);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{t("personal_settings")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className={styles.form}
              noValidate
            >
              <GenericDatePicker
                name="birth_date"
                label={t("birthdate")}
                predefinedvalue={userInfos?.person?.birth_date}
              />
              <GenericInputField
                predefinedvalue={userInfos?.person?.phone}
                name="phone"
                label={t("phone")}
                inputType="tel"
              />

              <GenericButton className={styles.submitButton} type="submit">
                {t("save")}
              </GenericButton>
            </form>
          </FormProvider>
        </AccordionDetails>
      </Accordion>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {successForNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
};

export default UserSettingsForm;
