import React from "react";
import InputTooltip, { placement } from "../../general/tooltip";
import styles from "./dateFieldWithRef.module.css";

interface Props {
  mandatory?: boolean;
  label?: string;
  readonly?: boolean;
  name?: string;
  required?: boolean;
  onChange?: (event: any) => void;
  defaultValue?: any;
  tooltip?: boolean;
  placement?: placement;
  tooltipMessage?: any;
}

export const DateFieldWithRef = React.forwardRef<HTMLInputElement, Props>(
  function DateFieldWithRef(props, ref) {
    return (
      <div>
        <label className={styles.label}>
          {props.label}
          {props.required === true && (
            <span style={{ color: "red" }}>&nbsp;{"\u{002A}"}</span>
          )}
          {props.tooltip && (
            <InputTooltip
              tooltipMessage={props.tooltipMessage}
              placement={props.placement}
            />
          )}
        </label>
        <input
          ref={ref}
          name={props.name}
          type="date"
          readOnly={props.readonly}
          id={props.name}
          data-testid="dateFieldWithRef-date-input"
          // required={props.required}
          onChange={props.onChange}
          className={styles.dateInput}
          min="2000-01-01"
          max="2999-12-31"
        ></input>
      </div>
    );
  }
);
