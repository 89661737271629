import React, { FC, useEffect, useState } from "react";
import GenericCrudTable from "../tables/genericCrudTable";
import { t } from "i18next";
import { replaceDate } from "../../helper/dateHandling";
import { convertEurocentToEuroForLabels } from "../../helper/formattingValues";
import { api } from "../../helper/api";

const TrackedServiceTemplatesTable: FC = () => {
  const [trackedServiceTypeForFiltering, setTrackedServiceTypeForFiltering] =
    useState<any[]>([]);
  const [mainCategoryOptionsForFiltering, setMaincategoryOptionsForFiltering] =
    useState<any[]>([]);

  useEffect(() => {
    getTrackedServiceType(setTrackedServiceTypeForFiltering);
    getMainCategories(setMaincategoryOptionsForFiltering);
  }, []);
  const trackedServiceTemplateColumns = [
    { header: "ID", accessorKey: "id", id: "id" },
    {
      header: t("internal_name"),
      accessorKey: "internal_name",
      id: "internal_name",
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("description"),
      accessorKey: "description",
      id: "description",
      enableForCreate: true,
      textarea: true,
    },
    {
      header: t("estimated_duration"),
      accessorKey: "estimated_duration",
      id: "estimated_duration",
      enableForCreate: true,
      textfieldType: "number",
      required: true,
      errorMessage: t("field_required"),
    },
    {
      header: t("price_with_currency", { currencySymbol: "€" }),
      accessorKey: "price",
      filterVariant: "price",
      customColumnFilterId: "price",
      id: "price",
      enableForCreate: true,
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{convertEurocentToEuroForLabels(cell.getValue())}</span>;
      },
    },

    {
      header: t("trackedservice_type"),
      accessorKey: "tracked_service_type",
      id: "tracked_service_type",
      enableForCreate: true,
      entity: "trackedServiceType",
      filterVariant: "select",
      filterSelectOptions: trackedServiceTypeForFiltering,
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("category"),
      accessorKey: "category",
      id: "category",
      enableForCreate: true,
      entity: "mainCategory",
      filterVariant: "select",
      filterSelectOptions: mainCategoryOptionsForFiltering,
      required: true,
      errorMessage: t("field_required"),
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("created_on"),
      accessorKey: "created_on",
      id: "created_on",
      filterVariant: "date",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("created_by"),
      accessorKey: "created_by",
      customColumnFilterId: "created_by_name",

      id: "created_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
    {
      header: t("modified_on"),
      accessorKey: "modified_on",
      filterVariant: "date",
      id: "modified_on",
      sortingFn: "datetime",
      Cell: ({ cell }: any) => {
        return <span>{replaceDate(cell.getValue())}</span>;
      },
    },
    {
      header: t("modified_by"),
      accessorKey: "modified_by",
      customColumnFilterId: "modified_by_name",
      id: "modified_by",
      Cell: ({ cell }: any) => {
        return <span>{cell.getValue().name}</span>;
      },
    },
  ];
  return (
    <>
      <GenericCrudTable
        heading={t("trackedservice_template_tableHeading")}
        entity="trackedServiceTemplate"
        columns={trackedServiceTemplateColumns}
        allowColumnFiltering={true}
        showColumnFilterbyDefault={true}
      />
    </>
  );
};

export default TrackedServiceTemplatesTable;

function getTrackedServiceType(setOptions: any) {
  api.genericApiRequest({
    method: "get",
    entity: "trackedServiceType",
    successHandler: (res: any) => {
      const typeOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(typeOptions);
    },
  });
}
function getMainCategories(setOptions: any) {
  api.genericApiRequest({
    method: "get",
    entity: "mainCategory",
    successHandler: (res: any) => {
      const categoryOptions = res.data.results.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(categoryOptions);
    },
  });
}
