import { Box, Tooltip } from "@mui/material";
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
  MaterialReactTable,
} from "material-react-table";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import "../../files/documents.css";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { t } from "i18next";
import { IFileItem } from "../../../types/FileItem";
import FileTableIcon from "../../files/fileTableIcon";
import { api } from "../../../helper/api";
import GenericErrorMessageModal from "../../forms/errorHandling/genericErrorMessageModal";
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useDispatch } from "react-redux";
import { setSubjectSearch } from "../../state/subjectSearch/subjectSearchSlice";
import styles from "./documentTable.module.css";
import GenericButton from "../../forms/inputs/button/genericButton";

interface Props {
  subjectId: any;
  folderId?: any;
  subjectForDispatch?: any;
  folder: "all" | "reports" | "uploads";
  additionalColumns?: any;
  customPageSize?: any;
  customDensity?: "spacious" | "compact" | "comfortable";
  customTopToolBarAction?: ReactNode | undefined;
  tableMargin?: any;
  tableBoxShadow?: any;
  buttonLinkToDocuments?: boolean;
}

const DocumentTable: FC<Props> = (props: Props) => {
  const [fileData, setFileData] = useState<IFileItem[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: props.customPageSize ? props.customPageSize : 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "created_on", desc: true },
  ]);
  const [error, setError] = useState<any>();

  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  const columnSorting = "-modified_on";

  const reportsFolderId = "11111111-1111-1111-1111-888888888888";
  const uploadFolderId = "11111111-1111-1111-1111-999999999999";

  const replaceDate = (id: any) => {
    const date = new Date(id).toLocaleString();
    return date;
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        id: "id",
        enableHiding: false,
      },

      {
        header: t("name"),
        accessorKey: "name",
        id: "name",
        Cell: ({ row, renderedCellValue }: any) => {
          return (
            <Box className={styles.fileTableName}>
              <FileTableIcon extension={row.getValue("extension")} />
              <a
                href={"/api/v1" + row.original.view_link}
                target="_blank"
                rel="noreferrer"
                className="fileTableNameLink"
              >
                <Tooltip title={formatFilenameString(renderedCellValue)}>
                  <span>{formatFilenameString(renderedCellValue)}</span>
                </Tooltip>
              </a>
            </Box>
          );
        },
      },

      {
        header: t("extension"),
        accessorKey: "extension",
        id: "extension",
        size: 40,
      },
      {
        header: t("created_on"),
        accessorKey: "created_on",
        id: "created_on",
        size: 80,
        filterVariant: "date",
        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return <span>{replaceDate(cell.getValue())}</span>;
        },
      },
      {
        header: t("modified_on"),
        accessorKey: "modified_on",
        id: "modified_on",
        filterVariant: "date",

        sortingFn: "datetime",
        Cell: ({ cell }: any) => {
          return <span>{replaceDate(cell.getValue())}</span>;
        },
      },
    ],
    []
  );

  if (props.additionalColumns) {
    columns.push(props.additionalColumns);
  }

  const tableOptions = {
    columnHiding:
      subdomain === "admin" || subdomain === "manage" ? true : false,
  };

  useEffect(() => {
    if (!initialLoading) {
      {
        const fetchData = async () => {
          if (fileData.length === 0) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }
          const additionalUrlParameters: any = {};
          if (props.folder === "reports") {
            additionalUrlParameters["folder_id"] = reportsFolderId;
          }
          if (props.folder === "uploads") {
            additionalUrlParameters["folder_id"] = uploadFolderId;
          }

          if (props.subjectId) {
            additionalUrlParameters["impersonate_subject"] = props.subjectId;
          }

          try {
            /*eslint-disable */
            const res = await api.genericApiRequest({
              entity: "file",
              method: "get",
              parametersToRender: {
                depth: "0",
                ordering: columnSorting,
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize,
                search: globalFilter,
                additionalUrlParameters: additionalUrlParameters,
              },

              successHandler: (res: any) => {
                /*eslint-enable */
                const data = res.data;
                setFileData(data.results);
                setRowCount(data.count);
                setIsLoading(false);
                setInitialLoading(true);
                const files = [];
                for (const key in data.results) {
                  const item = data.results[key];
                  const id = item.id;
                  const name = item.name;
                  files.push({ id: id, name: name });
                }
              },
            });
          } catch (error) {
            setError(error);
            return;
          }
          setIsRefetching(false);
        };
        fetchData();
      }
    }
  }, [
    props.folderId,
    props.subjectId,
    pagination.pageIndex,
    pagination.pageSize,
    globalFilter,
    initialLoading,
    columnSorting,
  ]);

  function formatFilenameString(fileName: any) {
    let validFileName;

    if (typeof fileName === "object") {
      validFileName = fileName.props?.["aria-label"];
    } else if (typeof fileName === "string") {
      validFileName = fileName;
    }
    let stringWithSpaces = validFileName.replace(/_+/g, " ");

    const dateRegex = /(\d{2,4}-\d{1,2}-\d{1,2})$/;
    const match = stringWithSpaces.match(dateRegex);

    if (match) {
      const dateString = match[1];
      const [year, month, day] = dateString.split("-");
      const formatedDate = `${day}.${month}.${year}`;
      stringWithSpaces = stringWithSpaces.replace(dateRegex, formatedDate);
    }
    return stringWithSpaces;
  }

  const columnVisibility = {
    id: false,
    created_on: true,
    extension: false,
    modified_on: false,
  };

  const handleNavigateToDocuments = () => {
    const subject = {
      value: props?.subjectForDispatch?.id,
      label: props?.subjectForDispatch?.name,
    };
    dispatch(setSubjectSearch(subject));
    navigate("/document");
  };

  return (
    <>
      <MaterialReactTable
        localization={{
          ...MRT_Localization_DE,
        }}
        columns={columns}
        data={fileData}
        enableHiding={tableOptions.columnHiding}
        enableFullScreenToggle={false}
        positionToolbarAlertBanner="none"
        displayColumnDefOptions={{
          "mrt-row-actions": {
            size: 40,
            muiTableHeadCellProps: {
              align: "center",
            },
          },
        }}
        muiTablePaperProps={{
          sx: {
            width: "100%",
            boxShadow: props.tableBoxShadow && props.tableBoxShadow,
            marginTop: props.tableMargin ? props.tableMargin : "1rem",
            "&&  .MuiCollapse-root, && .MuiCollapse-wrapperInner": {
              width: "90%",
            },
            "& .MuiAlert-root": { backgroundColor: "#fff" },
            "& .MuiInputBase-root, && .MuiInput-root::before, && .MuiInput-root::after":
              { borderBottom: "none" },
            "& .MuiTextField-root": {
              backgroundColor: "#f4f2f2",

              outline: "none",
            },
            "&& .MuiBox-root": {
              alignItems: "center",
              justifyContent: "space-arround",
            },
          },
        }}
        muiTableBodyRowProps={({ row }: any) =>
          row.index % 2 === 0
            ? { sx: { backgroundColor: "#f5f5f5" } }
            : { sx: { backgroundColor: "#FFF" } }
        }
        muiTableBodyCellProps={{
          sx: {
            borderRight: "1px solid #e0e0e0",
          },
        }}
        initialState={{
          columnVisibility: columnVisibility,
          density: props.customDensity ? props.customDensity : "spacious",
          showGlobalFilter: true,
          showColumnFilters: false,
        }}
        state={{
          pagination: pagination,
          globalFilter: globalFilter,
          isLoading: isLoading,
          showProgressBars: isRefetching,
          sorting: sorting,
        }}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          onClick: () => {
            setGlobalFilter("");
          },
        }}
        onGlobalFilterChange={(e: any) => {
          setGlobalFilter(e);
          setInitialLoading(false);
        }}
        enableColumnFilters={false}
        onSortingChange={(e) => {
          setSorting(e), setInitialLoading(false);
        }}
        manualPagination={true}
        onPaginationChange={(e: any) => {
          setPagination(e);
          setInitialLoading(false);
        }}
        manualFiltering={true}
        rowCount={rowCount}
        enableRowSelection={false}
        renderTopToolbarCustomActions={() => {
          let toolBarItems: ReactNode = <></>;

          toolBarItems = (
            <>
              {props.buttonLinkToDocuments && (
                <GenericButton
                  startIcon={<OpenInNewIcon />}
                  onClick={handleNavigateToDocuments}
                >
                  <Link to="" className={styles.buttonLink}>
                    {t("documents")}
                  </Link>
                </GenericButton>
              )}
              {props.customTopToolBarAction && props.customTopToolBarAction}
            </>
          );
          return (
            <div className={styles.toolBarItemsContainer}>{toolBarItems}</div>
          );
        }}
        enableRowActions={false}
      />
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
    </>
  );
};

export default DocumentTable;
