import { useEffect, useState } from "react";
import LoginForm from "../components/forms/loginForm";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Background from "../icons/background";
import { renderToStaticMarkup } from "react-dom/server";
import "../components/styles/login.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Modal } from "@mui/material";
import { t } from "i18next";
import styles from "./login.module.css";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import { DotLoader } from "react-spinners";

interface Props {
  subdomain: string;
}

export default function Login(props: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { step } = useParams();

  useEffect(() => {
    if (step === undefined || step === "2fa") {
      setIsLoading(false);
    }
  }, [step]);

  const handleErrorMessage = (errorCode: string) => {
    setError(errorCode);
  };
  const handleResetErrorMessage = () => {
    setError(null);
  };
  const svgString = encodeURIComponent(renderToStaticMarkup(<Background />));

  const backgroundImage = {
    background: `url('data:image/svg+xml;utf8, ${svgString}')`,
  } as React.CSSProperties;

  const handleErrorElement = () => {
    if (error === "invalid") {
      return (
        <div className={styles.errorContainer}>
          <ErrorOutlineIcon />
          <div className={styles.errorMessageWrapper}>
            <span className={styles.errorMessageSpan}>
              {t("2fa_invalid_link")}
            </span>
            <span className={styles.errorMessageSpan}>
              {t("2fa_invalid_link_message")}
            </span>
            <NavLink className={styles.navLink} to={"/login"}>
              {t("login")}
            </NavLink>
          </div>
        </div>
      );
    } else
      return (
        <div className={styles.errorContainer}>
          <ErrorOutlineIcon />
          <div className={styles.errorMessageWrapper}>
            <span className={styles.errorMessageSpan}>
              {t("login_not_possible")}
            </span>
            <span className={styles.errorMessageSpan}>
              {t("check_password_and_try_again")}
            </span>
            <span className={styles.errorMessageSpan}>
              {t("for_questions_contact")} {""}
              <a
                className={styles.emailStyle}
                href="mailto:service@kaerhealth.com"
              >
                {" "}
                service@kaerhealth.com
              </a>
            </span>
            {error && (
              <span className={styles.errorMessageSpan}>
                ({t("error")} {error})
              </span>
            )}
            <NavLink className={styles.navLink} to={"/login"}>
              {t("login")}
            </NavLink>
          </div>
        </div>
      );
  };

  function portalName() {
    switch (props.subdomain) {
      case "admin":
        return t("kaer_staff_portal");
      case "manage":
        return t("kaer_manager_portal");
      case "portal":
        return t("kaer_employee_portal");
    }
  }

  const loginWrapper = {
    width: useMediaQuery(theme.breakpoints.up("sm")) ? "50%" : "80%",
    margin: useMediaQuery(theme.breakpoints.up("sm")) ? "0" : "2rem 0",
  } as React.CSSProperties;

  const handleSetLoadingState = (state: boolean) => {
    setIsLoading(state);
  };

  return (
    <div
      className={styles.mainContainer}
      style={backgroundImage}
      onClick={handleResetErrorMessage}
    >
      {isLoading ? (
        <DotLoader
          color="#8c1ec8"
          size={65}
          cssOverride={{ position: "absolute", top: "45vh", left: "50vw" }}
        />
      ) : (
        <div style={loginWrapper}>
          {!step && !error && (
            <LoginForm
              errorHandler={handleErrorMessage}
              errorReset={handleResetErrorMessage}
              subdomain={props.subdomain}
              portalName={portalName}
              token={token}
              setLoadingState={handleSetLoadingState}
            />
          )}
          {step === "2fa" && (
            <div className={styles.infoContainer}>
              <h1 className={styles.infoTitle}>{t("login")}</h1>
              <p> {t("2fa_infotext_user_valid_login")}</p>
            </div>
          )}
        </div>
      )}
      <Modal
        slotProps={{
          backdrop: {
            onClick: (event) => event.stopPropagation(),
          },
        }}
        className={styles.modalContainer}
        open={!!error}
      >
        {handleErrorElement()}
      </Modal>
    </div>
  );
}
