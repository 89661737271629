import React, { FC } from "react";
import GenericButton from "../forms/inputs/button/genericButton";
import { t } from "i18next";
import { useStore } from "@xyflow/react";
import styles from "./actionChains.module.css";

interface ActionChainActionProps {
  openModal: (position: any) => void;
  batchDeleteActions: (itemsToDelete: any) => void;
  copyNodes: (selectedNodes: any) => void;
}

const ActionChainActions: FC<ActionChainActionProps> = ({
  batchDeleteActions,
  copyNodes,
}) => {
  const selectedNodes = useStore((state) =>
    state.nodes.filter((n) => n.selected)
  );

  const handlebatchDelete = (selectedNodes: any) => {
    const idsToDelete = selectedNodes.map((node: any) => node.id);
    batchDeleteActions(idsToDelete);
  };

  return (
    <div className={styles.actionButtonWrapper}>
      {selectedNodes.length > 1 && (
        <>
          <GenericButton
            onClick={() => handlebatchDelete(selectedNodes)}
            color="error"
          >
            {t("bulk_delete")}
          </GenericButton>
          <GenericButton
            onClick={() => copyNodes(selectedNodes)}
            color="secondary"
          >
            {t("bulk_copy_paste")}
          </GenericButton>
        </>
      )}
    </div>
  );
};

export default ActionChainActions;
