import { useState, useEffect, createContext, useContext, useRef } from "react";
import GenericNotification from "../components/notification/genericNotification";
import { t } from "i18next";
import { useSnackbar, SnackbarKey } from "notistack";

const OnlineStatusContext = createContext(navigator.onLine);

export function OnlineStatusProvider({ children }: any) {
  const { closeSnackbar } = useSnackbar();
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [showNotification, setShowNotification] = useState<boolean>(
    !navigator.onLine
  );
  const offlineSnackbarKey = useRef<SnackbarKey | null>(null);

  useEffect(() => {
    const updateStatus = () => {
      const online = navigator.onLine;
      setIsOnline(online);
      setShowNotification(!online);

      if (online && offlineSnackbarKey.current) {
        closeSnackbar(offlineSnackbarKey.current);
        offlineSnackbarKey.current = null;
      }
    };

    window.addEventListener("online", updateStatus);
    window.addEventListener("offline", updateStatus);

    return () => {
      window.removeEventListener("online", updateStatus);
      window.removeEventListener("offline", updateStatus);
    };
  }, [closeSnackbar]);

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {children}
      {showNotification && (
        <GenericNotification
          message={t("onlinestatus_offline_notification")}
          variant="error"
          handleCloseSnackbar={() => setShowNotification(false)}
          persist={true}
          snackbarKeyRef={offlineSnackbarKey}
          showCloseButton={false}
        />
      )}
    </OnlineStatusContext.Provider>
  );
}

export function useOnlineStatus() {
  return useContext(OnlineStatusContext);
}
