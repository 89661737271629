import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";

import styles from "./genericConfirmationModal.module.css";
import GenericButton from "../inputs/button/genericButton";
import { t } from "i18next";
import React from "react";

type GenericConfirmationModalProps = {
  title?: string;
  message: string | ReactNode;
  confirmButtonText?: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  autoCloseAfter?: number;
  disableConfirmFor?: number;
};

const GenericConfirmationModal: FC<GenericConfirmationModalProps> = ({
  title,
  message,
  confirmButtonText,
  isOpen,
  onConfirm,
  onCancel,
  disableConfirmFor,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (disableConfirmFor) {
      setIsConfirmDisabled(true);
      const timer = setTimeout(
        () => setIsConfirmDisabled(false),
        disableConfirmFor
      );
      return () => clearTimeout(timer);
    }
  }, [disableConfirmFor]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && onCancel) {
        event.preventDefault();
        onCancel();
      } else if (event.key === "Enter" && onConfirm) {
        event.preventDefault();
        onConfirm();
      }
    },
    [onConfirm, onCancel]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, handleKeyDown]);

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      PaperProps={{ className: styles.confirmationModalWrapper }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={styles.confirmationModalTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={styles.confirmationModalContent}>
        {message}
      </DialogContent>
      <DialogActions className={styles.confirmationModalActionWrapper}>
        <GenericButton variant="outlined" onClick={onCancel}>
          {t("cancel")}
        </GenericButton>
        <GenericButton onClick={onConfirm} disabled={isConfirmDisabled}>
          {confirmButtonText}
        </GenericButton>
      </DialogActions>
    </Dialog>
  );
};

GenericConfirmationModal.displayName = "GenericConfirmationModal";

export default React.memo(GenericConfirmationModal);
