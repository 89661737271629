import { Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultInput from "../../components/forms/inputs/defaultInput";
import DropDown from "../../components/forms/inputs/dropDown";
// import { Edit, Delete } from "@mui/icons-material";
// import GenericCrudTableModal from "../../components/tables/genericCrudTableModal";
import { IDropDownOption } from "../../types/DropDown";
import { t } from "i18next";
import { api } from "../../helper/api";
// import { MaterialReactTable } from "material-react-table";
import GenericErrorMessageModal from "../../components/forms/errorHandling/genericErrorMessageModal";
import GenericNotification from "../../components/notification/genericNotification";
import { TextArea } from "../../components/forms/inputs/textArea";
import ProbeEditorFormCrud from "./probeEditorFormCrud";
import EditNoteIcon from "@mui/icons-material/EditNote";
import styles from "./probeEditor.module.css";
import { useConfirmationModal } from "../../context/confirmationModalContext";
import GenericButton from "../forms/inputs/button/genericButton";

interface Props {
  entity?: string;
}
export function ProbeEditorView(props: Props) {
  const [examinationOptions, setExaminationOptions] = useState<
    IDropDownOption[]
  >([]);
  const [probeName, setProbeName] = useState<string>("");
  const [probeDescription, setProbeDescription] = useState<string>("");
  const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(true);
  const [predefinedExaminationId, setPredefinedExaminationId] =
    useState<string[]>();
  const [selectedExaminationValues, setSelectedExaminationValues] = useState<
    IDropDownOption[] | any
  >([]);
  const [error, setError] = useState<any>();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<any>("");
  const [notificationVariant, setNotificationVariant] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >();
  const [resetFormTable, setResetFormTable] = useState<boolean>(false);
  const [initialState, setInitialState] = useState<any>({
    name: "",
    description: "",
    examinationValues: "",
  });

  const { probeId } = useParams() as { probeId: string };
  const navigate = useNavigate();
  const { showConfirmationModalAsync } = useConfirmationModal();

  const resetStateOnCloseNotification = () => {
    setShowNotification(false);
    setNotificationMessage("");
  };

  const handleFormEditorOpen = (row: any) => {
    navigate(`/settings/formEditor/${row.original.id}`, {
      state: { probeId: probeId },
    });
    probeId;
  };
  const navigateToProbelist = async (event: any) => {
    if (!disabledSaveButton) {
      const isConfirmed = await showConfirmationModalAsync(t("save_changes"));
      if (!isConfirmed) return;
      handleSubmit(event);
    }
    navigate(`/settings/probeList/`);
  };

  useEffect(() => {
    api.genericApiRequest({
      entity: "examination",
      method: "get",

      successHandler: (res: any) => {
        const examinationOptions: any = [];
        for (const key in res.data.results) {
          const item = res.data.results[key];
          examinationOptions.push({ value: item.id, label: item.name });
        }
        setExaminationOptions(examinationOptions);
      },
      failHandler: (error: any) => {
        setError(error);
      },
    });
  }, []);

  useEffect(() => {
    if (probeId) {
      const additionalUrlParameters: any = {};
      additionalUrlParameters["latest_version"] = "true";
      api.genericApiRequest({
        entity: "probe",
        method: "get",
        parametersToRender: {
          depth: "0",
          additionalUrlParameters: additionalUrlParameters,
        },
        successHandler: (res: any) => {
          // setProbeDetails(res.data);
          setProbeName(res.data.name);
          const examinationId = [];
          for (const key in res.data.examination_set) {
            const id = res.data.examination_set[key].id;
            examinationId.push(id);
          }
          setPredefinedExaminationId(examinationId);

          let probeDescription = "";
          if (res.data.description) {
            probeDescription = res.data.description;
          }
          setProbeDescription(probeDescription);
          const forms = [];
          for (const key in res.data.form_set) {
            const item = res.data.form_set[key];
            forms.push(item);
          }
          // setIsLoading(false);
          getUpdatedProbeId({ probe: { id: res.data.id } });
          setResetFormTable(false);
          setInitialState({
            name: res.data.name,
            description: probeDescription,
            examinationValues: examinationId,
          });
        },
        failHandler: (error: any) => {
          console.log(error.message);
        },
        entityId: probeId,
      });
      // setDisabledSaveButton(true);
    }
  }, [probeId]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const formExaminations = [];
    for (const key in selectedExaminationValues) {
      const id = selectedExaminationValues[key].value;
      formExaminations.push(id);
    }
    const result = {
      name: probeName,
      description: probeDescription,
      examination_set: formExaminations,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await new Promise<any>((resolve, reject) => {
        api.genericApiRequest({
          entity: "probe",
          method: "put",
          entityId: String(probeId),
          submitData: result,
          config: config,
          successHandler: (res: any) => {
            resolve(res);
            setNotificationMessage(t("save_successful"));
            setNotificationVariant("success");
            setShowNotification(true);
            navigate(`/settings/probeList/`);
            // resetTable();
          },
          failHandler: (error: any) => {
            reject(error);
          },
        });
      });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const isNameChanged = probeName !== initialState.name;
    const isDescriptionChanged = probeDescription !== initialState.description;
    const isExamValuesChanged =
      (initialState.examinationValues.length > 0 &&
        selectedExaminationValues.length === 0) ||
      selectedExaminationValues.length !==
        initialState.examinationValues.length ||
      !selectedExaminationValues.every((item: any) =>
        initialState.examinationValues.includes(item.value)
      );
    const isChanged =
      isNameChanged || isDescriptionChanged || isExamValuesChanged;
    setDisabledSaveButton(!isChanged);
  }, [probeName, probeDescription, selectedExaminationValues, initialState]);

  const handleNameChange = (event: any) => {
    setProbeName(event);
  };
  const handleDescriptionChange = (event: any) => {
    setProbeDescription(event);
  };

  const handleDropDownChange = (selectedOption: any) => {
    setSelectedExaminationValues(selectedOption);
  };

  const handleDeleteRow = useCallback(
    (row: any, skipQuestion = false) => {
      const deleteRow = async () => {
        if (!skipQuestion) {
          const isConfirmed = await showConfirmationModalAsync(
            `Möchtest du den Eintrag ${row.getValue("name")} wirklich löschen?`
          );
          if (!isConfirmed) return;
        }
        row.toggleSelected(false);

        const additionalRouteParts: any = {};
        additionalRouteParts["unlink"] = "/";

        const fetchData = async () => {
          try {
            const res = await api.genericApiRequest({
              entity: "form",
              method: "put",
              additionalRouteParts: additionalRouteParts,
              successHandler: (res: any) => {
                /*eslint-enable */
                setNotificationMessage(
                  `${row.getValue("name")} erfolgreich gelöscht!`
                );
                setNotificationVariant("success");
                setShowNotification(true);
                getUpdatedProbeId({ probe: { id: res.data?.probe?.id } });
                setResetFormTable(true);
              },
              failHandler: (error: any) => {
                setError(error);
              },
              entityId: row.getValue("id"),
            });
          } catch (error: any) {
            console.log(error.message);
          }
        };

        await fetchData();
      };
      deleteRow();
    },
    [props.entity]
  );

  const customCreatePresetValues = () => {
    const tempPresetValues: any = [];
    tempPresetValues["editable_by_subject"] = "false";

    return tempPresetValues;
  };

  const customRowActions = (row: any) => {
    return (
      <Tooltip arrow placement="left" title={t("form_edit")}>
        <GenericButton
          color="iconBase"
          variant="icon"
          onClick={() => handleFormEditorOpen(row)}
        >
          {" "}
          <EditNoteIcon />
        </GenericButton>
      </Tooltip>
    );
  };

  const getUpdatedProbeId = (data?: any) => {
    navigate(`/settings/probeEditor/${data.probe.id}`);
  };
  return (
    <>
      <form className={styles.form}>
        <DefaultInput
          name="name"
          label="Name"
          value={probeName}
          onChange={(e: any) => handleNameChange(e.target.value)}
          autoComplete="off"
        />
        <TextArea
          name="description"
          value={probeDescription}
          withLabel={true}
          label="Beschreibung"
          onChange={(e: any) => handleDescriptionChange(e.target.value)}
        />
        <DropDown
          name="examination"
          label="Untersuchungsreihe"
          options={examinationOptions}
          onChange={handleDropDownChange}
          defaultValue={predefinedExaminationId}
          isMulti={true}
          wrapperClassName={styles.dropDown__examination}
          required={true}
        />
        <div className={styles.probeEditorFormWrapper}>
          {probeId && !resetFormTable && (
            <ProbeEditorFormCrud
              probeId={probeId}
              customRowActions={customRowActions}
              customCreatePresetValues={() => customCreatePresetValues()}
              additionalSubmitDataForCreate={{
                probe: probeId,
              }}
              additionalOnSuccessfulCreateFunction={getUpdatedProbeId}
              customDeleteHandler={handleDeleteRow}
              resetFormtable={resetFormTable}
            />
          )}
        </div>

        <div className={styles.probeEditorButtonWrapper}>
          <GenericButton variant="outlined" onClick={navigateToProbelist}>
            {t("back")}
          </GenericButton>

          <GenericButton
            waitingForResponse={true}
            showSpinnerOnDisable={!disabledSaveButton}
            onClick={handleSubmit}
            disabled={disabledSaveButton}
          >
            {t("save")}
          </GenericButton>
        </div>
      </form>
      {error && error !== "" && (
        <GenericErrorMessageModal
          title={t("error_occurred")}
          error={error}
          onClosehandler={() => {
            setError("");
          }}
        />
      )}
      {showNotification && (
        <GenericNotification
          message={notificationMessage}
          variant={notificationVariant}
          handleCloseSnackbar={resetStateOnCloseNotification}
        />
      )}
    </>
  );
}
